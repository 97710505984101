import { Box, useMediaQuery, useTheme } from "@mui/material";
import { SocialItem } from "../../views/Communication/components/SocialItem";

import SolidarIcon from "./../../assets/Communication/socials/solidar.png";

import YoutubeColorIcon from "./../../assets/Communication/socials/youtube-color.png";
import YoutubeIcon from "./../../assets/Communication/socials/youtube.png";

import InstagramColorIcon from "./../../assets/Communication/socials/instagram-color.png";
import InstagramIcon from "./../../assets/Communication/socials/instagram.png";

import FacebookColorIcon from "./../../assets/Communication/socials/facebook-color.png";
import FacebookIcon from "./../../assets/Communication/socials/facebook.png";
import { ComunicacionEnlaces } from "../../views/Communication/constants";

const ButtonSocialMedia = ({
  right = 50,
  mostrarEnMobile = false,
}: {
  right?: number;
  mostrarEnMobile?: boolean;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const goToLink = (enlace: string) => {
    const link = document.createElement("a");
    link.href = enlace;
    link.target = "_blank";
    link.click();
  };

  if (isMobile && !mostrarEnMobile) {
    return null;
  }

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        height: "100%",
        position: "absolute",
        right: right,
      }}
    >
      <Box
        sx={{
          py: 2,
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          px: 1,
        }}
      >
        <SocialItem
          withBorder
          customAlt={"solidar-icon"}
          iconPath={SolidarIcon}
          disableIconPath={SolidarIcon}
          action={() => goToLink(ComunicacionEnlaces.SOLIDAR)}
        />
        <SocialItem
          withBorder
          customAlt={"facebook-icon"}
          iconPath={FacebookColorIcon}
          disableIconPath={FacebookIcon}
          action={() => goToLink(ComunicacionEnlaces.FACEBOOK)}
        />
        <SocialItem
          withBorder
          customAlt={"instagram-icon"}
          iconPath={InstagramColorIcon}
          disableIconPath={InstagramIcon}
          action={() => goToLink(ComunicacionEnlaces.INSTAGRAM)}
        />
        <SocialItem
          withBorder
          customAlt={"youtube-icon"}
          iconPath={YoutubeColorIcon}
          disableIconPath={YoutubeIcon}
          action={() => goToLink(ComunicacionEnlaces.YOUTUBE)}
        />
      </Box>
    </Box>
  );
};

export default ButtonSocialMedia;
