import { Box, Typography, useTheme } from "@mui/material";
import { useRef } from "react";
import imgUpload from "./../../../assets/course/cd_subir.svg";
import { printLog } from "../../utils";

type CFilePickerProps = {
  placeholder: string;
  name?: string;
  multiple?: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement> | any) => void;
  disabled?: boolean;
  accept?: "image/*" | "application/pdf" | "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
};

export const CFilePicker = (props: CFilePickerProps) => {
  // const { handleChangePicker } = props;
  const theme = useTheme();
  const inputFileRef = useRef<any>(null);

  const uploadFile = () => {
    if (props.disabled) return;

    if (inputFileRef && inputFileRef.current) {
      inputFileRef.current.click();
    }
    // const inputFile = document.getElementById("file-component");
    // inputFile?.click();
  };

  const handleDragOver = (event: React.DragEvent<HTMLElement>) => {
    if (props.disabled) return;
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDrop = (event: React.DragEvent<HTMLElement>) => {
    if (props.disabled) return;
    event.preventDefault();
    event.stopPropagation();
    printLog("Archivo arrastrado! ", event.dataTransfer.files);
    // const dataTransfer = event.dataTransfer as unknown as ChangeEvent<HTMLInputElement> && EventTarget
    printLog(" hay handlechange >>> ", props);
    props.handleChange({ target: event.dataTransfer });
  };

  // const handleFileSelected = (files: FileList | null) => {
  //   printLog("Archivo seleccionado :: ", files);
  //   handleChangePicker(files);
  // };

  return (
    <Box mt={1} mb={2}>
      <Box
        style={{
          border: "1px dashed #ABAFB3",
          padding: 30,
          borderRadius: 5,
          // width: "100%",
          cursor: "move",
        }}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={uploadFile}
      >
        <img src={imgUpload} height={80} alt="img-upload" />
        <Box sx={{ textAlign: "center" }}>
          <Typography
            component={"span"}
            sx={{ paddingRight: 0.5, color: theme.palette.primary.main }}
          >
            {props.placeholder ? props.placeholder : "Carga el archivo adjunto"}
          </Typography>
          <Typography component={"span"} sx={{ color: "#ABAFB3" }}>
            o sólo arrastra y suelta aquí
          </Typography>
        </Box>
        <input
          ref={inputFileRef}
          type="file"
          hidden
          name={props.name}
          // onChange={(e) => handleFileSelected(e.target.files)}
          accept={props.accept}
          multiple={!!props.multiple}
          onChange={props.handleChange}
          disabled={props.disabled}
        />
      </Box>
    </Box>
  );
};
