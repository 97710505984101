import {useEffect, useState} from "react";
import {Box, SelectChangeEvent, styled, Typography} from "@mui/material";
import theme from "../../../../../../themes";
import useHttp from "../../../../../../shared/hooks/useHttp";
import CSelectForm from "../../../../../../shared/components/form/CSelectForm";

const StyledBox = styled(Box)(() => ({
  margin: '1.5em 0 0 0',
  padding: '1.5em 1.5em 1.5em 1.5em',
  width: '100%',
  borderRadius: '8px',
  backgroundColor: '#dedede'
}));

const SearchMunicipality = ({handleSetMunicipio, handleSetDpto}:any) => {

  const http = useHttp();
  const [departamentos, setDepartamentos] = useState([]);
  // const [municipios, setMunicipios] = useState([]);

  useEffect(() => {
    obtenerDepartamentosMunicipios().finally();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const obtenerDepartamentosMunicipios = async () => {
    const response: any = await http.get('/municipio/porDepartamento?esMunicipioVdsv=true', {});
    
    if (response) {
      setDepartamentos(response.departamentos)
    }
  }

  return (
    <StyledBox display="flex" flexDirection="column" justifyContent="center">
      <Box>
        <Typography sx={{ textTransform: 'uppercase', fontFamily: 'Barlow', fontSize: 22, color: theme.palette.neutral.active }}>
          Realiza una búsqueda por departamento 
        </Typography>
      </Box>
      <Box display="flex" sx={{ padding: '0.5em 0 0.5em 0' }}>
        <CSelectForm
          placeholder="Selecciona el departamento"
          fullWidth
          // items={departamentosForm}
          items={[
            { value: '', label: 'TODOS' },
            ...departamentos.map((item: any) => ({
              value: item.departamento_cod,
              label: item.nombre,
            }))
          ]}
          onChange={(event: SelectChangeEvent) => {
            // setDepartamento(event.target.value as string)
            handleSetDpto(event.target.value);
            // if (event.target.value === '') {
            //   setMunicipios([]); 
            // } else {
            //   const _depto: any = departamentos.find((item: any) => item.departamento_cod === event.target.value);
            //   setMunicipios(_depto.municipios); 
            // }
          }}
        />
      </Box>
      {/* <Box display="flex" sx={{ padding: '0.5em 0 0.5em 0' }}>
        <CSelectForm
          placeholder="Selecciona el municipio"
          fullWidth
          // items={departamentosForm}
          items={municipios.map((item: any) => ({
            value: item.municipio_cod,
            label: item.municipio_desc,
          }))}
          onChange={(event: SelectChangeEvent) => {
            handleSetMunicipio(event.target.value as string);
          }}
        />
      </Box> */}
      {/* <DepartmentList /> */}
    </StyledBox>
  )
};

export default SearchMunicipality;