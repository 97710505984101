import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  SelectChangeEvent,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form";
import CInputForm from "../../../../../shared/components/form/CInputForm";
import CSelectForm from "../../../../../shared/components/form/CSelectForm";
import { useEffect, useState } from "react";
import useHttp from "../../../../../shared/hooks/useHttp";
import useAppContext from "../../../../../shared/hooks/useAppContext";
import { CFilePicker } from "../../../../../shared/components/form/CFilePicker";
import { printLog } from "../../../../../shared/utils";
import { Alerts } from "../../../../../shared/hooks/useAlert";
import { CancelRounded } from "@mui/icons-material";

export type RegisterFormType = {
  nombre: string;
  correo: string;
  titulo: string;
  descripcion: string;
  contrasena: string;
  contrasena2: string;
  fechaNacimiento: string;
  celular: string;
  departamento: string;
  municipio_cod: string;
  institucion: string;
  fondo: string;
  gestion: number;
  bloque: string;
  adjunto: FileList | null;
  recurso: string;
};

const RegisterForm = ({ onClose }: any) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<RegisterFormType>();

  const adjunto = useWatch({
    control,
    name: "adjunto",
  });

  const { saveGoodPracticeForm } = useAppContext();

  const http = useHttp();

  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    obtenerDepartamentosMunicipios().finally();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const obtenerDepartamentosMunicipios = async () => {
    const response: any = await http.get(
      "/municipio/porDepartamento?esMunicipioVdsv=true",
      {}
    );
    if (response) {
      setDepartamentos(response.departamentos);
    }
  };

  const onSubmit: SubmitHandler<RegisterFormType> = async (data: any) => {
    printLog(data);

    let formData = new FormData();

    for (const file of adjunto ?? []) {
      formData.append("adjunto", file);
    }
    // formData.append("adjunto", data.adjunto);

    formData.append("bloque", data.bloque);
    formData.append("celular", data.celular);
    formData.append("departamento", data.departamento);
    formData.append("descripcion", data.descripcion);
    formData.append("fondo", "FONDO_BUENAS_PRACTICAS");
    formData.append("gestion", data.gestion);
    formData.append("municipio_cod", data.municipio_cod);
    formData.append("nombre", data.nombre);
    formData.append("titulo", data.titulo);
    formData.append("recurso", data.recurso);

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    try {
      let res: any = await saveGoodPracticeForm(formData);

      if (res["id"]) {
        onClose();

        Alerts.showSuccess({
          confirmText: "Aceptar",
          description: "Buena práctica enviada",
        });
      }
    } catch (e: any) {
      onClose();
      Alerts.showError({
        description: e["error"] ? e["error"] : "Error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const CoursePrimaryButton = styled((props: any) => (
    <Button variant="contained" disableElevation color="inherit" {...props}>
      {props.children}
    </Button>
  ))(({ theme }) => ({
    background:
      "linear-gradient(180deg, rgba(0,134,76, 1) 0%, rgba(0,134,76, 1) 53%, rgba(0,134,76, 1) 100%)",
    fontFamily: "Barlow",
    borderRadius: "8px",
    color: "white",
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    // border: "1px solid rgba(0,119,170,1)",
    "&:hover": {
      background:
        "linear-gradient(180deg, (0,124,60, .95) 0%, rgba(0,124,60, .95) 53%, rgba(0,124,60, .95) 100%)",
    },
  }));

  const theme = useTheme();

  const handleDeleteAttachment = (index: number) => {
    let data: any = [];

    if (adjunto) {
      for (let x = 0; x < adjunto.length; x++) {
        if (x !== index) {
          data = [...data, adjunto[x]];
        }
      }

      setValue("adjunto", data);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit, () => {
        Alerts.showError({
          description:
            "Asegúrate de llenar las casillas en rojo antes de enviar.",
        });
      })}
    >
      <Grid container direction="column" spacing={3} alignItems="center" mb={3}>
        <Grid item container xs={12}>
          <Controller
            name="nombre"
            control={control}
            defaultValue=""
            rules={{
              required: "El nombre completo es requerido",
            }}
            render={({ field }) => (
              <CInputForm
                type="text"
                placeholder="Nombre completo"
                fullWidth
                {...field}
                ref={null}
                error={errors.nombre}
              />
            )}
          />
        </Grid>
        <Grid item container xs={12}>
          <Controller
            name="celular"
            control={control}
            defaultValue=""
            rules={{
              required: "El número de celular es requerido",
            }}
            render={({ field }) => (
              <CInputForm
                type="text"
                placeholder="Número de celular"
                fullWidth
                {...field}
                ref={null}
                error={errors.celular}
              />
            )}
          />
        </Grid>
        {/* <Grid item container xs={12}>
            <Controller
              name="fondo"
              control={control}
              defaultValue=""
              rules={{
                required: 'El fondo es requerido'
              }}
              render={({ field }) => (
                <CSelectForm
                  placeholder="Selecciona el Fondo en el que participas*"
                  fullWidth
                  items={[
                    { value: 'FONDO_BUENAS_PRACTICAS', label: "Fondo por una Vida Digna Sin Violencia para las Mujeres"},
                    { value: 'FONDO_EXPERIENCIAS_INNOVADORAS', label: "Fondo Hilando Autonomías" }
                  ]}
                  {...field}
                  ref={null}
                  error={errors.fondo}
                  onChange={(event: SelectChangeEvent) => {
                    field.onChange(event.target.value);
                    setValue('bloque', '');
                  }}
                />
              )}
            />
        </Grid> */}

        <Grid item container xs={12}>
          <Controller
            name="bloque"
            control={control}
            defaultValue=""
            rules={{
              required: "El bloque es requerido",
            }}
            render={({ field }) => (
              <CSelectForm
                placeholder="Soy responsable del bloque*"
                fullWidth
                items={[
                  { value: "GESTION_PUBLICA", label: "Gestión pública" },
                  {
                    value: "COMUNICACION_PUBLICA",
                    label: "Comunicación públicas",
                  },
                  { value: "REPLICAS", label: "Réplicas" },
                  { value: "PREVENCION", label: "Prevención" },
                ]}
                {...field}
                ref={null}
                error={errors.bloque}
              />
            )}
          />
        </Grid>

        <Grid item container xs={12}>
          <Grid item xs={12} sx={{ pr: 1 }}>
            <Typography
              sx={{
                fontSize: "16px",
                color: theme.palette.neutral.active,
              }}
            >
              Por favor, comparte con nosotros tu experiencia o buena práctica
              en los siguientes campos:
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Controller
            name="titulo"
            control={control}
            defaultValue=""
            rules={{
              required: "El título es requerido",
            }}
            render={({ field }) => (
              <CInputForm
                type="string"
                placeholder="Titulo de la experiencia*"
                fullWidth
                {...field}
                ref={null}
                error={errors.titulo}
              />
            )}
          />
        </Grid>
        <Grid item container xs={12}>
          <Controller
            name="descripcion"
            control={control}
            defaultValue=""
            rules={{
              required: "La descripción es requerida",
              maxLength: 350,
            }}
            render={({ field }) => (
              <Grid item container xs={12}>
                <CInputForm
                  type="string"
                  placeholder="Descripción*"
                  fullWidth
                  {...field}
                  inputProps={{ maxLength: 350 }}
                  ref={null}
                  error={errors.descripcion}
                  multiline
                  rows={4}
                />
                <Typography variant="caption" color="textSecondary">
                  Caracteres restantes: {350 - field.value.length}
                </Typography>
              </Grid>
            )}
          />
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={6} sx={{ pr: 1 }}>
            <Controller
              name="departamento"
              control={control}
              defaultValue=""
              rules={{
                required: "El departamento es requerido",
              }}
              render={({ field, formState }) => (
                <CSelectForm
                  placeholder="Departamento *"
                  fullWidth
                  // items={departamentosForm}
                  items={departamentos.map((item: any) => ({
                    value: item.nombre,
                    label: item.nombre,
                  }))}
                  {...field}
                  error={errors.departamento}
                  onChange={(event: SelectChangeEvent) => {
                    // printLog('errores de form > ', formState.errors)
                    setValue("departamento", event.target.value);
                    delete formState.errors.departamento;
                    setValue("municipio_cod", "");
                    const _depto: any = departamentos.find(
                      (item: any) => item.nombre === event.target.value
                    );
                    setMunicipios(_depto.municipios);
                  }}
                  ref={null}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sx={{ pl: 1 }}>
            <Controller
              name="municipio_cod"
              control={control}
              defaultValue={""}
              rules={{
                required: "El municipio es requerido",
              }}
              render={({ field }) => (
                <CSelectForm
                  placeholder="Municipio *"
                  fullWidth
                  // items={departamentosForm}
                  items={municipios.map((item: any) => ({
                    value: item.municipio_cod,
                    label: item.municipio_desc,
                  }))}
                  {...field}
                  error={errors.municipio_cod}
                  ref={null}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Grid item xs={12} sx={{ pr: 1 }}>
            <Typography
              sx={{
                fontSize: "16px",
                color: theme.palette.neutral.active,
              }}
            >
              A continuación puedes cargar de manera opcional algún enlace o
              adjunto desde tu dispositivo en la sección 'URL Recurso/Adjuntos'
              que complementan la iniciativa que estás compartiendo.
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Grid item xs={12} sx={{ pr: 1 }}>
            <Controller
              name="gestion"
              control={control}
              defaultValue={undefined}
              rules={{
                required: "La gestión es requerida",
              }}
              render={({ field }) => (
                <CSelectForm
                  placeholder="Gestión"
                  fullWidth
                  {...field}
                  error={errors.gestion}
                  ref={null}
                  items={[
                    { value: 2023, label: "2023" },
                    { value: 2024, label: "2024" },
                    { value: 2025, label: "2025" },
                  ]}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Grid item xs={12} sx={{ pr: 1 }}>
            <Controller
              name="recurso"
              control={control}
              defaultValue={""}
              rules={{
                pattern: {
                  //Regex from https://www.makeuseof.com/regular-expressions-validate-url/
                  value:
                    /^((http|https):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g,
                  message: "La url es inválida",
                },
              }}
              render={({ field }) => (
                <CInputForm
                  type="string"
                  placeholder="URL Recurso"
                  fullWidth
                  {...field}
                  ref={null}
                  error={errors.recurso}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Grid item xs={12} sx={{ pl: 1 }}>
            {/* <Controller
              name="adjunto"
              control={control}
              defaultValue={undefined}
              render={({ field }) => (
                <input
                type="file"
                  onChange={(e) => {
                    field.onChange(e.target.files);
                  }}
                  multiple
                />
              )}
            /> */}
            <Controller
              name="adjunto"
              control={control}
              defaultValue={undefined}
              render={({ field }) => (
                <CFilePicker
                  placeholder="Adjuntos"
                  handleChange={(e) => {
                    debugger;
                    field.onChange(e.target.files);
                  }}
                  multiple={true}
                />
              )}
            />
            {adjunto
              ? Array.from(adjunto).map((file, index) => {
                  return (
                    <Chip
                      key={index}
                      deleteIcon={
                        <IconButton size="small" sx={{ stroke: "red" }}>
                          <CancelRounded width="20" height="20" />
                        </IconButton>
                      }
                      onDelete={() => handleDeleteAttachment(index)}
                      label={file.name}
                      sx={{
                        margin: 0.5,
                        border: `1px solid green`,
                      }}
                    />
                  );
                })
              : null}
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <Grid item xs={12} sx={{ pr: 1 }}>
            <Typography
              sx={{
                fontSize: "16px",
                color: theme.palette.neutral.active,
                pl: 10,
              }}
            >
              ¡Juntos inspiramos un cambio positivo en comunidad!
            </Typography>
          </Grid>
        </Grid>

        <Grid item container xs={12}>
          <CoursePrimaryButton type="submit" fullWidth disabled={isSubmitting}>
            {isSubmitting ? "Enviando..." : "Enviar"}
          </CoursePrimaryButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RegisterForm;
