import {
  alpha,
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Typography,
} from "@mui/material";
import theme from "../../../themes";
import React, { ReactNode } from "react";

interface CSelectFormProps<T>
  extends Omit<SelectProps<T>, "error" | "items" | "placeholder"> {
  error?: {
    type?: string;
    message?: string;
  };
  items?: ItemProp[];
  placeholder?: string;
  inputIcon?: ReactNode;
  multiple?: boolean;
  maxSelection?: number;
}

interface ItemProp {
  value: string | number;
  label: string;
}

const CSelectForm = <
  T extends string | number | string[] | number[] | undefined
>(
  props: CSelectFormProps<T>
) => {
  const {
    error: _error,
    items: _items,
    placeholder: _placeholder,
    multiple = false,
    maxSelection = 1,
    ...inputProps
  } = props;

  const handleChange = (event: SelectChangeEvent<typeof inputProps.value>) => {
    if (multiple) {
      const selectedValues = event.target.value as (string | number)[];
      if (maxSelection && selectedValues.length > maxSelection) {
        return;
      }
      if (inputProps.onChange) {
        inputProps.onChange(event as SelectChangeEvent<T>, selectedValues);
      }
    } else {
      if (inputProps.onChange) {
        inputProps.onChange(event as SelectChangeEvent<T>, event.target.value);
      }
    }
  };

  const truncateLabel = (label: string) => {
    if (window.innerWidth <= 768) {
      return label.length > 30 ? label.slice(0, 30) + "..." : label;
    }
    return label;
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <FormControl fullWidth variant="standard">
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          backgroundColor: "#ECECEC",
          borderRadius: "8px",
          borderWidth: 0.5,
          borderStyle: "solid",
          borderColor: props.error ? theme.palette.error.main : "#ECECEC",
        }}
      >
        <Select
          disableUnderline
          displayEmpty
          fullWidth
          multiple={multiple}
          onChange={handleChange}
          renderValue={(selected) =>
            multiple && Array.isArray(selected)
              ? selected
                  .map(
                    (s) => props.items?.find((item) => item.value === s)?.label
                  )
                  .join(", ") || _placeholder
              : props.items?.find((item) => item.value === selected)?.label ||
                _placeholder ||
                "Seleccione un ítem"
          }
          sx={{
            px: 1,
            py: 1,
            pl: "10px",
          }}
          {...inputProps}
          MenuProps={MenuProps}
        >
          <MenuItem disabled value="">
            <em style={{ color: alpha(theme.palette.common.black, 0.7) }}>
              {props.placeholder || "Seleccione un ítem"}
            </em>
          </MenuItem>
          {props.items && props.items.length ? (
            props.items.map((item: ItemProp) => (
              <MenuItem key={item.value} value={item.value}>
                {multiple && Array.isArray(inputProps.value) ? (
                  <Checkbox
                    checked={(inputProps.value as (string | number)[]).includes(
                      item.value
                    )}
                  />
                ) : null}
                <ListItemText primary={truncateLabel(item.label)} />
              </MenuItem>
            ))
          ) : (
            <MenuItem value="none" disabled>
              <em>No hay items disponibles</em>
            </MenuItem>
          )}
        </Select>
      </Box>
      {props.error && (
        <Typography
          component={"span"}
          sx={{
            textAlign: "left",
            fontSize: ".8rem",
            color: theme.palette.error.main,
          }}
        >
          {props.error.type === "required" ? "* " : ""}
          {props.error.message || ""}
        </Typography>
      )}
    </FormControl>
  );
};

export default CSelectForm;
