import { Close } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CInputForm from "../../../shared/components/form/CInputForm";
import { colorsBase, DesignSystem } from "../../../themes/theme";
import { CFilePicker } from "../../../shared/components/form/CFilePicker";
import { Adjunto, INoticiaForm, NoticiaDetalle } from "../NoticiaInterface";
import {
  ChangeEvent,
  lazy,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  CancelButton,
  ConfirmButton,
} from "../../../shared/components/CButton";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { ReactComponent as RolIcon } from "../../../assets/icons/rol.svg";
import { ReactComponent as CancelCircleIcon } from "../../../assets/icons/cancelCircle.svg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import useHttp from "../../../shared/hooks/useHttp";
import CSelectForm from "../../../shared/components/form/CSelectForm";
import { isJsonString, printLog } from "../../../shared/utils";
import draftToHtml from "draftjs-to-html";
import { Alerts } from "../../../shared/hooks/useAlert";

export type NoticiaPayload = INoticiaForm & {
  archivos: File[];
  adjuntosEliminados: Adjunto[];
  imagesCarrusel: File[];
  currentImagesCarousel: Adjunto[];
};

type FormNoticiaComponentProps = {
  noticia?: NoticiaDetalle;
  noticiaDialog: boolean;
  onClose: () => void;
  onSubmitNoticia: (data: NoticiaPayload) => void;
  loading: boolean;
  media: any;
  esEnlaceDeInteres?: boolean | undefined;
};

const CANTIDAD_MAXIMA_IMAGENES_CARRUSEL = 10;

export const FormNoticiaComponent = ({
  noticia,
  noticiaDialog,
  onClose,
  onSubmitNoticia,
  loading,
  media,
  esEnlaceDeInteres,
}: FormNoticiaComponentProps) => {
  const ComponentCkEditor = useMemo(
    () => lazy(() => import("../../../components/ckeditor/EditorTexto")),
    []
  );

  const [loadingModal, setLoading] = useState<boolean>(false);

  const [adjuntos, setAdjuntos] = useState<File[]>([]);
  const [currentAdjunto, setCurrentAdjunto] = useState<Adjunto | null>();
  const [deletedAdjuntos, setDeletedAdjuntos] = useState<Adjunto[]>([]);

  const [imgCarousel, setImgCarousel] = useState<File[]>([]);
  const [currentImgCarousel, setCurrentImgCarousel] = useState<Adjunto[]>([]);
  const [deletedImgCarousel, setDeletedImgCarousel] = useState<Adjunto[]>([]);

  const [contenidoHTML, setContenidoHTML] = useState<string>(() => {
    if (noticia && esEnlaceDeInteres === false) {
      if (isJsonString(noticia.contenido) && JSON.parse(noticia.contenido)) {
        const parseado = JSON.parse(noticia.contenido);
        const editorContent = EditorState.createWithContent(
          convertFromRaw(parseado)
        );
        const rawContentState = convertToRaw(editorContent.getCurrentContent());
        let markup = draftToHtml(rawContentState);
        markup = markup.replace(/(width\s*:\s*auto)/gi, "width: 100%");
        return markup;
      } else {
        return noticia.contenido;
      }
    } else {
      return "";
    }
  });
  const [imagesDeleteEditor, setImagesDeleteEditor] = useState<string[]>([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
    watch,
    setValue,
  } = useForm<INoticiaForm>();
  const http = useHttp();

  useEffect(() => {
    if (noticia) {
      const isLink = (Object.keys(noticia) as (keyof typeof noticia)[]).find(
        (key) => {
          return key === "prensa_cod";
        }
      );

      reset({
        titulo: noticia.titulo,
        tipo: isLink ? "ENLACE_INTERES" : "NOTICIA",
        categoria: noticia.categoria,
        alcanceGeografico: noticia.alcanceGeografico,
        esNoticiaSolidar: noticia.esNoticiaSolidar,
        estado: noticia.estado,
      });

      if (!isLink) setValue("esDestacada", noticia.esDestacada);

      isLink ? setValue("tipo", "ENLACE_INTERES") : setValue("tipo", "NOTICIA");

      if (isLink) setValue("url", noticia.prensa_enlace);

      if (isLink) setValue("medio_cod", noticia.medio_cod);

      if (noticia.urlImagen) {
        let urlSplit = noticia.urlImagen.split("/");
        let fileName = urlSplit[urlSplit.length - 1];

        setCurrentAdjunto({
          nombre: fileName,
          url: noticia.urlImagen,
        });
      }

      if (noticia.carrusel && noticia.carrusel.length > 0) {
        const currentsFiles: Adjunto[] = [];
        noticia.carrusel.forEach((img) => {
          currentsFiles.push({
            nombre: img.uploadedImage.originalFileName,
            url: img.urlImagen,
          });
        });
        setCurrentImgCarousel(currentsFiles);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isNews = watch("tipo");

  const onEditorStateChange = (editorState: string) => {
    setContenidoHTML(editorState);
  };

  const handleChangePicker = ({ target }: ChangeEvent<HTMLInputElement>) => {
    // printLog('[NoticiaPage] >  Archivos seleccionados de FilePicker :: target.files ', target.files);
    if (target.files && target.files.length) {
      printLog(
        "[NoticiaPage] >  Archivos seleccionados de FilePicker :: target.files[0]",
        target.files![0]
      );
      // if (multiple)
      setAdjuntos([
        // ...adjuntos,
        ...target.files,
      ]);
    }
  };

  const handleChangePickerCarousel = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    if (target.files && target.files.length) {
      const totalImages = target.files.length + imgCarousel.length;

      if (totalImages > CANTIDAD_MAXIMA_IMAGENES_CARRUSEL) {
        Alerts.showError({
          description: `Solo se pueden agregar un total de ${CANTIDAD_MAXIMA_IMAGENES_CARRUSEL} imagenes`,
        });
        return;
      }
      printLog(
        "[NoticiaPage] >  Archivos seleccionados de FilePicker :: target.files[0]",
        target.files![0]
      );

      // verificamos si el nombre del archivo ya existe en el carrusel, si existe omitimos la carga
      const files = target.files;
      const filesNames = imgCarousel?.map((img) => img.name);
      currentImgCarousel?.forEach((img) => {
        filesNames.push(img.nombre);
      });
      const filesToLoad = Array.from(files).filter(
        (file) => !filesNames.includes(file.name)
      );
      if (filesToLoad.length === 0) {
        return;
      }

      setImgCarousel([...imgCarousel, ...target.files]);
    }
  };

  const handleSubmitPublicar: SubmitHandler<INoticiaForm> = async (data) => {
    printLog("PUBLICAR noticia :: ", data);
    printLog("Adjuntos de la noticia :: ", adjuntos);

    if (adjuntos.length === 0 && !currentAdjunto) {
      Alerts.showError({
        description: "Debe adjuntar una imagen a la noticia",
      });
      return;
    }

    if (adjuntos.length > 0) {
      const isNotImage = adjuntos.find((adjunto) => {
        return !adjunto.type.includes("image");
      });

      if (isNotImage) {
        Alerts.showError({
          description:
            "Solo se permiten imágenes como adjuntos, el archivo seleccionado no es una imagen",
        });
        return;
      }
    }

    setLoading(true)
    if (data.tipo === "NOTICIA") {
      const tieneContenidoHTML = validateContenidoHTML();
      if (!tieneContenidoHTML) {
        return;
      }

      const datos: NoticiaPayload = {
        tipo: data.tipo,
        titulo: data.titulo,
        categoria: data.categoria,
        alcanceGeografico: data.alcanceGeografico,
        esNoticiaSolidar: data.esNoticiaSolidar,
        esDestacada: data.esDestacada,
        contenido: contenidoHTML,
        estado: "PUBLICADO",
        archivos: adjuntos,
        adjuntosEliminados: deletedAdjuntos,
        imagesCarrusel: imgCarousel,
        currentImagesCarousel: currentImgCarousel,
      };

      onSubmitNoticia(datos);
    } else {
      // debugger;
      const datos: any = {
        tipo: data.tipo,
        titulo: data.titulo,
        categoria: data.categoria,
        alcanceGeografico: data.alcanceGeografico,
        estado: "PUBLICADO",
        archivos: adjuntos,
        adjuntosEliminados: deletedAdjuntos,
        url: data.url,
        medio_cod: data.medio_cod,
      };

      onSubmitNoticia(datos);
    }

    if (imagesDeleteEditor.length > 0) {
      imagesDeleteEditor.forEach((url) => {
        if (url) deleteImageFromServer(url);
      }); // Eliminar del servidor
    }

    setLoading(false)
    // Actualizar las imágenes actuales
    setImagesDeleteEditor([]);
  };

  const handleSubmitBorrador: SubmitHandler<INoticiaForm> = async (data) => {
    printLog("BORRADOR noticia :: ", data);
    // printLog('editorState.getCurrentContent :: ', editorState.getCurrentContent());
    // printLog('editorState convertToRaw :: ', convertToRaw(editorState.getCurrentContent()));
    printLog("Adjuntos de la noticia :: ", adjuntos);

    if (adjuntos.length > 0) {
      const isNotImage = adjuntos.find((adjunto) => {
        return !adjunto.type.includes("image");
      });

      if (isNotImage) {
        Alerts.showError({
          description:
            "Solo se permiten imágenes como adjuntos, el archivo seleccionado no es una imagen",
        });
        return;
      }
    }

    setLoading(true)
    if (data.tipo === "NOTICIA") {
      const tieneContenidoHTML = validateContenidoHTML();
      if (!tieneContenidoHTML) {
        return;
      }

      const datos: NoticiaPayload = {
        tipo: data.tipo,
        titulo: data.titulo,
        categoria: data.categoria,
        alcanceGeografico: data.alcanceGeografico,
        esNoticiaSolidar: data.esNoticiaSolidar,
        esDestacada: data.esDestacada,
        contenido: contenidoHTML,
        estado: "BORRADOR",
        archivos: adjuntos,
        adjuntosEliminados: deletedAdjuntos,
        imagesCarrusel: imgCarousel,
        currentImagesCarousel: currentImgCarousel,
      };

      onSubmitNoticia(datos);
    } else {
      const datos: any = {
        tipo: data.tipo,
        titulo: data.titulo,
        categoria: data.categoria,
        alcanceGeografico: data.alcanceGeografico,
        estado: "BORRADOR",
        archivos: adjuntos,
        adjuntosEliminados: deletedAdjuntos,
        url: data.url,
        medio_cod: data.medio_cod,
      };

      onSubmitNoticia(datos);
    }

    if (imagesDeleteEditor.length > 0) {
      imagesDeleteEditor.forEach((url) => {
        if (url) deleteImageFromServer(url);
      }); // Eliminar del servidor
    }

    setLoading(false)
    setImagesDeleteEditor([]);
  };

  const validateContenidoHTML = () => {
    if (
      contenidoHTML === "" ||
      contenidoHTML === "<p><br></p>" ||
      contenidoHTML.trim() === ""
    ) {
      Alerts.showError({
        description: "El contenido de la noticia no puede estar vacío",
      });
      return false;
    }
    return true;
  };

  const deleteImageFromServer = async (url: string) => {
    // verificamos que no sea una imagen de base64
    if (url.startsWith("data:image")) return;
    const idImage = url.split("/").pop();
    try {
      await http.delete(`/file/${idImage}`, {}).then((response) => {
        printLog("Imagen eliminada:", response);
      });
    } catch (error) {
      printLog("Error al eliminar la imagen del editor:", error);
    }
  };

  const handleCloseDialogNoticia = () => {
    setAdjuntos([]);
    contenidoHTML && setContenidoHTML("");
    // setEditorState(() => EditorState.createEmpty());
    reset();
    onClose();
  };

  return (
    <Dialog
      open={noticiaDialog}
      fullWidth
      maxWidth="lg"
      onClose={handleCloseDialogNoticia}
      disableEnforceFocus
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ borderBottom: `1px solid ${colorsBase.BorderGray}` }}
        >
          <Typography component={"span"} sx={{ ...DesignSystem?.titulo1 }}>
            Creación de nueva noticia
          </Typography>
          <IconButton onClick={handleCloseDialogNoticia}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box component={"form"} onSubmit={handleSubmit(handleSubmitBorrador)}>
        <DialogContent>
          <Grid container spacing={3} sx={{ px: 2 }}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Título de la noticia*
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="titulo"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "El campo Titulo es requerido",
                  }}
                  render={({ field }) => (
                    <CInputForm
                      inputIcon={<RolIcon />}
                      type="text"
                      placeholder="Escribe el título de la noticia"
                      {...field}
                      ref={null}
                      error={errors.titulo}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Tipo*
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="tipo"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "El campo es requerido",
                  }}
                  render={({ field }) => (
                    <CSelectForm
                      placeholder="Selecciona el tipo de noticia"
                      fullWidth
                      items={[
                        { value: "NOTICIA", label: "NOTICIA" },
                        { value: "ENLACE_INTERES", label: "ENLACE DE INTERES" },
                      ]}
                      {...field}
                      ref={null}
                      required
                      error={errors.tipo}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Categoría
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="categoria"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "El campo es requerido",
                  }}
                  render={({ field }) => (
                    <CSelectForm
                      placeholder="Selecciona la categoría"
                      fullWidth
                      items={[
                        { value: "POLITICO", label: "POLÍTICO" },
                        { value: "ECONOMICO", label: "ECONÓMICO" },
                        { value: "SOCIAL", label: "SOCIAL" },
                        { value: "TECNOLOGICO", label: "TECNOLÓGICO" },
                        { value: "ECOLOGICO", label: "ECOLÓGICO" },
                        { value: "LEGAL", label: "LEGAL" },
                        { value: "SALUD", label: "SALUD" },
                      ]}
                      {...field}
                      ref={null}
                      required
                      error={errors.categoria}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Alcance geográfico
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="alcanceGeografico"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: { value: true, message: "El campo es requerido" },
                  }}
                  render={({ field }) => (
                    <CSelectForm
                      placeholder="Seleccione el Alcance Geográfico"
                      fullWidth
                      items={[
                        { value: "INTERNACIONAL", label: "INTERNACIONAL" },
                        { value: "REGIONAL", label: "REGIONAL" },
                        { value: "NACIONAL", label: "NACIONAL" },
                      ]}
                      {...field}
                      ref={null}
                      required
                      error={errors.alcanceGeografico}
                    />
                  )}
                />
              </Grid>
            </Grid>

            {isNews === "NOTICIA" ? (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={2}>
                  Es noticia de SOLIDAR:
                </Grid>
                <Grid item xs={12} md={10}>
                  <Controller
                    name="esNoticiaSolidar"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        ref={null}
                        //TODO add validation for news and interest link
                        // required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}

            {isNews === "NOTICIA" ? (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={2}>
                  Es noticia destacada:
                </Grid>
                <Grid item xs={12} md={10}>
                  <Controller
                    name="esDestacada"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        ref={null}
                        //TODO add validation for news and interest link
                        // required
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}

            {isNews === "NOTICIA" ? (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={2}>
                  <Typography
                    component={"span"}
                    sx={{ ...DesignSystem?.cuerpo2 }}
                  >
                    Contenido de la noticia*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Suspense fallback={<div>Cargando...</div>}>
                    <ComponentCkEditor
                      id="editor-cursos-form"
                      onChange={onEditorStateChange}
                      value={contenidoHTML}
                      ocultarBordes={false}
                      setImagesDeleteEditor={setImagesDeleteEditor}
                    />
                  </Suspense>
                </Grid>
              </Grid>
            ) : null}
            {isNews === "ENLACE_INTERES" ? (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={2}>
                  <Typography
                    component={"span"}
                    sx={{ ...DesignSystem?.cuerpo2 }}
                  >
                    Url de la noticia*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Controller
                    name="url"
                    control={control}
                    defaultValue=""
                    rules={{
                      required:
                        isNews === "ENLACE_INTERES"
                          ? "El campo es requerido"
                          : false,
                    }}
                    render={({ field }) => (
                      <CInputForm
                        inputIcon={<RolIcon />}
                        type="text"
                        placeholder="Escribe la url de la noticia"
                        {...field}
                        ref={null}
                        error={errors.url}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}
            {isNews === "ENLACE_INTERES" ? (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={2}>
                  <Typography
                    component={"span"}
                    sx={{ ...DesignSystem?.cuerpo2 }}
                  >
                    Medio*
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Controller
                    name="medio_cod"
                    control={control}
                    defaultValue={undefined}
                    rules={{
                      required:
                        isNews === "ENLACE_INTERES"
                          ? "El campo es requerido"
                          : false,
                    }}
                    render={({ field }) => (
                      <CSelectForm
                        placeholder="Selecciona el medio"
                        fullWidth
                        items={media}
                        {...field}
                        ref={null}
                        required
                        error={errors.medio_cod}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Añadir foto portada
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Box display="flex" flexDirection="column">
                  <CFilePicker
                    multiple={false}
                    placeholder="Carga la foto de portada"
                    handleChange={(ev: ChangeEvent<HTMLInputElement>) =>
                      handleChangePicker(ev)
                    }
                    accept="image/*"
                  />
                  <Box>
                    {adjuntos.map((adjunto: File, indiceAdjunto: number) => (
                      <Chip
                        deleteIcon={
                          <IconButton
                            size="small"
                            sx={{ stroke: colorsBase.Rojo }}
                          >
                            <CancelCircleIcon width="20" height="20" />
                          </IconButton>
                        }
                        onDelete={() => {
                          const _adj = [...adjuntos];
                          _adj.splice(indiceAdjunto, 1);
                          setAdjuntos(_adj);
                        }}
                        label={adjunto.name ? `${adjunto.name}` : ""}
                        key={indiceAdjunto}
                        sx={{
                          margin: 0.5,
                          border: `1px solid ${colorsBase.Verde3}`,
                        }}
                      />
                    ))}
                  </Box>
                  <Box>
                    {!!currentAdjunto && adjuntos.length === 0 ? (
                      <Chip
                        deleteIcon={
                          <IconButton
                            size="small"
                            sx={{ stroke: colorsBase.Rojo }}
                          >
                            <CancelCircleIcon width="20" height="20" />
                          </IconButton>
                        }
                        onDelete={() => {
                          console.log("eliminando adjunto!!");
                          const _adj = { ...currentAdjunto };
                          setCurrentAdjunto(null);
                          const _deletedAdj = [...deletedAdjuntos];
                          _deletedAdj.push(_adj);
                          setDeletedAdjuntos(_deletedAdj);
                        }}
                        label={`${currentAdjunto.nombre}`}
                        sx={{
                          margin: 0.5,
                          border: `1px solid ${colorsBase.Verde3}`,
                        }}
                      />
                    ) : null}
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {isNews === "NOTICIA" && (
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} md={2}>
                  <Typography
                    component={"span"}
                    sx={{ ...DesignSystem?.cuerpo2 }}
                  >
                    Añadir imágenes para el carrusel
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Box display="flex" flexDirection="column">
                    <CFilePicker
                      multiple={true}
                      placeholder="Carga imágenes para el carrusel"
                      handleChange={(ev: ChangeEvent<HTMLInputElement>) =>
                        handleChangePickerCarousel(ev)
                      }
                      accept="image/*"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      {currentImgCarousel.length > 0 &&
                        currentImgCarousel.map((adjunto, index) => (
                          <Box
                            key={index}
                            sx={{
                              position: "relative",
                              width: 120,
                              height: 120,
                              borderRadius: "8px",
                              overflow: "hidden",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: 0.5,
                              border: `1px solid ${colorsBase.Verde3}`,
                              boxShadow: 2,
                            }}
                          >
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                borderRadius: "5px",
                              }}
                              src={adjunto.url}
                              alt="miniatura"
                            />
                            <IconButton
                              onClick={() => {
                                const _adj = [...currentImgCarousel];
                                _adj.splice(index, 1);
                                setCurrentImgCarousel(_adj);
                                setDeletedImgCarousel([
                                  ...deletedImgCarousel,
                                  adjunto,
                                ]);
                              }}
                              size="small"
                              title="Eliminar imagen"
                              sx={{
                                position: "absolute",
                                top: 4,
                                right: 4,
                                stroke: colorsBase.Rojo,
                                backgroundColor: "transparent",
                                "&:hover": {
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                },
                              }}
                            >
                              <CancelCircleIcon width="20" height="20" />
                            </IconButton>
                          </Box>
                        ))}
                      {imgCarousel.map(
                        (adjunto: File, indiceAdjunto: number) => (
                          <Box
                            key={indiceAdjunto}
                            sx={{
                              position: "relative",
                              width: 120,
                              height: 120,
                              borderRadius: "8px",
                              overflow: "hidden",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              margin: 0.5,
                              border: `1px solid ${colorsBase.Verde3}`,
                              boxShadow: 2,
                            }}
                          >
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                                borderRadius: "5px",
                              }}
                              src={URL.createObjectURL(adjunto)}
                              alt="miniatura"
                            />
                            <IconButton
                              onClick={() => {
                                const _adj = [...imgCarousel];
                                _adj.splice(indiceAdjunto, 1);
                                setImgCarousel(_adj);
                              }}
                              size="small"
                              title="Eliminar imagen"
                              sx={{
                                position: "absolute",
                                top: 4,
                                right: 4,
                                stroke: colorsBase.Rojo,
                                backgroundColor: "transparent",
                                "&:hover": {
                                  backgroundColor: "rgba(255, 255, 255, 1)",
                                },
                              }}
                            >
                              <CancelCircleIcon width="20" height="20" />
                            </IconButton>
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            margin: 2,
            marginTop: 0,
            paddingTop: 1,
            borderTop: `1px solid ${colorsBase.BorderGray}`,
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <CancelButton disabled={loadingModal || loading} onClick={handleCloseDialogNoticia}>
              Cancelar
            </CancelButton>
            <ConfirmButton
              form="form-noticia"
              onClick={handleSubmit(handleSubmitBorrador)}
              sx={{ ml: 2 }}
              loading={loading || loadingModal}
            >
              Guardar borrador
            </ConfirmButton>
            {/* <ConfirmButton disabled sx={{ ml: 2 }}>
            Vista previa
          </ConfirmButton> */}
            <ConfirmButton
              onClick={handleSubmit(handleSubmitPublicar)}
              sx={{ ml: 2 }}
              loading={loading || loadingModal}
            >
              Publicar
            </ConfirmButton>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
