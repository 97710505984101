import {
  Alert,
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { colorsBase, DesignSystem } from "../../../themes/theme";
import { CancelRounded, CloseRounded, Download } from "@mui/icons-material";
import {
  CancelButton,
  ConfirmButton,
} from "../../../shared/components/CButton";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CFilePicker } from "../../../shared/components/form/CFilePicker";
import { ChangeEvent, useState } from "react";
import { printLog } from "../../../shared/utils";
import CSelectForm from "../../../shared/components/form/CSelectForm";
import { yearsCifra } from "../constants";
import { useCreateCifras, useDownloadTemplate } from "../data";
import { Alerts } from "../../../shared/hooks/useAlert";

export interface ICifraForm {
  gestion: string;
}

type CifraFormDialogType = {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export const CifraFormDialog = ({
  open,
  onClose,
  onSuccess,
}: CifraFormDialogType) => {
  const [adjuntos, setAdjuntos] = useState<File[]>([]);
  const { handleSubmit, control } = useForm<ICifraForm>();

  const [loading, setLoading] = useState<boolean>(false);

  const handleChangePicker = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files && target.files.length) {
      printLog(
        "[CifraForm] >  Archivos seleccionados de FilePicker :: target.files[0]",
        target.files![0]
      );
      setAdjuntos([...target.files]);
    }
  };

  const createCifras = useCreateCifras();
  const handleSubmitGuardar: SubmitHandler<ICifraForm> = async (data) => {
    printLog("guardar archivo de cifras :: ", data);
    printLog("archivos de cifras :: ", adjuntos);

    if (!adjuntos.length) {
      Alerts.showError({
        description: "Debe seleccionar un archivo para cargar las cifras",
      });
      return;
    }

    if (!data.gestion) {
      Alerts.showError({
        description: "Debe seleccionar la gestión de las cifras",
      });
      return;
    }

    setLoading(true);

    const payload = {
      year: data.gestion,
      files: adjuntos,
    };
    const create = await createCifras(payload);
    if (create) {
      onSuccess();
      Alerts.showSuccess({
        description: "Cifras cargadas correctamente",
      });
    }
    setTimeout(() => {
      setLoading(false);
    }, 300);
  };

  const downloadTemplatePetition = useDownloadTemplate()
  const downloadTemplate = async () => {
    setLoading(true)
    await downloadTemplatePetition()
    setTimeout(() => {
      setLoading(false)
    }, 300);
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ borderBottom: `1px solid ${colorsBase.BorderGray}` }}
        >
          <Typography component={"span"} sx={{ ...DesignSystem?.titulo1 }}>
            Agregar Cifras de tu municipio
          </Typography>
          <IconButton disabled={loading} onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box component={"form"} onSubmit={handleSubmit(handleSubmitGuardar)}>
        <DialogContent>
          <Alert variant="outlined" severity="info" sx={{ mb: 2 }}>
            <Box
              style={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                gap: 10,
              }}
              onClick={downloadTemplate}
            >
              <Typography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  ...DesignSystem?.cuerpo2,
                }}
                component={"span"}
              >
                Para cargar las cifras de tu municipio, descarga la plantilla de
                Excel y completa los datos solicitados.
              </Typography>
              <Download />
            </Box>
          </Alert>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={"span"} sx={{ ...DesignSystem?.cuerpo2 }}>
                Gestión*
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Controller
                name="gestion"
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <CSelectForm
                    placeholder="Selecciona la gestión de la cifra"
                    fullWidth
                    items={yearsCifra}
                    disabled={loading}
                    {...field}
                    ref={null}
                    required
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
              <Typography component={"span"} sx={{ ...DesignSystem?.cuerpo2 }}>
                Archivo de cifras*
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <CFilePicker
                multiple={false}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                placeholder="Carga el archivo adjunto de cifras"
                handleChange={(ev: ChangeEvent<HTMLInputElement>) =>
                  handleChangePicker(ev)
                }
              />
              <Box>
                {adjuntos.map((adjunto: File, indiceAdjunto: number) => (
                  <Chip
                    deleteIcon={
                      <IconButton size="small" sx={{ stroke: colorsBase.Rojo }}>
                        <CancelRounded width="20" height="20" />
                      </IconButton>
                    }
                    onDelete={() => {
                      const _adj = [...adjuntos];
                      _adj.splice(indiceAdjunto, 1);
                      setAdjuntos(_adj);
                    }}
                    label={adjunto.name ? `${adjunto.name}` : ""}
                    key={indiceAdjunto}
                    sx={{
                      margin: 0.5,
                      border: `1px solid ${colorsBase.Verde3}`,
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            margin: 2,
            marginTop: 0,
            paddingTop: 1,
            borderTop: `1px solid ${colorsBase.BorderGray}`,
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <CancelButton disabled={loading} onClick={onClose}>
              Cancelar
            </CancelButton>
            <ConfirmButton
              onClick={handleSubmit(handleSubmitGuardar)}
              sx={{ ml: 2 }}
              loading={loading}
            >
              Guardar
            </ConfirmButton>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
