import {
  Box,
  LinearProgress,
  Pagination,
  PaginationItem,
  Stack,
  styled,
  useMediaQuery,
} from "@mui/material";
import theme from "../../../../themes";
import MunicipalityCard from "./components/MunicipalityCard";
import ResourceSelector from "./components/ResourceSelector";
import SearchMunicipality from "./components/SearchMunicipality";
import courseOne from "../../../../assets/GoodMunicipalPractices/courses/course_one.png";
import LaPaz from "../../../../assets/GoodMunicipalPractices/departments/lapaz.png";
import Oruro from "../../../../assets/GoodMunicipalPractices/departments/oru.png";
import SantaCruz from "../../../../assets/GoodMunicipalPractices/departments/scz.png";
import Pando from "../../../../assets/GoodMunicipalPractices/departments/pdo.png";
import Potosi from "../../../../assets/GoodMunicipalPractices/departments/potosi.png";
import Tarija from "../../../../assets/GoodMunicipalPractices/departments/tarija.png";
import Beni from "../../../../assets/GoodMunicipalPractices/departments/bni.png";
import Cochabamba from "../../../../assets/GoodMunicipalPractices/departments/cbba.png";
import Chuquisaca from "../../../../assets/GoodMunicipalPractices/departments/chq.png";
import useAppContext from "../../../../shared/hooks/useAppContext";
import { useEffect, useRef, useState } from "react";
import ResourceModal from "./components/ResourceModal";

type PaginationType = {
  count: number;
  from: number;
  to: number;
};

const StyledBox = styled(Box)(() => ({
  margin: "0 0 0 0",
  padding: 0,
  width: "100%",
}));

const Resources = () => {
  const { goodPractices, getFilteredGoodPractices } = useAppContext();
  // const http = useHttp();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const pageSize = 3;
  const [pagination, setPagination] = useState<PaginationType>({
    count: 0,
    from: 0,
    to: pageSize,
  });

  const handlePageChange = (event: any, page: any) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;

    let data: any = goodPractices.slice(from, to);
    setSlicedGoodPractices(data);

    setPagination({ ...pagination, from: from, to: to });
    setTimeout(() => {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: isMobile ? "start" : "center",
      });
    }, 10);
  };

  const getImageForDepartment = (department: String) => {
    switch (department) {
      case "LA PAZ":
        return LaPaz;
      case "ORURO":
        return Oruro;
      case "COCHABAMBA":
        return Cochabamba;
      case "CHUQUISACA":
        return Chuquisaca;
      case "TARIJA":
        return Tarija;
      case "SANTA CRUZ":
        return SantaCruz;
      case "BENI":
        return Beni;
      case "PANDO":
        return Pando;
      case "POTOSI":
        return Potosi;
      default:
        return courseOne;
    }
  };

  //Data to display
  const [slicedGoodPractices, setSlicedGoodPractices] = useState([]);
  // Filters
  const [resourceType, setResourceType] = useState<string>("");
  const [departamento, setDepartamento] = useState("");
  const [municipio, setMunicipio] = useState("");
  // Resource modal
  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [modalResourceType, setModalResourceType] = useState("");
  const [modalUrl, setModalUrl] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const handleSetResourceType = async (str: string) => {
    setLoading(true);
    await getFilteredGoodPractices(str, departamento, municipio);
    setResourceType((prev) => {
      return str;
    });
    setLoading(false);
  };

  const handleSetDpto = async (str: string) => {
    setLoading(true);
    await getFilteredGoodPractices(resourceType, str, municipio);
    setDepartamento(str);
    setLoading(false);
  };
  

  const handleSetMunicipio = (str: string) => {
    setMunicipio((prev) => {
      getFilteredGoodPractices(resourceType, departamento, str);
      return str;
    });
  };

  useEffect(() => {
    let tmpArray: any = goodPractices.slice(0, pageSize);
    setSlicedGoodPractices(tmpArray);
    setPagination({ from: 0, to: pageSize, count: goodPractices.length });
  }, [goodPractices]);

  const handleCloseModal = () => {
    setIsResourceModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsResourceModalOpen(true);
  };

  const handleModalResource = (resourceType: string, modalUrl: string) => {
    setModalResourceType(resourceType);
    setModalUrl(modalUrl);
  };

  const ref = useRef<HTMLElement>();

  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          margin: 0,
          padding: 0,
        }}
      >
        <Box
          display="flex"
          flexDirection={{ xs: "column", md: "row" }}
          sx={{ width: "100%" }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "60%",
              },
              order: {
                xs: 2,
                md: 1,
              },
            }}
          >
            <Box
              ref={ref}
              sx={{
                height: "45px",
              }}
            />
            {loading ? (
              <LinearProgress />
            ) : slicedGoodPractices?.length > 0 ? (
              slicedGoodPractices.map((item: any, index) => {
                return (
                  <MunicipalityCard
                    key={index}
                    department={item.departamento_desc}
                    municipality={item.municipio_desc}
                    title={item.titulo}
                    date={item.fecha_publicacion}
                    content={item.buena_descripcion}
                    image={getImageForDepartment(item.departamento_desc)}
                    resourceType={item.tipo_recurso}
                    resourceUrl={item.buena_recurso}
                    handleOpenModal={handleOpenModal}
                    handleModalResource={handleModalResource}
                  />
                );
              })
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                No se encontraron datos.{" "}
                {departamento ? "Intente con otro departamento." : ""}
              </Box>
            )}
          </Box>
          <Box
            sx={{
              width: {
                xs: "100%",
                md: "40%",
              },
              padding: "0 0 0 1.5em",
              order: {
                xs: 1,
                md: 2,
              },
              [theme.breakpoints.down("md")]: {
                padding: "0 1.5em 0 1.5em",
              },
            }}
          >
            <ResourceSelector handleSetResourceType={handleSetResourceType} />
            <SearchMunicipality
              handleSetDpto={handleSetDpto}
              handleSetMunicipio={handleSetMunicipio}
            />
          </Box>
        </Box>
        <Box mt={"1.5em"}>
          <Stack spacing={2}>
            <Pagination
              color="primary"
              renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    backgroundColor: "#dedede",
                    color: "theme.palette.neutral.active",
                  }}
                />
              )}
              count={Math.ceil(pagination.count / pageSize)}
              onChange={handlePageChange}
            />
          </Stack>
        </Box>
      </Box>
      {isResourceModalOpen && (
        <ResourceModal
          open={isResourceModalOpen}
          onClose={handleCloseModal}
          modalResourceType={modalResourceType}
          modalUrl={modalUrl}
        />
      )}
    </StyledBox>
  );
};

export default Resources;
