import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { CourseNeutralButton } from "../Courses/shared/Elements";
import { ArrowBackRounded } from "@mui/icons-material";
import React from "react";
import {
  MunicipalityOverview,
  SaveDirectoryRequest,
  useGetMunicipalityOverview,
  useSaveDirectory,
} from "./DirectorioClient";
import CInputForm from "../../shared/components/form/CInputForm";
import { Controller, useForm } from "react-hook-form";
import { CancelButton, ConfirmButton } from "../../shared/components/CButton";
import { Alerts } from "../../shared/hooks/useAlert";

export function DirectorioFormPage() {
  const { municipio_cod } = useParams<{ municipio_cod: string }>();
  const {
    isError,
    isLoading,
    data: overview,
    refetch,
  } = useGetMunicipalityOverview(Number(municipio_cod));
  const { push } = useHistory();

  if (isLoading) return <p> Cargando... </p>;

  if (isError || !overview)
    return <p>No se pudo cargar los datos del municipio</p>;

  return (
    <>
      <PageHeader municipio_desc={overview.municipio_desc}></PageHeader>

      <DirectorioForm
        municipalityOverview={overview}
        onSaveFinished={() => {
          refetch();
          push("/admin/directorio");
        }}
      />
    </>
  );
}

function PageHeader({ municipio_desc }: { municipio_desc: string }) {
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const { goBack } = useHistory();

  // Estilos copiados descaradamente de CourseDetail.tsx
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gap={2}
      alignContent="center"
      sx={{
        borderBottom: "1px solid #cacaca",
        paddingX: "2rem",
        paddingY: "1rem",
      }}
    >
      <Typography
        sx={{
          flex: 1,
          fontSize: matchesPhone ? 12 : 18,
          fontWeight: 500,
        }}
      >
        Directorio Municipal: {municipio_desc}
      </Typography>
      <CourseNeutralButton onClick={goBack}>
        <ArrowBackRounded />
      </CourseNeutralButton>
    </Box>
  );
}

type DirectorioFormValues = {
  title: string;
  subtitle: string;
  phoneNumber1: string;
  phoneNumber2: string;
  addresses: string;
};

function DirectorioForm({
  municipalityOverview,
  onSaveFinished,
}: {
  municipalityOverview: MunicipalityOverview;
  onSaveFinished: () => void;
}) {
  const {
    data: initialValues,
    municipio_cod,
    municipio_desc,
  } = municipalityOverview;

  console.log("DirectorioForm Props", municipalityOverview);
  const { goBack } = useHistory();

  const { control, handleSubmit } = useForm({
    defaultValues: getFormValues(initialValues),
  });

  const { mutate, isPending } = useSaveDirectory();

  const onSubmit = (formValuesByType: Record<string, DirectorioFormValues>) => {
    mutate(getRequest(municipio_cod, formValuesByType), {
      onSuccess: () => {
        Alerts.showSuccess({
          title: `Directorio guardado`,
          description: `Los datos del municipio ${municipio_desc} se guardaron.`,
        });
        onSaveFinished();
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={1}>
        {Object.entries(initialValues).map(([type, data]) => (
          <Card key={type}>
            <CardHeader title={type}>{type}</CardHeader>
            <CardContent>
              <Grid item container xs={12} md={6} gap={1}>
                <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                  <Typography component={"span"}>Titulo</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Controller
                    name={`${type}.title`}
                    control={control}
                    render={(renderProps) => {
                      return (
                        <CInputForm
                          type="text"
                          fullWidth
                          disabled={true}
                          {...renderProps.field}
                          ref={null}
                        ></CInputForm>
                      );
                    }}
                  />
                </Grid>

                {type === "ALCALDIA" && (
                  <>
                    <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                      <Typography component={"span"}>Alcalde</Typography>
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Controller
                        name={`${type}.subtitle`}
                        control={control}
                        render={(renderProps) => {
                          return (
                            <CInputForm
                              type="text"
                              fullWidth
                              {...renderProps.field}
                              ref={null}
                            ></CInputForm>
                          );
                        }}
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                  <Typography component={"span"}>Teléfono 1</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Controller
                    name={`${type}.phoneNumber1`}
                    control={control}
                    render={(renderProps) => {
                      return (
                        <CInputForm
                          type="text"
                          fullWidth
                          {...renderProps.field}
                          ref={null}
                        ></CInputForm>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                  <Typography component={"span"}>Teléfono 2</Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Controller
                    name={`${type}.phoneNumber2`}
                    control={control}
                    render={(renderProps) => {
                      return (
                        <CInputForm
                          type="text"
                          fullWidth
                          {...renderProps.field}
                          ref={null}
                        ></CInputForm>
                      );
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={3} sx={{ textAlign: "left" }}>
                  <Typography component={"span"}>
                    Direcciones (1 por linea)
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Controller
                    name={`${type}.addresses`}
                    control={control}
                    render={(renderProps) => {
                      return (
                        <CInputForm
                          type="text"
                          fullWidth
                          multiline
                          {...renderProps.field}
                          ref={null}
                        ></CInputForm>
                      );
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}

        <Box display="flex" justifyContent="flex-end">
          <CancelButton onClick={() => goBack()}>
            Cancelar
          </CancelButton>
          <ConfirmButton
            type="submit"
            // form="form-noticia"
            //onClick={handleSubmit(handleSubmitBorrador)}
            sx={{ ml: 2 }}
            loading={isPending}
          >
            Guardar
          </ConfirmButton>
        </Box>
      </Stack>
    </form>
  );
}

function getRequest(
  municipio_cod: number,
  formValuesByType: Record<string, DirectorioFormValues>,
): SaveDirectoryRequest {
  const data: SaveDirectoryRequest["data"] = {};

  Object.entries(formValuesByType).forEach(
    ([type, { title, subtitle, phoneNumber1, phoneNumber2, addresses }]) => {
      data[type] = {
        title:
          type === "ALCALDIA"
            ? [title, subtitle].map((str) => str.trim()).join(" - Alcalde: ")
            : title,
        phoneNumbers: [phoneNumber1.trim(), phoneNumber2.trim()].filter(
          (str) => str !== "",
        ),
        addresses: addresses
          .split("\n")
          .map((str) => str.trim())
          .filter((str) => str !== ""),
      };
    },
  );

  return {
    municipio_cod,
    data,
  };
}

function getFormValues(initialValues: {
  [p: string]: { addresses: string[]; phoneNumbers: string[]; title: string };
}) {
  const formValuesByType: Record<string, DirectorioFormValues> = {};

  Object.entries(initialValues).forEach(([type, directory]) => {
    const [title, subtitle] =
      type === "ALCALDIA"
        ? [getTitle(type), parseAlcaldiaTitle(directory.title)[1]]
        : [getTitle(type), ""];

    formValuesByType[type] = {
      title,
      subtitle,
      phoneNumber1: directory.phoneNumbers[0] ?? "",
      phoneNumber2: directory.phoneNumbers[1] ?? "",
      addresses: directory.addresses.join("\n"),
    };

    directory.title = getTitle(type);
  });
  return formValuesByType;
}

function getTitle(type: string): string {
  switch (type) {
    case "ALCALDIA":
      return "Gobierno Autónomo Municipal";
    case "SALUD":
      return "Centro de Salud/Hospital/Posta";
    case "SLIM":
      return "SLIM - DNA";
    case "FORENSE":
      return "Médico Forense";
    case "DESARROLLO_HUMANO_SOCIAL":
      return "Desarrollo humano/social en el GAM";
    case "JUZGADO":
      return "Juzgado";
    case "POLICIA":
      return "Oficina policial";
    case "FISCALIA":
      return "Fiscalía";
    default:
      return "--";
  }
}

function parseAlcaldiaTitle(title: string) {
  const i = title.indexOf(":");
  if(i > 0) {
    return [title.slice(0, i), title.slice(i + 1)];
  } else {
    return [title, ""]
  }
}
