import {
  Autocomplete,
  Box,
  Container,
  FormControl,
  TextField,
} from "@mui/material";
// import { colorsBase, DesignSystem } from "../../theme";
import { colorsBase, DesignSystem } from "../../../themes/theme";
import { printLog } from "../../utils";
import { Alerts } from "../../hooks/useAlert";
// import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrowDown.svg';

// interface ItemProp {
//   value: string | number;
//   label: string;
// }

const CAutocomplete = (props: any) => {
  const inputProps = { ...props };
  delete inputProps.inputIcon;
  delete inputProps.error;
  delete inputProps.placeholder;
  delete inputProps.onChange;
  delete inputProps.maxSelection;

  return (
    <FormControl fullWidth variant="standard">
      <Box
        sx={{
          display: "flex",
          alignItems: "stretch",
          backgroundColor: "#ECECEC",
          borderRadius: "8px",
          border: "none",
          borderColor: "transparent",
          ...(props.error
            ? {
                borderWidth: 0.5,
                borderStyle: "solid",
                borderColor: colorsBase.Rojo,
              }
            : {}),
        }}
      >
        {props.inputIcon && (
          <Box
            sx={{
              color: "action.active",
              backgroundColor: props.error
                ? colorsBase.Rojo
                : colorsBase.Texto2,
              px: 2,
              py: 2,
              borderRadius: "7px",
            }}
          >
            {props.inputIcon}
          </Box>
        )}

        <Autocomplete
          disablePortal
          fullWidth
          multiple={props.multiple}
          filterSelectedOptions={false}
          options={Array.isArray(props.items) ? props.items : []}
          getOptionLabel={(option: any) => option.label || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={props.placeholder}
              // variant="standard"
            />
          )}
          value={props.value}
          onChange={(_, newValue) => {
            if (
              props.maxSelection &&
              Array.isArray(newValue) &&
              newValue.length > props.maxSelection
            ) {
              printLog("maxSelection", props.maxSelection);
              Alerts.showError({
                confirmText: "Aceptar",
                description: `Solo puedes seleccionar ${props.maxSelection} ${
                  props.maxSelection === 1 ? "opción" : "opciones"
                }`,
              });
              return;
            }

            if (props.multiple) {
              const uniqueValues = Array.isArray(newValue)
                ? newValue.filter(
                    (opt, index, self) =>
                      self.findIndex((o) => o.value === opt.value) === index
                  )
                : [];
              props.onChange(uniqueValues);
            } else {
              props.onChange(newValue || null);
            }
          }}
          {...inputProps}
        />
      </Box>
      <Container>
        <span
          style={{
            ...DesignSystem?.cuerpo3,
            color: colorsBase.Rojo,
            paddingTop: "3px",
          }}
        >
          {props.error && props.error.message ? props.error.message : ""}
        </span>
      </Container>
    </FormControl>
  );
};

export default CAutocomplete;
