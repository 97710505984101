import { Box, Button, Grid, Typography, styled } from "@mui/material";
import GeographicScope from "./GeographicScope";
import PublishType from "./PublishType";
import SearchIcon from "@mui/icons-material/Search";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { useState } from "react";
import theme from "../../../../../themes";
import CInputForm from "../../../../../shared/components/form/CInputForm";
import { printLog } from "../../../../../shared/utils";
import dayjs, { Dayjs } from "dayjs";
import CategoryType from "./CategoryType";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: "2em 0 3em 0",
  width: "100%",
  backgroundColor: "#CECECE",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "8px",
  color: theme.palette.secondary.active,
  // width: "100%",
  height: "50px",
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
    color: theme.palette.common.white,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const StyledButtonW = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  backgroundColor: theme.palette.common.white,
  borderRadius: "8px",
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  // width: "100%",
  height: "50px",
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
    color: theme.palette.common.white,
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const Categories = ({ handleFilters }: any) => {
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [keyWords, setKeyWords] = useState<string>("");
  const [geographicScope, setGeographicScope] = useState<string>("");
  const [publishType, setPublishType] = useState<string>("");
  // const [from, setFrom] = useState<Dayjs|null>(dayjs());
  const [from, setFrom] = useState<Dayjs | null>(null);
  const [to, setTo] = useState<Dayjs | null>(null);

  const handleSetCategory = (str: string) => {
    setSelectedCategory(str);
  };

  const handleKeyWords = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeyWords(e.target.value);
  };

  const updateGeographicScope = (val: string) => {
    setGeographicScope(val);
  };

  const updatePublishType = (val: string) => {
    setPublishType(val);
  };

  const handleClean = () => {
    setSelectedCategory("");
    setKeyWords("");
    setGeographicScope("");
    setPublishType("");
    setFrom(null);
    setTo(null);
    handleFilters("");
  };

  const getFilteredNews = () => {
    let filters = [];

    let trimCategory = selectedCategory.trim();
    let trimKeywords = keyWords.trim();
    let trimGeographicScope = geographicScope.trim();
    let trimPublishType = publishType.trim();

    if (trimCategory) filters.push(`categoria=${trimCategory}`);
    if (trimKeywords) filters.push(`palabrasClave=${trimKeywords}`);
    if (trimGeographicScope)
      filters.push(`alcanceGeografico=${trimGeographicScope}`);
    if (trimPublishType) filters.push(`tipo=${trimPublishType}`);
    if (from) filters.push(`desde=${dayjs(from).format("YYYY-MM-DD")}`);
    if (to) filters.push(`hasta=${dayjs(to).format("YYYY-MM-DD")}`);

    let filtersStr = filters.length ? "?" + filters.join("&") : "";
    printLog("Filtros generados >>> ", filtersStr);
    handleFilters(filtersStr);
  };

  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: "0 1.5em 0 1.5em",
        }}
      >
        {/* Box Categorías */}
        <Box display="flex" flexDirection="column" width="100%" mt={1}>
          <Typography
            variant="h4"
            textAlign={"center"}
            fontWeight={"bold"}
            color={"rgb(74,12,85,0.8)"}
          >
            ARCHIVO DE NOTICIAS
          </Typography>
          <Box my={2} />
          <Typography
            textAlign={"center"}
            fontWeight={"medium"}
            variant="body1"
            sx={
              {
                // textTransform: "uppercase",
                // fontFamily: "Barlow",
                // fontSize: 18,
                // color: theme.palette.neutral.hover,
              }
            }
          >
            En este espacio encontrarás las noticias del proyecto que reflejan
            el trabajo y la gestión de los actores clave y enlaces de interés
            relevantes sobre la temática.
          </Typography>
          <Box my={2} />
        </Box>
        {/* <Box
          display="flex"
          flexWrap="wrap"
          justifyContent="space-evenly"
          gap={2}
          my={2}
        >
          <CategoryBox
            text="Social"
            txtLabel="SOCIAL"
            image={iSoc}
            imageHover={iSocH}
            selectedCategory={selectedCategory}
            handleSetCategory={handleSetCategory}
          />
          <CategoryBox
            text="Económico"
            txtLabel="ECONOMICO"
            image={iEco}
            imageHover={iEcoH}
            selectedCategory={selectedCategory}
            handleSetCategory={handleSetCategory}
          />
          <CategoryBox
            text="Político"
            txtLabel="POLITICO"
            image={iPolitics}
            imageHover={iPoliticsH}
            selectedCategory={selectedCategory}
            handleSetCategory={handleSetCategory}
          />
          <CategoryBox
            text="Tecnológico"
            txtLabel="TECNOLOGICO"
            image={iTec}
            imageHover={iTecH}
            selectedCategory={selectedCategory}
            handleSetCategory={handleSetCategory}
          />
          <CategoryBox
            text="Ecológico"
            txtLabel="ECOLOGICO"
            image={iEcol}
            imageHover={iEcolH}
            selectedCategory={selectedCategory}
            handleSetCategory={handleSetCategory}
          />
          <CategoryBox
            text="Legal"
            txtLabel="LEGAL"
            image={iLeg}
            imageHover={iLegH}
            selectedCategory={selectedCategory}
            handleSetCategory={handleSetCategory}
          />
          <CategoryBox
            text="Salud"
            txtLabel="SALUD"
            image={iSal}
            imageHover={iSalH}
            selectedCategory={selectedCategory}
            handleSetCategory={handleSetCategory}
          />
        </Box> */}
        <Box display="flex" flexDirection="column" width="100%">
          {/* Box Filtros */}
          <Box
            my={2}
            sx={{
              width: "100%",
              backgroundColor: theme.palette.common.white,
              borderRadius: "8px",
              padding: "1.5em 1.5em 1.5em 1.5em",
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4} lg={3}>
                <CInputForm
                  value={keyWords}
                  onChange={handleKeyWords}
                  placeholder="Escribe una palabra clave"
                  sx={{
                    backgroundColor: "#dedede",
                    // color: "black",
                    borderRadius: "8px",
                    "& input::placeholder": {
                      // color: theme.palette.neutral.dark,
                      color: "black",
                      opacity: 1,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <GeographicScope
                  geographicScope={geographicScope}
                  updateGeographicScope={updateGeographicScope}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <PublishType
                  publishType={publishType}
                  updatePublishType={updatePublishType}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <CategoryType
                  categoryType={selectedCategory}
                  updateCategoryType={handleSetCategory}
                />
              </Grid>
              {/* <Grid item xs={12} md={4} lg={2}>
                <CDatePicker
                  placeholder="Desde"
                  value={from}
                  onChange={setFrom}
                  sx={{
                    backgroundColor: "#dedede",
                    "& input::placeholder": {
                      color: theme.palette.neutral.active,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4} lg={2}>
                <CDatePicker
                  placeholder="Hasta"
                  value={to}
                  onChange={setTo}
                  sx={{
                    backgroundColor: "#dedede",
                    "& input::placeholder": {
                      color: theme.palette.neutral.active,
                    },
                  }}
                />
              </Grid> */}
              <Grid
                item
                xs={12}
                md={4}
                lg={3}
                display={"flex"}
                justifyContent={"end"}
                gap={1}
              >
                <StyledButton
                  startIcon={<SearchIcon fontSize="small" />}
                  onClick={getFilteredNews}
                >
                  Buscar
                </StyledButton>
                <StyledButtonW
                  startIcon={<CleaningServicesIcon fontSize="small" />}
                  onClick={handleClean}
                >
                  Limpiar
                </StyledButtonW>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </StyledBox>
  );
};

export default Categories;
