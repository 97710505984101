import { Box, Button, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import bgMunicipalPractices from "../../../../assets/bg_municipios.jpg";
import bgBannerRosita from "../../../../assets/GoodMunicipalPractices/banner_rosita.png";
import { alpha, styled } from "@mui/material/styles";
import theme from "../../../../themes";
import React, { useEffect, useState } from "react";
import { useGetFeaturedGoodPractice } from "../../../../context/app";

const StyledImg = styled("img")(() => ({
  maxWidth: "100%",
  height: "100%",
  objectFit: "contain",
}));

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  height: "70vh",
  backgroundImage: `url(${bgMunicipalPractices})`,
  backgroundAttachment: "fixed",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));

const StyledBoxSecondary = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  height: "30vh",
  backgroundImage: `url(${bgBannerRosita})`,
  // backgroundAttachment: "fixed",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "3em 4em 1.5em 1em",
  backgroundColor: "rgba(0, 124, 60, 0.6)",
  width: "50%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "3em 1.5em 1.5em 1.5em",
  },
}));

const StyledDivMediaCaptionSecondary = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "1em 6em 1.5em 1.5em",
  // backgroundColor: "rgba(0, 124, 60, 0.6)",
  // backgroundColor: alpha(theme.palette.secondary.active!, 0.8),
  backgroundColor: alpha(theme.palette.common.black, 0.1),
  width: "50%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "3em 1.5em 1.5em 1.5em",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  borderRadius: "8px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.active,
  width: "200px",
  height: "45px",
  marginTop: "10px",
  [theme.breakpoints.down("md")]: {
    width: "150px",
    height: "40px",
    fontSize: 12,
  },
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
    color: theme.palette.common.white,
  },
}));

const StyledButtonSecondary = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  color: theme.palette.common.white,
  borderRadius: "8px",
  backgroundColor: theme.palette.success.main,
  width: "200px",
  height: "45px",
  marginTop: "1rem",
  background:
    "linear-gradient(to top, rgba(0, 124, 60, 0.6), rgba(0, 124, 60, 0.6))",
  [theme.breakpoints.down("md")]: {
    width: "150px",
    height: "40px",
    fontSize: 12,
  },
  "&:hover": {
    backgroundColor: theme.palette.success.active,
    color: "#E8E8E8",
  },
}));

const MunicipalPractices = () => {
  const [featuredPractice, setFeaturedPractice] = useState<any>(null);
  const getFeaturedPractice = useGetFeaturedGoodPractice();

  const fetchFeaturedPractice = async () => {
    try {
      const featuredPractice = await getFeaturedPractice();
      setFeaturedPractice(featuredPractice);
    } catch (error) {
      console.log("Ocurrió un error al obtener featured practice :: ", error);
      setFeaturedPractice(null);
    }
  };

  useEffect(() => {
    fetchFeaturedPractice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let history = useHistory();

  return (
    <React.Fragment>
      <StyledBox display="flex" justifyContent="center">
        <Box
          sx={{
            width: { xs: "100%", md: "80%", lg: "75%" },
            height: "100%",
            position: "relative",
            margin: 0,
            padding: 0,
          }}
        >
          <StyledDivMediaCaption>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              sx={{ width: "100%", height: "100%" }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: { xs: 35, md: 35, lg: 38 },
                  color: theme.palette.common.white,
                }}
              >
                Buenas Prácticas Municipales
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: { xs: "13px", md: "13px", lg: "16px" },
                  textTransform: "uppercase",
                  color: theme.palette.primary.main,
                }}
                mt={5}
              >
                Municipio destacado
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  textTransform: "uppercase",
                  fontFamily: "Barlow",
                  fontSize: { xs: "30px", md: "28px", lg: "40px" },
                  color: theme.palette.common.white,
                }}
              >
                {featuredPractice ? featuredPractice.municipio_desc : ""}
              </Typography>
              <Box
                sx={{
                  overflow: "hidden",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Ubuntu",
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "12px",
                      lg: "14px",
                    },
                    color: theme.palette.common.white,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    WebkitLineClamp: {
                      md: 4,
                      xs: 3,
                      sm: 6,
                      lg: 5,
                    },
                    textOverflow: "ellipsis",
                  }}
                >
                  {featuredPractice ? featuredPractice.buena_descripcion : ""}
                </Typography>
              </Box>
              <StyledButton
                onClick={() =>
                  history.push("/buenas-practicas#municipioDestacado")
                }
              >
                Ver más
              </StyledButton>
            </Box>
          </StyledDivMediaCaption>
        </Box>
      </StyledBox>

      <StyledBoxSecondary display="flex" justifyContent="center" mt={13} mb={6}>
        <Box
          sx={{
            width: { xs: "100%", md: "75%" },
            height: "100%",
            position: "relative",
            margin: 0,
            padding: 0,
          }}
        >
          <StyledDivMediaCaptionSecondary>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              sx={{ width: "100%", height: "100%" }}
            >
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: {
                    xs: 30,
                    md: 32,
                    lg: 42,
                  },
                  color: theme.palette.success.active,
                  // color: theme.palette.success.active,
                }}
              >
                Comparte tus buenas prácticas
              </Typography>
              <StyledButtonSecondary
                onClick={() =>
                  history.push("/buenas-practicas#formularioBuenasPrac")
                }
              >
                Compartir
              </StyledButtonSecondary>
            </Box>
          </StyledDivMediaCaptionSecondary>
        </Box>
      </StyledBoxSecondary>
    </React.Fragment>
  );
};

export default MunicipalPractices;
