export const yearsCifra = [
    {
        value: '2023-LB',
        label: '2023 - Línea base'
    },{
        value: '2023-M',
        label: '2023 - Monitoreo'
    },{
        value: '2024-M',
        label: '2024 - Monitoreo'
    },{
        value: '2025-M',
        label: '2025 - Monitoreo'
    }
]