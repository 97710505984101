import {
  Alert,
  Box,
  IconButton,
  LinearProgress,
  Typography,
  Tooltip,
  Chip,
  alpha,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { ReactComponent as KeyboardBackspace } from "../../assets/icons/keyboard-backspace.svg";
import { colorsBase, DesignSystem } from "../../themes/theme";
import {
  useCreateIniciativa,
  useGetExperienciaPractica,
  useDeleteIniciativa,
} from "./IniciativaClient";
import { IniciativaDetalle } from "./IniciativaInterface";
import { Alerts } from "../../shared/hooks/useAlert";
import dayjs from "dayjs";
import { FormIniciativaComponent } from "./components/FormIniciativaComponent";
import { useAuthContext } from "../../shared/auth/AuthContext";
import { SystemRoles } from "../../shared/common/constants";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { printLog } from "../../shared/utils";
import { useGetStockImages } from "./IniciativaClient";
import _ from "lodash";
import { CoursePrimaryButton } from "../Courses/shared/Elements";
import { VerifiedRounded } from "@mui/icons-material";
import FeedIcon from "@mui/icons-material/Feed";

export const IniciativaPage = () => {
  const navigate = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { user } = useAuthContext();
  const [iniciativas, setIniciativas] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [noticiaDialog, setNoticiaDialog] = useState<boolean>(false);
  const [stockImages, setStockImages] = useState([]);
  // const [params] = useSearchParams();
  const location = useLocation();
  const myState: any = location.state;

  /// From Client
  const getStockImages = useGetStockImages();
  const getExperienciaPractica = useGetExperienciaPractica();
  const createNoticia = useCreateIniciativa();
  const deleteIniciativa = useDeleteIniciativa();

  const fetchStockImages = useCallback(async () => {
    try {
      const imagesData = await getStockImages();

      setStockImages(imagesData as unknown as []);
    } catch (error) {
      printLog("Ocurrió un error al obtener medios :: ", error);
      setStockImages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchExperienciaPractica = useCallback(async () => {
    try {
      const iniciativasData = await getExperienciaPractica();

      let iniciativasFormatted = iniciativasData.map(
        (item: any, index: any) => {
          return {
            id: item.id,
            titulo: item.titulo,
            estado: item.publicada ? "PUBLICADO" : "BORRADOR",
            fondo: item.fondo,
            fechaPublicacion: item.fechaPublicacion,
            destacada: item.destacada,
            publicada: item.publicada,
          };
        }
      );

      const fetchedIniciativas = _.orderBy(
        iniciativasFormatted,
        ["fechaPublicacion"],
        ["desc"]
      );

      setIniciativas(fetchedIniciativas as unknown as []);
      setLoading(false);
    } catch (error) {
      printLog("Ocurrió un error al obtener iniciativas :: ", error);
      setIniciativas([]);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteIniciativa = async (id: string) => {
    printLog("onDeleteNoticia -> ", id);
    await Alerts.showConfirm({
      title: "¿Estás segura(o) de eliminar la iniciativa?.",
      description: "",
      onConfirm: async () => {
        printLog("onDeleteIniciativa -> onConfirm");
        setLoading(true);
        let error = false;
        try {
          printLog("Iniciando Borrado de iniciativa");
          await deleteIniciativa(id);
          printLog("Finalizando Borrado de iniciativa");
        } catch (e) {
          error = true;
        }

        await fetchExperienciaPractica();
        setLoading(false);

        printLog("Listos para retomar. Errores? ", error);

        !error
          ? Alerts.showSuccess({
              confirmText: "Aceptar",
              description: "¡La iniciativa fue eliminada exitosamente!",
            })
          : Alerts.showError({
              confirmText: "Continuar",
              title: "Hubo un problema",
              description:
                "Lo sentimos. No pudimos eliminar la iniciativa seleccionada",
            });
      },
    });
  };

  useEffect(() => {
    fetchStockImages();
    fetchExperienciaPractica();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onSubmitNoticia = async (data: ExperienciaPracticaPayload) => {debugger;
  const onSubmitIniciativa = async (data: any) => {
    printLog("Guardar iniciativa :: ", data);
    const _data = { ...data };

    setLoading(true);
    const resultado = await createNoticia(_data);

    if (resultado) {
      setLoading(true);
      await fetchExperienciaPractica();
      setLoading(false);
      handleCloseDialogNoticia();
      Alerts.showSuccess({
        confirmText: "Aceptar",
        description:
          data.estado === "PUBLICADO"
            ? "¡La iniciativa fue publicada exitosamente!"
            : "¡El borrador de la iniciativa fue guardado exitosamente!",
      });
    }
  };

  const handleCloseDialogNoticia = () => {
    setNoticiaDialog(false);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        mb={6}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            onClick={() =>
              navigate.push("/", {
                replace: true,
              })
            }
          >
            <KeyboardBackspace />
          </IconButton>
          <Typography sx={{ ...DesignSystem?.titulo1, pl: 2 }}>
            {myState?.state?.isPractice
              ? "Buenas Prácticas"
              : "Experiencias Innovadoras"}
          </Typography>
        </Box>
        {user?.rol !== SystemRoles.PARTICIPANTE && (
          <Box>
            <CoursePrimaryButton onClick={() => setNoticiaDialog(true)}>
              Agregar +
            </CoursePrimaryButton>
          </Box>
        )}
      </Box>
      {loading ? (
        <LinearProgress />
      ) : (
        <Box
          sx={{
            background: "rgba(255, 255, 255, 0.5)",
            boxShadow: "1.5px 2.6px 10px rgba(119, 119, 119, 0.1)",
            boxSizing: "border-box",
            borderRadius: "8px",
            p: 4,
          }}
        >
          {iniciativas
            .filter((iniciativa: IniciativaDetalle, index) => {
              if (myState?.state?.isPractice)
                return iniciativa.fondo === "FONDO_BUENAS_PRACTICAS";
              else return iniciativa.fondo === "FONDO_EXPERIENCIAS_INNOVADORAS";
            })
            .map((iniciativa: IniciativaDetalle, index) => {
              return (
                <Box
                  key={index}
                  display="flex"
                  flexDirection="row"
                  alignContent="center"
                  sx={{
                    py: {
                      xs: 0,
                      sm: 0,
                      md: 2,
                    },
                    px: {
                      xs: 0,
                      sm: 0,
                      md: 2,
                    },
                    borderBottom: `1px solid ${colorsBase.Verde3}`,
                    "&:hover": { cursor: "pointer" },
                  }}
                >
                  {user?.rol === SystemRoles.MONITOR && (
                    <Box alignSelf="center">
                      <IconButton
                        size="small"
                        onClick={() => onDeleteIniciativa(iniciativa.id)}
                        sx={{ fill: colorsBase.Azul1 }}
                      >
                        <TrashIcon />
                      </IconButton>
                    </Box>
                  )}
                  {iniciativa.fondo === "FONDO_BUENAS_PRACTICAS" && (
                    <Box alignSelf="center">
                      <Tooltip title="Buena practica">
                        <Typography
                          color="primary"
                          sx={{ fontFamily: "Ubuntu" }}
                        >
                          BP
                        </Typography>
                      </Tooltip>
                    </Box>
                  )}
                  {iniciativa.fondo === "FONDO_EXPERIENCIAS_INNOVADORAS" && (
                    <Box alignSelf="center">
                      <Tooltip title="experiencia innovadora">
                        <Typography
                          color="primary"
                          sx={{ fontFamily: "Ubuntu" }}
                        >
                          EI
                        </Typography>
                      </Tooltip>
                    </Box>
                  )}
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{
                      py: 2,
                      px: 3,
                      "&:hover": { cursor: "pointer" },
                    }}
                    onClick={() =>
                      navigate.push(`/admin/iniciativa/detalle`, {
                        noticiaId: iniciativa.id,
                        isPractice: myState.state.isPractice,
                      })
                    }
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      sx={{
                        gap: 1,
                      }}
                    >
                      {iniciativa.destacada && (
                        <Chip
                          variant="outlined"
                          color="success"
                          title="Es una publicación destacada"
                          size="small"
                          sx={{
                            background: alpha(theme.palette.success.main, 0.1),
                          }}
                          label={
                            !isMobile && (
                              <Typography variant="body2" fontWeight={"bold"}>
                                Destacado
                              </Typography>
                            )
                          }
                          icon={<VerifiedRounded />}
                        />
                      )}
                      <Typography
                        component={"span"}
                        sx={{ ...DesignSystem?.titulo2 }}
                      >
                        {iniciativa.titulo}
                      </Typography>
                      <Tooltip
                        title={
                          iniciativa.publicada
                            ? "Está publicada"
                            : "Es borrador"
                        }
                      >
                        <FeedIcon
                          fontSize="small"
                          color={iniciativa.publicada ? "info" : "disabled"}
                        />
                      </Tooltip>
                    </Box>
                    <Typography
                      component={"span"}
                      sx={{ ...DesignSystem?.cuerpo3, pt: 0.5 }}
                    >
                      Fecha de publicación:{" "}
                      {iniciativa.fechaPublicacion
                        ? dayjs(iniciativa.fechaPublicacion).format(
                            "DD/MM/YYYY"
                          )
                        : "No disponible"}
                    </Typography>
                    {/* <Typography component={'span'} sx={{ ...DesignSystem.cuerpo3, pt: .5 }}>
                  { noticia.contenido }
                </Typography> */}
                  </Box>
                </Box>
              );
            })}
          {iniciativas.length === 0 && (
            <Alert severity="warning">
              No se encontraron datos de Iniciativas
            </Alert>
          )}
        </Box>
      )}
      {noticiaDialog && (
        <FormIniciativaComponent
          noticiaDialog={noticiaDialog}
          onClose={handleCloseDialogNoticia}
          onSubmitIniciativa={onSubmitIniciativa}
          loading={loading}
          stockImages={stockImages}
          isPractice={myState.state.isPractice}
        />
      )}
    </>
  );
};
