import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
// import { colorsBase } from "../../../themes/theme";
import iconNoticias from "../../../assets/icon/iconNoticias.svg";
import iconCursos from "../../../assets/icon/iconCursos.svg";
import iconReportes from "../../../assets/icon/iconReportes.svg";
import iconCreacionUsuarios from "../../../assets/icon/iconCreacionUsuarios.svg";
import iconDirectory from "../../../assets/admin/directorio-blanco.svg";
import iconRegionales from "../../../assets/icon/iconRegionales.svg";
import iconCommunication from "../../../assets/Communication/admin/promotion.png";
// import iconExperiencias from "../../../assets/admin/experiencias-blanco.svg";
// import iconNumbers from "../../../assets/admin/cifras-circulo.svg";
// import iconDirectory from "../../../assets/icon/iconDirectory.svg";
import iconNumbers from "../../../assets/admin/cifras-blanco.svg"; // bien
import { useHistory } from "react-router-dom";
import theme from "../../../themes";

export const DashboardMonitor = ({ classes, goToPage }: any) => {
  const navigate = useHistory();
  // const verReportes = () => {
  //   navigate.push("/reporte", { state: { userRol: "MONITOR" } });
  // };

  const modules: Array<{
    route: string;
    title: string;
    icon: string;
    color: string | undefined;
    configRoute?: any;
  }> = [
    {
      route: "/admin/usuario",
      title: "Usuarios",
      icon: iconCreacionUsuarios,
      color: theme.palette.neutral.active,
    },
    {
      route: "/admin/cursos",
      title: "Cursos",
      icon: iconCursos,
      color: theme.palette.info.main,
    },
    {
      route: "/admin/noticia",
      title: "Noticias y enlaces de interés",
      icon: iconNoticias,
      color: theme.palette.success.active,
    },
    {
      route: "/admin/reporte",
      title: "Reportes",
      icon: iconReportes,
      color: theme.palette.primary.main,
    },
    {
      route: "/admin/directorio",
      title: "Directorio municipal",
      icon: iconDirectory,
      color: "#C09CC3",
    },
    {
      route: "/admin/iniciativa",
      title: "Buenas prácticas",
      icon: iconRegionales,
      color: theme.palette.secondary.active,
      configRoute: {
        isPractice: true,
      },
    },
    {
      route: "/admin/experiencias-innovadoras",
      title: "Experiencias Innovadoras",
      icon: iconRegionales,
      color: theme.palette.error.main,
      configRoute: {
        isPractice: false,
      },
    },
    {
      route: "/admin/cifras",
      title: "Municipio en datos",
      icon: iconNumbers,
      color: "#0181B3",
    },
    {
      route: "/admin/comunicacion",
      title: "Comunicación",
      icon: iconCommunication,
      color: "#041c66",
    },
  ];

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      {modules.map((module, index) => (
        <Grid item xs={12} md={6} lg={4}>
          <Card
            sx={{
              "&:hover": {
                transform: "scale3d(1.05, 1.05, 1)",
                cursor: "pointer",
                backgroundColor: "background.paper",
              },
              transition: "0.1s",
              borderRadius: 3,
            }}
            elevation={0}
            onClick={(ev) => {
              if (module.configRoute) {
                ev.preventDefault();
                navigate.push(module.route, {
                  state: module.configRoute,
                });
              } else {
                goToPage(ev, module.route);
              }
            }}
          >
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  borderRadius: "50%",
                  border: "3px solid white",
                  backgroundColor: module.color,
                  p: 2,
                  maxHeight: "85px",
                  maxWidth: "85px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={module.icon}
                  alt="icon-noticias"
                  style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Typography variant="button" className={classes.cardTitle} my={2}>
                {module.title}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
