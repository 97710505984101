import { useAuthContext } from "../../../shared/auth/AuthContext";
import { Alerts } from "../../../shared/hooks/useAlert";
import useHttp from "../../../shared/hooks/useHttp";
import { printLog } from "../../../shared/utils";

export function useCreateCifras(): (payload: any) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async (payload: any) => {
    const arrayData = new FormData();

    if (payload?.files && payload?.files.length === 1) {
      arrayData.append("file", payload.files[0]);
    }

    arrayData.append(
      "body",
      JSON.stringify({
        year: payload?.year,
      })
    );

    try {
      const response = await http.post(`/admin/cifras/${payload?.year}`, {
        auth: authContext?.user?.token ?? undefined,
        payload: arrayData,
        withFiles: true,
      });

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (error) {
      Alerts.showError({
        description: (error as any).message || "Error al crear cifras",
      });
      printLog("Error al crear cifras >>> ", error);
    }
  };
}

export function useGetCifras(): () => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async () => {
    try {
      const response = await http.get("/admin/cifras", {
        auth: authContext?.user?.token ?? undefined,
      });

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (error) {
      Alerts.showError({
        description: (error as any).message || "Error al obtener cifras",
      });
      printLog("Error al obtener cifras >>> ", error);
    }
  };
}

export function useDeleteCifras(): (cifraId: string) => Promise<any> {
  const http = useHttp();
  const authContext = useAuthContext();

  return async (cifraId: string) => {
    try {
      const response = await http.delete(`/admin/cifras/${cifraId}`, {
        auth: authContext?.user?.token ?? undefined,
      });

      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (error) {
      Alerts.showError({
        description: (error as any).message || "Error al eliminar cifras",
      });
      printLog("Error al eliminar cifras >>> ", error);
    }
  };
}

export function useDownloadTemplate(): () => Promise<any> {
  const authContext = useAuthContext();

  return async () => {
    try {
      const url = "/api/admin/cifras/plantilla";
      const response = await fetch(url, {
        method: "GET",
        headers: new Headers({
          Authorization: authContext?.user?.token ?? "",
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
      });

      if (response) {
        if (!response.ok) {
          throw new Error("No se pudo descargar el archivo");
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "plantilla-cifras.xlsx";
        link.click();
        window.URL.revokeObjectURL(url);
      } else {
        return [];
      }
    } catch (error) {
      Alerts.showError({
        description: (error as any).message || "Error al descargar plantilla",
      });
      printLog("Error al descargar plantilla >>> ", error);
    }
  };
}
