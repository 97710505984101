import Carousel from "react-material-ui-carousel";
import theme from "../../../themes";
import { Box, Card, Grid, styled, useMediaQuery } from "@mui/material";
import { Settings } from "../../../interfaces/CarouselBannerProps";
import MobileSettings from "../../Home/components/HomeCarousel/MobileSettings";
import DefaultSettings from "../../Home/components/HomeCarousel/DefaultSettings";

type Props = {
  noticias: {
    orden: number;
    urlImagen: string;
    // id: number;
    uploadedImage: {
    //   id: string;
      originalFileName: string;
    };
  }[];
};

const StyledCardContainer = styled(Card)(() => ({
  height: "65vh",
  position: "relative",
  boxShadow: "none",
  borderRadius: 10,
  elevation: 3,
  [theme.breakpoints.down("md")]: {
    maxHeight: "fit-content",
    height: 275,
  },
}));

const StyledBannerGrid = styled(Grid)(() => ({
  height: "100%",
  position: "relative",
}));

const CarouselNoticia = ({ noticias }: Props) => {
  const matchMd: boolean = useMediaQuery(theme.breakpoints.down("md"));
  let settings: Settings = matchMd ? MobileSettings : DefaultSettings;

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      minHeight: "100%",
      minWidth: "100%",
      height: "100%",
      margin: "auto",
    }}>
      <Carousel
        {...settings}
        sx={{
          mt: 3,
          mb: 3,
          width: {
            xs: "100%",
            sm: "100%",
            md: "100%",
            lg: "90%",
            xl: "80%",
          },
          height: "100%",
        }}
      >
        {noticias
          .sort((a, b) => a.orden - b.orden)
          .map((item, index) => {
            return (
              <StyledCardContainer raised key={index}>
                <StyledBannerGrid container spacing={0}>
                  <Box
                    sx={{
                      height: "100%",
                      width: "100%",
                      background: "transparent",
                    }}
                  >
                    <img
                      src={item.urlImagen}
                      alt={"Vida digna sin violencia"}
                      style={{
                        width: "100%",
                        height: "100%",
                        background: "transparent",
                        objectFit: "fill",
                      }}
                    />
                  </Box>
                </StyledBannerGrid>
              </StyledCardContainer>
            );
          })}
      </Carousel>
    </Box>
  );
};

export default CarouselNoticia;
