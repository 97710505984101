import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import {
  ArrowForwardIosRounded,
  EmojiEvents,
  GetApp,
  KeyboardArrowDownRounded,
  Pending,
  QuizRounded,
  SchoolRounded,
} from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Layout from "../../../components/Layout";
import { useEffect, useState } from "react";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  useGetStudentCourse,
  useGetUnitStudentCourse,
} from "./data/StudentCourseClient";
import imgUnidad from "./../../../assets/course/image-unidad-curso.png";
import contenidoTextoIcon from "./../../../assets/course/cm_doc_normal.svg";
import contenidoVideoIcon from "./../../../assets/course/cm_vid_normal.svg";
import contenidoQuizIcon from "./../../../assets/course/cm_tarea_normal.svg";
import contenidoEntregableIcon from "./../../../assets/course/cm_adj_up_normal.svg";
import contenidoToolsIcon from "./../../../assets/course/cm_tools.svg";
import { printLog } from "../../../shared/utils";
import {
  CursoParticipante,
  UnidadCursoParticipante,
} from "./data/StudentCourseApiModels";
import { QuizViewerSection } from "../../../shared/components/curso/QuizViewerSection";
import { machometroQuestions } from "./data/testMachometro";
import { TipoPregunta } from "../interface";
import { CDialog } from "../../../shared/components/CDialog";
import { QuizFinishedEvent } from "../../../shared/components/curso/CursoViewerTypes";
import useHttp from "../../../shared/hooks/useHttp";
import { useMutation } from "@tanstack/react-query";
import Cloader from "../../../shared/components/CLoader";
import Breadcrumb from "../../../components/Breadcrumb";
import { Document, Page, pdfjs } from "react-pdf";
import CertificadoEjemplo from "./../../../assets/certificado_ejemplo.png";

// Estilos necesarios para el visor de pdf (react-pdf)
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const unitCardColors = [
  {
    borde: "207,121,0",
    background: "244,181,0",
    opacity: 0.3, //%
  },
  {
    borde: "0,124,60",
    background: "0,153,110",
    opacity: 0.3,
  },
  {
    borde: "5,125,174",
    background: "73,181,218",
    opacity: 0.3,
  },
  {
    borde: "105,28,119",
    background: "152,66,143",
    opacity: 0.3,
  },
];

function StudentCourseDetail() {
  const history = useHistory();
  const theme = useTheme();
  const location = useLocation();
  const { courseId } = location.state as { courseId: string };
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  printLog("participante._courseId >>> ", courseId);

  const [course, setCourse] = useState<CursoParticipante | null>(null);
  const [unidadSelected, setUnidadSelected] = useState<number | null>(null);
  const [unidadCurso, setUnidadCurso] = useState<any | null>(null);

  const [loading, setLoading] = useState(false);

  const [testEntradaCompletado, setTestEntradaCompletado] = useState(false);
  const [estadoMachometro, setEstadoMachometro] = useState<
    "NINGUNO" | "ENTRADA" | "SALIDA"
  >("NINGUNO");
  const [machometroResult, setMachometroResult] = useState<number | null>(null);

  const getStudentCourse = useGetStudentCourse();
  const getUnitCourse = useGetUnitStudentCourse();

  const {
    mutate: submitMachometroEntrada,
    isPending: isSubmitMachometroEntradaPending,
  } = useSubmitMachometroEntrada();
  const {
    mutate: submitMachometroSalida,
    isPending: isSubmitMachometroSalidaPending,
  } = useSubmitMachometroSalida();

  async function fetchCourse(courseId: string) {
    const fetchedCourseData = await getStudentCourse({
      cursoParticipanteId: courseId,
    });
    printLog("Cursos participante fetcheado :: ", fetchedCourseData);
    setCourse(fetchedCourseData);
    setTestEntradaCompletado(fetchedCourseData.testEntradaCompletado);
    // setLoading(false);
  }

  useEffect(() => {
    if (courseId) {
      fetchCourse(courseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCourse]);

  const handleOnChange = async (idxUnidad: number, unidad: any) => {
    printLog("unidadSelected: :", unidadSelected);
    if (unidadSelected === idxUnidad) {
      printLog("Cerrando accordion");
      setUnidadSelected(null);
      setUnidadCurso(null);
    }
    if (unidadSelected !== idxUnidad) {
      if (mostrarTestEntrada && !testEntradaCompletado) {
        // No abrir el accordion a menos que el test de entrada haya sido completado
        console.log("mostrarTestEntrada", mostrarTestEntrada);
        console.log("testEntradaCompletado", testEntradaCompletado);
        return;
      }

      printLog("abriendo accordion");
      setUnidadSelected(idxUnidad);
      printLog(
        "obtener los datos de los contenidos para la unidad ",
        idxUnidad
      );
      printLog("objeto unidad > ", unidad);
      if (unidad.estado !== "DESHABILITADA") {
        setLoading(true);
        printLog("[TODO] obtener contenido de unidad");
        await fetchUnidadCurso(unidad.numero);
        setLoading(false);
      }
    }
    printLog("unidadCurso :: ", unidadCurso);
  };

  const handleVerContenido = (
    unidadId: string,
    idxContenido: number,
    contenido: any
  ) => {
    printLog("ver contenido!!", idxContenido, contenido, unidadId, courseId);
    // navigate(`/curso/participante/${cursoParticipanteId}/unidad/${unidadId}`, { state: { numeroContenido: contenido.numero } });
    // history.push(`/curso/participante/${myState.courseId}/unidad/${unidadId}`, { numeroContenido: contenido.numero });
    history.push(`/cursos/participante/unidad/detalle`, {
      numeroContenido: contenido.numero,
      cursoParticipanteId: courseId,
      unidadId,
    });
  };

  async function fetchUnidadCurso(unidadId: string) {
    printLog("fetcheando unidadCurso :: ");
    const fetchedUnidadCurso = await getUnitCourse({
      cursoParticipanteId: course!.id,
      unidadId,
    });
    printLog("Unidad curso fetcheado :: ", fetchedUnidadCurso);
    setUnidadCurso(fetchedUnidadCurso);
  }

  const mostrarTestEntrada =
    course?.requiereTestEntrada && course?.unidades?.length > 0;

  async function completarMachometro(data: QuizFinishedEvent) {
    const payload = Object.values(data.respuestas).map((resp) =>
      Boolean(parseInt(resp))
    );

    if (estadoMachometro === "ENTRADA") {
      submitMachometroEntrada(
        { courseEnrolmentId: courseId, data: payload },
        {
          onSuccess: (data) => {
            console.log("Submitted Machometro", data);
            setTestEntradaCompletado(true);
            const dt = data as { puntaje: number };
            setMachometroResult(Math.round(dt.puntaje));
          },
          onError: (error) => console.log(error),
        }
      );
    } else if (estadoMachometro === "SALIDA") {
      submitMachometroSalida(
        {
          data: payload,
          courseEnrolmentId: course?.id!,
        },
        {
          onSuccess: (data) => {
            console.log("Submitted Machometro", data);
            const dt = data as { puntaje: number };
            setMachometroResult(Math.round(dt.puntaje));
            fetchCourse(courseId);
          },
          onError: (error) => console.log(error),
        }
      );
    }
  }

  const breadcrumbRoutes = [
    { name: "Cursos", path: "/catalogo-cursos" },
    { name: "Mis Cursos", path: "/cursos" },
    {
      name: course?.nombre ?? "",
      path: "/cursos/participante/detalle",
      param: { courseId: courseId },
    },
  ];

  if (!course) return <Cloader message={"Cargando curso"} />;

  return (
    <>
      <CDialog
        isOpen={estadoMachometro !== "NINGUNO"}
        maxWidth="md"
        scroll="paper"
        disableBackdropClick
        disableEscapeKeyDown
        handleClose={() => {
          setEstadoMachometro("NINGUNO");
        }}
      >
        <QuizViewerSection
          tituloQuiz="Machómetro"
          actionsPosition="static"
          idxContenido={undefined}
          tipo={"QUIZ"}
          preguntas={machometroQuestions.map((item, idx: number) => ({
            indice: idx,
            titulo: item,
            tipo: TipoPregunta.SELECCION_UNICA,
            opcion1: { label: "Estoy de acuerdo", texto: "1" },
            opcion2: { label: "No estoy de acuerdo", texto: "0" },
          }))}
          onFinalizarQuiz={completarMachometro}
          isEvaluated={false}
          onCancel={() => {
            setEstadoMachometro("NINGUNO");
          }}
          result={100 - (machometroResult ?? 100)}
          loading={
            isSubmitMachometroEntradaPending || isSubmitMachometroSalidaPending
          }
        />
      </CDialog>
      <Layout bgColor="custom">
        <Box
          mt={5}
          display="flex"
          gap={2}
          justifyContent="space-between"
          alignItems="center"
          alignContent="center"
          paddingBottom={{
            sm: 0,
            xs: 0,
            md: 2,
          }}
          ml={{
            sm: 2,
            xs: 2,
            md: 10,
          }}
        >
          <Breadcrumb routes={breadcrumbRoutes} />
        </Box>
        <Box my={3}></Box>

        {course?.certificado && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              m: {
                sm: 2,
                xs: 2,
                md: 0,
              },
            }}
          >
            <Card
              sx={{
                borderRadius: "8px",
              }}
            >
              <CardContent>
                <Grid container spacing={2}>
                  <Grid
                    item
                    sm={4}
                    xs={12}
                    md={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "180px",
                        height: "120px",
                        overflow: "hidden",
                        position: "relative",
                        borderRadius: "8px",
                        border: `1px solid ${theme.palette.secondary.main}`,
                        display: "flex",
                        backgroundColor: theme.palette.neutral.main,
                      }}
                    >
                      <img
                        src={CertificadoEjemplo}
                        width={"100%"}
                        alt="Certificado"
                        style={{
                          filter: "blur(0.7px)",
                        }}
                      />
                      {/* <Document
                        file={course.certificado}
                        onLoadError={(error) => {
                          printLog(
                            "Error al cargar la vista previa del pdf",
                            error
                          );
                        }}
                      >
                        <Page
                          height={isMobile ? 400 : 300}
                          width={isMobile ? 400 : 300}
                          scale={0.8}
                          pageNumber={1}
                        />
                      </Document> */}
                    </Box>
                  </Grid>
                  <Grid item sm={8} xs={12} md={8}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <EmojiEvents />
                      <Typography
                        component={"body"}
                        sx={{
                          fontWeight: 500,
                        }}
                      >
                        Felicidades ya puedes descargar tu certificado
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: 2,
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{
                          fontWeight: 250,
                          textAlign: "justify",
                        }}
                      >
                        {`Completaste el curso "${course.nombre}"`}
                        {course.fechaFinalizacion
                          ? ` en fecha ${course.fechaFinalizacion}`
                          : ""}
                      </Typography>
                      <Button
                        startIcon={<GetApp />}
                        variant={"contained"}
                        href={course.certificado}
                      >
                        Descargar certificado
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        )}

        <Box
          sx={{
            mx: 10,
            [theme.breakpoints.down("md")]: {
              mx: 1,
            },
          }}
        >
          {(mostrarTestEntrada || course.testEntradaCompletado) && (
            <Accordion
              elevation={0}
              sx={{
                borderRadius: "8px !important",
                border: `1px solid transparent`,
                backgroundColor: `rgba(${unitCardColors[3].background}, ${unitCardColors[3].opacity})`,
                my: 3,
                position: "initial",
              }}
              expanded={false}
              onClick={() =>
                !testEntradaCompletado && setEstadoMachometro("ENTRADA")
              }
              // onChange={() => handleOnChange(idxUnidad, unidad)}
            >
              <AccordionSummary expandIcon={<QuizRounded />}>
                <Box
                  flex="1"
                  display="flex"
                  sx={{
                    gap: 2,
                    [theme.breakpoints.down("md")]: { gap: 1 },
                  }}
                >
                  <Box
                    sx={{
                      width: "65px",
                      height: "65px", // [theme.breakpoints.down('md')]: {
                      //   width: '50px',
                      //   height: '50px'
                      // },
                    }}
                  >
                    <img
                      src={imgUnidad}
                      style={{
                        padding: 0,
                        width: "100%",
                        borderRadius: "10px",
                      }}
                      alt="imagen-curso"
                    />
                  </Box>
                  <Box flex="1" display="flex" flexDirection="column">
                    <Typography
                      component={"div"}
                      sx={{
                        color: theme.palette.text.primary, // textAlign: 'justify',
                        fontWeight: 800,
                        [theme.breakpoints.down("md")]: {
                          // ...DesignSystem.titulo3
                        },
                      }}
                    >
                      Test de Entrada
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "10pt",
                        fontWeight: 500,
                        color: theme.palette.grey[700],
                      }}
                    >
                      {testEntradaCompletado
                        ? `Test de Entrada Completado ${
                            testEntradaCompletado &&
                            (course.notaTestEntrada || machometroResult)
                              ? `- (${
                                  course.notaTestEntrada || machometroResult
                                }% Machista)`
                              : ""
                          }`
                        : "Bienvenido, antes de comenzar con el contenido de tus cursos, completa este test para medir tu nivel de machismo"}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails></AccordionDetails>
            </Accordion>
          )}

          {course.unidades.length > 0 &&
            course.unidades
              .sort((a: any, b: any) => a.numero - b.numero)
              .map((unidad: any, idxUnidad: number) => (
                <Accordion
                  key={idxUnidad}
                  elevation={0}
                  sx={{
                    borderRadius: "8px !important",
                    border: `1px solid ${
                      idxUnidad === unidadSelected
                        ? `rgba(${unitCardColors[idxUnidad % 4].borde}, 1)`
                        : `rgba(${unitCardColors[idxUnidad % 4].borde}, 0.2)`
                    }`,
                    backgroundColor: `rgba(${
                      unitCardColors[idxUnidad % 4].background
                    }, ${unitCardColors[idxUnidad % 4].opacity})`,
                    my: 3,
                    position: "initial", // boxShadow: "1.5px 2.6px 10px rgba(119, 119, 119, 0.1)",
                  }}
                  expanded={idxUnidad === unidadSelected}
                  onChange={() => handleOnChange(idxUnidad, unidad)}
                >
                  <AccordionSummary expandIcon={<KeyboardArrowDownRounded />}>
                    <Box
                      flex="1"
                      display="flex"
                      sx={{
                        gap: 2,
                        [theme.breakpoints.down("md")]: { gap: 1 },
                      }}
                    >
                      <Box
                        sx={{
                          width: "65px",
                          height: "65px", // [theme.breakpoints.down('md')]: {
                          //   width: '50px',
                          //   height: '50px'
                          // },
                        }}
                      >
                        <img
                          src={imgUnidad}
                          style={{
                            padding: 0,
                            width: "100%",
                            borderRadius: "10px",
                          }}
                          alt="imagen-curso"
                        />
                      </Box>
                      <Box
                        flex="1"
                        display="flex"
                        flexDirection={{
                          sm: "column",
                          xs: "column",
                          md: "row",
                        }}
                      >
                        <Typography
                          component={"div"}
                          sx={{
                            color: theme.palette.text.primary, // textAlign: 'justify',
                            fontWeight: 800,
                            [theme.breakpoints.down("md")]: {
                              // ...DesignSystem.titulo3
                            },
                          }}
                        >
                          {idxUnidad === 0 ? (
                            <>{unidad.titulo}</>
                          ) : (
                            <>
                              Unidad {idxUnidad}: {unidad.titulo}
                            </>
                          )}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            // marginLeft: "auto",
                          }}
                          mt={{
                            sm: "5px",
                            xs: "5px",
                            md: "0",
                          }}
                          marginLeft={{
                            sm: "0",
                            xs: "0",
                            md: "auto",
                          }}
                        >
                          {Math.floor(
                            (unidad.contenidosCompletados * 100) /
                              unidad.contenidosTotales
                          ) === 100 ? (
                            <Chip
                              size="small"
                              label={
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    alignContent: "center",
                                    gap: 1,
                                  }}
                                >
                                  <CheckCircleIcon
                                    sx={{
                                      color: theme.palette.success.contrastText,
                                    }}
                                    fontSize="small"
                                  />
                                  {unidad.contenidosTotales > 0 ? 100 : "0"}%
                                  completado
                                </Box>
                              }
                              color="success"
                              variant="filled"
                            />
                          ) : (
                            <Typography
                              sx={{
                                fontSize: "10pt",
                                fontWeight: 500,
                                padding: "0",
                                color: theme.palette.grey[800],
                                marginRight: 1,
                              }}
                            >
                              {unidad.contenidosTotales > 0
                                ? Math.floor(
                                    (unidad.contenidosCompletados * 100) /
                                      unidad.contenidosTotales
                                  )
                                : "0"}
                              % completado
                            </Typography>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      {unidad.estado === "DESHABILITADA" && (
                        <Alert
                          severity="warning"
                          sx={{
                            fontWeight: 700,
                            color: theme.palette.primary.main,
                          }}
                        >
                          Contenido aún no disponible
                        </Alert>
                      )}
                      {unidad.estado !== "DESHABILITADA" && (
                        <>
                          <Typography
                            component="p"
                            sx={{
                              textAlign: "left",
                              fontWeight: 500,
                              color: theme.palette.grey[800],
                            }}
                          >
                            CONTENIDO
                          </Typography>
                          {loading ? (
                            <LinearProgress />
                          ) : (
                            unidadCurso !== null && (
                              <Timeline
                                sx={{
                                  display: "flex",
                                  marginLeft: 0,
                                  paddingLeft: 0,
                                  [theme.breakpoints.down("md")]: {
                                    paddingRight: 0,
                                  },
                                }}
                              >
                                {unidadCurso.contenidos.length > 0 &&
                                  unidadCurso.contenidos.map(
                                    (
                                      contenido: UnidadCursoParticipante["contenidos"][number],
                                      idxContenido: number
                                    ) => (
                                      <TimelineItem
                                        key={idxContenido}
                                        sx={{
                                          // border: '1px solid red',
                                          paddingLeft: 1.5, // marginY: 1,
                                          "&:before": {
                                            content: "initial",
                                          },
                                        }}
                                      >
                                        <TimelineSeparator>
                                          <TimelineDot
                                            sx={{
                                              backgroundColor:
                                                contenido.estado ===
                                                "RESPONDIDO"
                                                  ? theme.palette.secondary.main
                                                  : theme.palette.neutral.main,
                                            }}
                                          >
                                            {contenido.estado ===
                                            "RESPONDIDO" ? (
                                              <CheckCircleIcon
                                                fontSize={"small"}
                                              />
                                            ) : (
                                              <Pending fontSize={"small"} />
                                            )}
                                          </TimelineDot>
                                          <TimelineConnector
                                            sx={{
                                              backgroundColor:
                                                contenido.estado ===
                                                "RESPONDIDO"
                                                  ? theme.palette.secondary.main
                                                  : theme.palette.neutral.main,
                                              py: 3,
                                            }}
                                          />
                                        </TimelineSeparator>
                                        <TimelineContent
                                          sx={{
                                            flexBasis: "auto", // paddingY: 0,
                                            paddingLeft: 2,
                                            paddingRight: 0,
                                            [theme.breakpoints.down("md")]: {
                                              // padding: '0 8px',
                                            },
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              border: `1px solid ${theme.palette.neutral.main}`,
                                              borderRadius: "8px",
                                              paddingY: 1,
                                              display: "flex",
                                              gap: 1,
                                              flexDirection: "row",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              cursor:
                                                contenido.estado !==
                                                "DESHABILITADO"
                                                  ? "pointer"
                                                  : "not-allowed",
                                              "&:hover": {
                                                backgroundColor:
                                                  contenido.estado !==
                                                  "DESHABILITADO"
                                                    ? `rgba(${
                                                        unitCardColors[
                                                          idxUnidad % 4
                                                        ].background
                                                      }, 0.5)`
                                                    : "transparent",
                                              },
                                            }}
                                            onClick={() => {
                                              if (
                                                contenido.estado ===
                                                "DESHABILITADO"
                                              )
                                                return;
                                              handleVerContenido(
                                                unidad.numero,
                                                idxContenido,
                                                contenido
                                              );
                                            }}
                                          >
                                            {contenido.tipo === "TEXTO" && (
                                              <img
                                                src={contenidoTextoIcon}
                                                style={{
                                                  paddingLeft: 12,
                                                  height: 23,
                                                }}
                                                alt="icono-ctexto"
                                              />
                                            )}
                                            {contenido.tipo === "VIDEO" && (
                                              <img
                                                src={contenidoVideoIcon}
                                                style={{
                                                  paddingLeft: 12,
                                                  height: 23,
                                                }}
                                                alt="icono-cvideo"
                                              />
                                            )}
                                            {contenido.tipo === "QUIZ" && (
                                              <img
                                                src={contenidoQuizIcon}
                                                style={{
                                                  paddingLeft: 12,
                                                  height: 23,
                                                }}
                                                alt="icono-cquiz"
                                              />
                                            )}
                                            {contenido.tipo ===
                                              "ENTREGABLE" && (
                                              <img
                                                src={contenidoEntregableIcon}
                                                style={{
                                                  paddingLeft: 12,
                                                  height: 23,
                                                }}
                                                alt="icono-centregable"
                                              />
                                            )}
                                            {contenido.tipo ===
                                              "HERRAMIENTAS" && (
                                              <img
                                                src={contenidoToolsIcon}
                                                style={{
                                                  paddingLeft: 12,
                                                  height: 23,
                                                }}
                                                alt="icono-centregable"
                                              />
                                            )}
                                            <Box
                                              sx={{
                                                marginRight: "auto",
                                                display: "flex",
                                                flexDirection: "column",
                                                justifyContent: "center",
                                                gap: 0,
                                                alignItems: "flex-start",
                                              }}
                                            >
                                              <Typography
                                                component="p"
                                                sx={{
                                                  flex: 1,
                                                  fontWeight: 500,
                                                  color:
                                                    theme.palette.grey[800],
                                                }}
                                              >
                                                {contenido.titulo}
                                              </Typography>

                                              <Typography
                                                component={"p"}
                                                sx={{
                                                  flex: 1,
                                                  color:
                                                    theme.palette.grey[700],
                                                }}
                                              >
                                                {contenido.tipo === "TEXTO"
                                                  ? contenido.titulo ===
                                                      "Te damos la bienvenida" ||
                                                    contenido.titulo ===
                                                      "¡Bienvenida/o!"
                                                    ? ""
                                                    : "Lectura"
                                                  : contenido.tipo === "VIDEO"
                                                  ? "Video"
                                                  : contenido.tipo === "QUIZ"
                                                  ? "Cuestionario "
                                                  : contenido.tipo ===
                                                    "HERRAMIENTAS"
                                                  ? ""
                                                  : "Entregable"}
                                                {contenido.tipo === "QUIZ" &&
                                                  contenido.notaQuiz && (
                                                    <Typography component="span">
                                                      - Calificación:{" "}
                                                      {contenido.notaQuiz}
                                                    </Typography>
                                                  )}
                                              </Typography>
                                            </Box>
                                            {contenido.estado !==
                                              "DESHABILITADO" && (
                                              <Box>
                                                <IconButton
                                                  size="small"
                                                  sx={{
                                                    background:
                                                      theme.palette.neutral
                                                        .main,
                                                    color: "white",
                                                    marginRight: 1,
                                                  }}
                                                  disabled={true}
                                                >
                                                  <ArrowForwardIosRounded
                                                    sx={{
                                                      fontSize: "12pt",
                                                    }}
                                                  />
                                                </IconButton>
                                              </Box>
                                            )}
                                          </Box>
                                        </TimelineContent>
                                      </TimelineItem>
                                    )
                                  )}
                              </Timeline>
                            )
                          )}
                        </>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}

          {
            <Accordion
              elevation={0}
              sx={{
                borderRadius: "8px !important",
                border: `1px solid transparent`,
                backgroundColor: `rgba(${unitCardColors[0].background}, ${unitCardColors[0].opacity})`,
                my: 3,
                position: "initial",
              }}
              expanded={false}
              onClick={() =>
                !course.testSalidaCompletado &&
                course?.unidadesCompletadas === course?.unidadesTotales &&
                (course?.unidadesCompletadas ?? 0) > 0 &&
                setEstadoMachometro("SALIDA")
              }
              // onChange={() => handleOnChange(idxUnidad, unidad)}
            >
              <AccordionSummary expandIcon={<SchoolRounded />}>
                <Box
                  flex="1"
                  display="flex"
                  sx={{
                    gap: 2,
                    [theme.breakpoints.down("md")]: { gap: 1 },
                  }}
                >
                  <Box
                    sx={{
                      width: "65px",
                      height: "65px", // [theme.breakpoints.down('md')]: {
                      //   width: '50px',
                      //   height: '50px'
                      // },
                    }}
                  >
                    <img
                      src={imgUnidad}
                      style={{
                        padding: 0,
                        width: "100%",
                        borderRadius: "10px",
                      }}
                      alt="imagen-curso"
                    />
                  </Box>
                  <Box flex="1" display="flex" flexDirection="column">
                    <Typography
                      component={"div"}
                      sx={{
                        color: theme.palette.text.primary, // textAlign: 'justify',
                        fontWeight: 800,
                        [theme.breakpoints.down("md")]: {
                          // ...DesignSystem.titulo3
                        },
                      }}
                    >
                      Test de Salida
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "10pt",
                        fontWeight: 500,
                        color: theme.palette.grey[700],
                      }}
                    >
                      {course.testSalidaCompletado
                        ? `Test de Salida Completado ${
                            course.notaTestSalida &&
                            `- (${course.notaTestSalida}% Machista)`
                          }`
                        : "Para completar el curso, toma nuestro test de salida"}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails></AccordionDetails>
            </Accordion>
          }
        </Box>

        <Box height={10} />
      </Layout>
    </>
  );
}

function useSubmitMachometroEntrada() {
  const http = useHttp();
  return useMutation({
    mutationFn: ({
      courseEnrolmentId,
      data,
    }: {
      courseEnrolmentId: string;
      data: boolean[];
    }) =>
      http.post(
        `/user/participante/machometro/initial?courseEnrolmentId=${courseEnrolmentId}`,
        { payload: data }
      ),
    gcTime: 1000, // 1 minute
  });
}

function useSubmitMachometroSalida() {
  const http = useHttp();
  return useMutation({
    mutationFn: ({
      courseEnrolmentId,
      data,
    }: {
      courseEnrolmentId: string;
      data: boolean[];
    }) =>
      http.post(
        `/user/participante/machometro/enrolment/${courseEnrolmentId}`,
        { payload: data }
      ),
  });
}

export default StudentCourseDetail;
