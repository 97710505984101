import useHttp from "../../../../shared/hooks/useHttp";
import {
  CompletarContenidoUnidadResponse,
  ContenidoCurso,
  CursoParticipante,
  UnidadCursoParticipante,
} from "./StudentCourseApiModels";
import { printLog } from "../../../../shared/utils";
import { Alerts } from "../../../../shared/hooks/useAlert";

export function useGetStudentCourses(): () => Promise<any> {
  const http = useHttp();
  return async () => {
    const cursos = await http.get(`/user/participante/inscripcion`, {});
    return cursos;
    // return _cursosFake;
  };
}

type GetCursoParticipanteParams = {
  cursoParticipanteId: string;
};

export function useGetStudentCourse(): (
  params: GetCursoParticipanteParams
) => Promise<CursoParticipante> {
  const http = useHttp();
  return async (params: GetCursoParticipanteParams) => {
    return (await http.get(
      `/user/participante/inscripcion/${params.cursoParticipanteId}`,
      {}
    )) as CursoParticipante;
  };
}

type getUnidadCursoParticipanteParams = {
  cursoParticipanteId: string;
  unidadId: string;
};

export function useGetUnitStudentCourse(): (
  params: getUnidadCursoParticipanteParams
) => Promise<UnidadCursoParticipante> {
  const http = useHttp();
  return async (params: getUnidadCursoParticipanteParams) => {
    const unidad = (await http.get(
      `/user/participante/inscripcion/${params.cursoParticipanteId}/unidad/${params.unidadId}`,
      {}
    )) as any;
    return unidad;
    // const _cursoFake = _cursosFake.find(
    //   (item) => item.id === params.cursoParticipanteId
    // );
    // const _unidad = _cursoFake!.unidades.find(
    //   (item) => item.id === params.unidadId
    // );
    // printLog("unidad obtenida >>>> ", _unidad);
    // return _unidad;
  };
}

type getContenidoUnidadParams = {
  cursoParticipanteId: string;
  unidadId: string;
  numero: number;
};

export function useGetUnitContent(): (
  params: getContenidoUnidadParams
) => Promise<ContenidoCurso> {
  const http = useHttp();
  return async (params: getContenidoUnidadParams) => {
    const contenido = (await http.get(
      `/user/participante/inscripcion/${params.cursoParticipanteId}/unidad/${params.unidadId}/contenido/${params.numero}`,
      {}
    ));
    return contenido as ContenidoCurso;
  };
}

type CompletarContenidoUnidadParams = {
  cursoParticipanteId: string,
  unidadId: string,
  numero: number
}

export function useCompletarContenidoUnidad(): (
  params: CompletarContenidoUnidadParams,
  mipayload: any,
  adjuntosRespuesta?: any
) => Promise<CompletarContenidoUnidadResponse> {
  const http = useHttp();
  return async (
    params: CompletarContenidoUnidadParams,
    mipayload: any,
    adjuntosRespuesta?: any
  ) => {
    // const algo: CompletarContenidoRequest = payload;
    const formData = new FormData();
    const arrayData: any[] = [];
    printLog('[hook] > mipayload :: ', mipayload);
    printLog('[hook] > JSON.stringify(mipayload) :: ', JSON.stringify(mipayload));
    if (mipayload.length > 0) {
      mipayload.forEach((item: string) => {
        printLog(item, ' > ', typeof item);
        printLog('json stringify item :: ', JSON.stringify(item));
        arrayData.push(
          item
        );
      })
    } else {
      arrayData.push({});
    }

    if (adjuntosRespuesta) {
      for (const item of Object.keys(adjuntosRespuesta)) {
        formData.append('adjuntos', adjuntosRespuesta[item], item)
      }
    }

    formData.append('data', JSON.stringify(arrayData));

    printLog('arrayDAta :: ', arrayData)

    const createResponse = await http.post(
      `/user/participante/inscripcion/${params.cursoParticipanteId}/unidad/${params.unidadId}/contenido/${params.numero}/completar`,
      {
        payload: formData,
        withFiles: true,
      },
    );
    printLog('createResponse :: ', createResponse);

    return createResponse as CompletarContenidoUnidadResponse;
  };
}

type StudentInscriptionParams = {
  plantillaCursoId: string,
}

export function useStudentInscription(): (
  params: StudentInscriptionParams
) => Promise<any> {
  const http = useHttp();
  return async (params: StudentInscriptionParams) => {
    try {
      const inscription = await http.post(`/user/participante/inscripcion`, {
        payload: {
          plantillaCursoId: params.plantillaCursoId,
        },
      });
      return inscription;
    } catch (error) {
      printLog("error :: ", error);
      Alerts.showError({
        title: "Error al inscribirse",
        description: `${
          (error as any).message || "Ocurrió un error desconocido"
        }`,
      });
    }
    // return _cursosFake;
  };
}
