import { Close } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import CInputForm from "../../../../shared/components/form/CInputForm";
import { colorsBase, DesignSystem } from "../../../../themes/theme";
import { CFilePicker } from "../../../../shared/components/form/CFilePicker";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  CancelButton,
  ConfirmButton,
} from "../../../../shared/components/CButton";
import { ReactComponent as RolIcon } from "../../../../assets/icons/rol.svg";
import { ReactComponent as CancelCircleIcon } from "../../../../assets/icons/cancelCircle.svg";
import { isValidUrl, printLog } from "../../../../shared/utils";
import { AdjuntoBanner, IBannerForm } from "../BannerInterface";
import { useCreateBanner, useGetBannerById, useUpdateBanner } from "../data";
import { Alerts } from "../../../../shared/hooks/useAlert";

export type BannerPayload = IBannerForm & {
  // archivos: File[];
  adjuntoEliminado: any[];
};

type FormBannerComponentProps = {
  bannerId: string | null;
  bannerDialog: boolean;
  onProcesarBanner: () => void;
  onClose: () => void;
  loading: boolean;
};

export const FormBannerComponent = ({
  bannerId,
  bannerDialog,
  onProcesarBanner,
  onClose,
  loading,
}: FormBannerComponentProps) => {
  const [adjuntos, setAdjuntos] = useState<File[]>([]);
  const [currentAdjunto, setCurrentAdjunto] = useState<AdjuntoBanner | null>();

  const [miniaturas, setMiniaturas] = useState<File[]>([]);
  const [currentMiniatura, setCurrentMiniatura] =
    useState<AdjuntoBanner | null>();

  const [loadingModal, setLoadingModal] = useState(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IBannerForm>();
  // const http = useHttp();
  // const authContext = useAuthContext();

  const getBanner = useGetBannerById();
  const updateBanner = useUpdateBanner();
  const createBanner = useCreateBanner();

  const handleChangePicker = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (target.files && target.files.length) {
      printLog(
        "[BannerForm] >  Archivos seleccionados de FilePicker :: target.files[0]",
        target.files![0]
      );

      // verificamos que los archivos solo sean de tipo PDF
      if (target.files[0].type !== "application/pdf") {
        Alerts.showError({
          title: "Error al cargar archivo",
          description: "El archivo debe ser de tipo PDF",
        });
        return;
      }

      setAdjuntos([...target.files]);
    }
  };

  const handleChangeMiniaturaPicker = ({
    target,
  }: ChangeEvent<HTMLInputElement>) => {
    if (target.files && target.files.length) {
      printLog(
        "[BannerForm] >  Archivos seleccionados de FilePicker :: target.files[0]",
        target.files![0]
      );

      const extension = target.files[0].name.split(".").pop();
      if (
        (extension && !["jpg", "jpeg", "png", "gif"].includes(extension)) ||
        !extension
      ) {
        Alerts.showError({
          title: "Error al cargar archivo",
          description: "El archivo debe ser de tipo imagen",
        });
        return;
      }

      setMiniaturas([...target.files]);
    }
  };

  const handleSubmitPublicar: SubmitHandler<IBannerForm> = async (data) => {
    let success = false;

    const newData = {
      ...data,
      adjunto: adjuntos,
      miniatura: miniaturas,
    };

    if (
      (!newData.miniatura || newData.miniatura.length === 0) &&
      !currentMiniatura
    ) {
      Alerts.showError({
        description: "La miniatura es requerida",
      });
      return;
    }

    if (
      (!newData.adjunto || newData.adjunto.length === 0) &&
      !currentAdjunto &&
      (newData.urlRecurso === "" || !newData.urlRecurso)
    ) {
      Alerts.showError({
        description: "El archivo adjunto es requerido",
      });
      return;
    }

    setLoadingModal(true);
    if (bannerId) {
      printLog("actualizar banner :: ", newData);
      const update = await updateBanner({ ...newData }, bannerId);
      if (update) {
        success = true;
      }
    } else {
      printLog("guardar banner :: ", newData);
      const create = await createBanner(newData);
      if (create) {
        success = true;
        Alerts.showSuccess({
          title: "Banner creado",
          description: "El banner se ha creado correctamente",
        });
      }
    }
    setLoadingModal(false);
    if (success) {
      onClose();
      onProcesarBanner();
    }
  };

  const handleCloseDialogBanner = () => {
    setAdjuntos([]);
    setMiniaturas([]);
    reset();
    onClose();
  };

  const fetchBanner = useCallback(async (bannerId: string) => {
    setLoadingModal(true);
    const fetchedBanner = await getBanner(bannerId);
    printLog("Banner fetched :: ", fetchedBanner);
    reset({
      titulo: fetchedBanner.titulo,
      esDestacado: fetchedBanner.esDestacado,
      urlRecurso: fetchedBanner.urlRecurso,
    });
    if (fetchedBanner.adjuntoFile) {
      setCurrentAdjunto(fetchedBanner.adjuntoFile);
    }
    if (fetchedBanner.miniaturaFile) {
      setCurrentMiniatura(fetchedBanner.miniaturaFile);
    }
    setAdjuntos([]);
    setMiniaturas([]);
    setLoadingModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bannerId) {
      fetchBanner(bannerId);
    }
  }, [fetchBanner, bannerId]);

  return (
    <Dialog
      open={bannerDialog}
      fullWidth
      maxWidth="md"
      onClose={handleCloseDialogBanner}
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ borderBottom: `1px solid ${colorsBase.BorderGray}` }}
        >
          <Typography component={"span"} sx={{ ...DesignSystem?.titulo1 }}>
            Banner destacado
          </Typography>
          <IconButton onClick={handleCloseDialogBanner}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box component={"form"} onSubmit={handleSubmit(handleSubmitPublicar)}>
        <DialogContent>
          <Grid container spacing={3} sx={{ px: 2 }}>
            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Título: *
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="titulo"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "El campo Titulo es requerido",
                    validate: async (value) => {
                      if (value.trim() === "")
                        return "El campo Titulo es inválido";
                    },
                  }}
                  render={({ field }) => (
                    <CInputForm
                      inputIcon={<RolIcon />}
                      type="text"
                      placeholder="Escribe el título del banner destacado"
                      {...field}
                      ref={null}
                      error={errors.titulo}
                      disabled={loadingModal || loading}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  URL del recurso:
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="urlRecurso"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value:
                        adjuntos?.length > 0 || currentAdjunto ? false : true,
                      message: "El campo URL Recurso es requerido",
                    },
                    validate: async (value) => {
                      if (adjuntos.length > 0 || currentAdjunto) return;
                      if (!isValidUrl(value)) return "No es una url válida";
                      if (value.trim() === "")
                        return "El campo URL Recurso es inválido";
                    },
                  }}
                  render={({ field }) => (
                    <Box>
                      <CInputForm
                        inputIcon={<RolIcon />}
                        type="text"
                        placeholder="Escribe la URL del recurso"
                        {...field}
                        ref={null}
                        error={errors.urlRecurso}
                        disabled={loadingModal || loading}
                      />
                    </Box>
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Es destacado:
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Controller
                  name="esDestacado"
                  defaultValue={false}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      ref={null}
                      disabled={loadingModal || loading}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Añadir adjunto:
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Box display="flex" flexDirection="column">
                  <CFilePicker
                    multiple={false}
                    placeholder="Carga el archivo adjunto"
                    handleChange={(ev: ChangeEvent<HTMLInputElement>) =>
                      handleChangePicker(ev)
                    }
                    disabled={loadingModal || loading}
                    accept="application/pdf"
                  />
                  <Box>
                    {adjuntos.map((adjunto: File, indiceAdjunto: number) => (
                      <Chip
                        deleteIcon={
                          <IconButton
                            size="small"
                            sx={{ stroke: colorsBase.Rojo }}
                          >
                            <CancelCircleIcon width="20" height="20" />
                          </IconButton>
                        }
                        disabled={loadingModal || loading}
                        onDelete={() => {
                          const _adj = [...adjuntos];
                          _adj.splice(indiceAdjunto, 1);
                          setAdjuntos(_adj);
                        }}
                        label={adjunto.name ? `${adjunto.name}` : ""}
                        key={indiceAdjunto}
                        sx={{
                          margin: 0.5,
                          border: `1px solid ${colorsBase.Verde3}`,
                        }}
                      />
                    ))}
                  </Box>
                  <Box>
                    {currentAdjunto && adjuntos.length === 0 && (
                      <Chip
                        deleteIcon={
                          <IconButton
                            size="small"
                            sx={{ stroke: colorsBase.Rojo }}
                          >
                            <CancelCircleIcon width="20" height="20" />
                          </IconButton>
                        }
                        disabled={loadingModal || loading}
                        onClick={() => {
                          window.open(currentAdjunto.url, "_blank");
                        }}
                        onDelete={() => {
                          setCurrentAdjunto(null);
                        }}
                        label={
                          currentAdjunto.nombre
                            ? `${currentAdjunto.nombre}`
                            : ""
                        }
                        sx={{
                          margin: 0.5,
                          border: `1px solid ${colorsBase.Verde3}`,
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Grid item container xs={12} spacing={1}>
              <Grid item xs={12} md={2}>
                <Typography
                  component={"span"}
                  sx={{ ...DesignSystem?.cuerpo2 }}
                >
                  Añadir miniatura: *
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Box display="flex" flexDirection="column">
                  <CFilePicker
                    multiple={false}
                    placeholder="Carga la miniatura del banner"
                    handleChange={(ev: ChangeEvent<HTMLInputElement>) =>
                      handleChangeMiniaturaPicker(ev)
                    }
                    disabled={loadingModal || loading}
                    accept="image/*"
                  />
                  <Box>
                    {miniaturas.map(
                      (miniatura: File, indiceMiniatura: number) => (
                        <Box
                          key={indiceMiniatura}
                          sx={{
                            position: "relative",
                            width: 120,
                            height: 120,
                            borderRadius: "8px",
                            overflow: "hidden",
                            boxShadow: 2,
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              borderRadius: "5px",
                            }}
                            src={URL.createObjectURL(miniatura)}
                            alt="miniatura"
                          />
                          <IconButton
                            onClick={() => {
                              const _adj = [...miniaturas];
                              _adj.splice(indiceMiniatura, 1);
                              setMiniaturas(_adj);
                            }}
                            disabled={loadingModal || loading}
                            size="small"
                            title="Eliminar miniatura"
                            sx={{
                              position: "absolute",
                              top: 4,
                              right: 4,
                              stroke: colorsBase.Rojo,
                              backgroundColor: "transparent",
                              "&:hover": {
                                backgroundColor: "rgba(255, 255, 255, 1)",
                              },
                            }}
                          >
                            <CancelCircleIcon width="20" height="20" />
                          </IconButton>
                        </Box>
                      )
                    )}
                  </Box>
                  <Box>
                    {currentMiniatura && miniaturas.length === 0 && (
                      <Box
                        sx={{
                          position: "relative",
                          width: 120,
                          height: 120,
                          borderRadius: "8px",
                          overflow: "hidden",
                          boxShadow: 2,
                        }}
                      >
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            borderRadius: "5px",
                          }}
                          src={currentMiniatura?.url}
                          alt="miniatura"
                        />
                        <IconButton
                          onClick={() => setCurrentMiniatura(null)}
                          size="small"
                          title="Eliminar miniatura"
                          disabled={loadingModal || loading}
                          sx={{
                            position: "absolute",
                            top: 4,
                            right: 4,
                            stroke: colorsBase.Rojo,
                            backgroundColor: "transparent",
                            "&:hover": {
                              backgroundColor: "rgba(255, 255, 255, 1)",
                            },
                          }}
                        >
                          <CancelCircleIcon width="20" height="20" />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        {loadingModal && <LinearProgress />}
        <DialogActions
          sx={{
            margin: 2,
            marginTop: 0,
            paddingTop: 1,
            borderTop: `1px solid ${colorsBase.BorderGray}`,
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <CancelButton
              onClick={handleCloseDialogBanner}
              loading={loading}
              disabled={loading || loadingModal}
            >
              Cancelar
            </CancelButton>
            <ConfirmButton
              type="submit"
              sx={{ ml: 2 }}
              loading={loading}
              disabled={loading || loadingModal}
            >
              Guardar
            </ConfirmButton>
          </Box>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
