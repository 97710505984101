import {
  Box,
  Button,
  Typography,
  alpha,
  styled,
  useTheme,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import bgTop from "./../../../assets/Communication/banner/main-bg-2.jpg";

import theme from "../../../themes";
import ButtonSocialMedia from "../../../components/socialMedia/ButtonsSocialMedia";

const StyledBox = styled(Box)(() => ({
  margin: 0,
  padding: 0,
  width: "100%",
  // height: 400,
  height: "70vh",
  backgroundImage: `url(${bgTop})`,
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "fit-content",
    height: 500,
  },
}));

const StyledDivMediaCaption = styled("div")(() => ({
  textOverflow: "ellipsis",
  position: "absolute",
  padding: "3em 1.5em 1.5em 1.5em",
  backgroundColor: "rgb(74,12,85,0.8)",
  width: "50%",
  height: "100%",
  transition: "300ms",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  backgroundColor: theme.palette.primary.main,
  borderRadius: "8px",
  color: theme.palette.secondary.active,
  "&:hover": {
    backgroundColor: theme.palette.primary.hover,
    color: theme.palette.common.white,
  },
  opacity: 1,
}));

const StyledWhiteButton = styled(Button)(({ theme }) => ({
  textTransform: "uppercase",
  fontFamily: "Barlow",
  fontSize: 14,
  borderRadius: "8px",
  backgroundColor: alpha(theme.palette.common.white, 0.85),
  color: theme.palette.secondary.active,
  "&:hover": {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.hover,
  },
  opacity: 1,
}));

const MainBanner = () => {
  const theme = useTheme();
  // const xs = useMediaQuery(theme.breakpoints.only("xs"));
  let history = useHistory();

  return (
    <StyledBox display="flex" justifyContent="center">
      <ButtonSocialMedia right={30} />

      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          position: "relative",
          margin: 0,
          padding: 0,
        }}
      >
        <StyledDivMediaCaption>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="end"
            sx={{ height: "100%", margin: 0, padding: 0 }}
          >
            <Box mb={2}>
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: {
                    xs: 15,
                    sm: 16,
                    md: 24,
                  },
                  fontWeight: 400,
                  color: theme.palette.primary.main,
                  lineHeight: 2,
                }}
              >
                LO QUE HACEMOS
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  fontFamily: "Barlow",
                  fontSize: {
                    xs: 30,
                    sm: 32,
                    md: 42,
                  },
                  fontWeight: 400,
                  color: theme.palette.common.white,
                  lineHeight: 1,
                }}
              >
                Comunicación
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: {
                    xs: 14,
                    sm: 14,
                    md: 16,
                  },
                  fontWeight: 300,
                  color: theme.palette.common.white,
                  mt: "1em",
                }}
              >
                Siendo la comunicación un pilar importante para contribuir a que
                las mujeres alcancen una vida digna sin violencia, en este
                espacio se visibilizará las acciones comunicacionales sostenidas
                y la difusión de buenas prácticas para alentar a la réplica.
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Ubuntu",
                  fontSize: {
                    xs: 14,
                    sm: 14,
                    md: 16,
                  },
                  fontWeight: 300,
                  color: theme.palette.common.white,
                  mt: "1em",
                }}
              >
                Para ello, se pondrá a disposición de todas y todos los
                interesados, productos multimedia difundidos a nivel nacional,
                regional y local.
              </Typography>
            </Box>

            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              mt={"1rem"}
              gap={1.5}
              alignItems={"start"}
            >
              <StyledButton
                variant="contained"
                onClick={() => history.push("/comunicacion#campania")}
              >
                CAMPAÑAS
              </StyledButton>
              <StyledWhiteButton
                variant="contained"
                onClick={() => history.push("/comunicacion#noticias")}
              >
                NOTICIAS
              </StyledWhiteButton>
            </Box>
          </Box>
        </StyledDivMediaCaption>
      </Box>
    </StyledBox>
  );
};

export default MainBanner;
