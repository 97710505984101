import {
  DeleteOutline,
  GetApp,
  KeyboardBackspaceRounded as KeyboardBackspaceRoundedIcon,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { DesignSystem } from "../../themes/theme";
import { useAuthContext } from "../../shared/auth/AuthContext";
import { SystemRoles } from "../../shared/common/constants";
import { CoursePrimaryButton } from "../Courses/shared/Elements";
import { CifraFormDialog } from "./components/CifraFormDialog";
import { Alerts } from "../../shared/hooks/useAlert";
import { useDeleteCifras, useGetCifras } from "./data";
import { yearsCifra } from "./constants";

type ListCifras = {
  id: string;
  year: string;
  urlFile: string;
};

export const AdminCifrasPage = () => {
  const theme = useTheme();
  const navigate = useHistory();
  const { user } = useAuthContext();
  const [cifraDialog, setCifraDialog] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [cifras, setCifras] = useState<ListCifras[]>([]);

  const abrirModalCifras = () => setCifraDialog(true);
  const cerrarModalCifras = () => setCifraDialog(false);

  const deleteCifras = useDeleteCifras();
  const getCifras = useGetCifras();

  async function fetchCifras() {
    setLoading(true);
    const fetchedCifras = await getCifras();
    setCifras(fetchedCifras as unknown as []);
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }

  const handleDelete = (id: string) => {
    setLoading(true);
    Alerts.showConfirm({
      title: "Eliminar datos",
      description: "¿Está seguro de eliminar estos datos?",
      onConfirm: async () => {
        const response = await deleteCifras(id);
        if (response) {
          Alerts.showSuccess({
            description: "Datos eliminados correctamente",
          });
          setTimeout(() => {
            fetchCifras();
          }, 1000);
        } else {
          Alerts.showError({
            description: "Error al eliminar datos",
          });
        }
      },
    });
  };

  useEffect(() => {
    fetchCifras();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {cifraDialog && (
        <CifraFormDialog
          open={cifraDialog}
          onClose={cerrarModalCifras}
          onSuccess={() => {
            setTimeout(() => {
              fetchCifras();
            }, 1000);
            cerrarModalCifras();
          }}
        />
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        mb={6}
      >
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            onClick={() =>
              navigate.push("/", {
                replace: true,
              })
            }
          >
            <KeyboardBackspaceRoundedIcon />
          </IconButton>
          <Typography sx={{ ...DesignSystem?.titulo1, pl: 2 }}>
            Municipio en datos
          </Typography>
        </Box>
        {user?.rol !== SystemRoles.PARTICIPANTE && (
          <Box>
            <CoursePrimaryButton disabled={loading} onClick={abrirModalCifras}>
              Agregar +
            </CoursePrimaryButton>
          </Box>
        )}
      </Box>

      <Box my={4} mx={4}>
        {loading && <LinearProgress />}
        {!loading &&
          cifras.length > 0 &&
          cifras.map((cifra, idxPlantilla: number) => (
            <Box
              key={idxPlantilla}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pt={4}
              pb={1}
              mx={3}
              borderBottom={1}
              borderColor={theme.palette.neutral.main}
            >
              <Typography component={"span"}>
                {yearsCifra.find((item) => cifra.year === item.value)?.label ||
                  "Año desconocido"}{" "}
              </Typography>
              <Box>
                <Tooltip title="Descargar datos">
                  <IconButton
                    size="small"
                    disabled={loading}
                    onClick={() => {
                      window.open(cifra.urlFile, "_blank");
                    }}
                  >
                    <GetApp />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar datos">
                  <IconButton
                    disabled={loading}
                    size="small"
                    onClick={() => handleDelete(cifra.id)}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          ))}
        {!cifras.length && !loading && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Typography variant="h6">No hay datos</Typography>
          </Box>
        )}
      </Box>
    </React.Fragment>
  );
};
