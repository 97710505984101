import { Box, Typography, useTheme } from "@mui/material";
import { useAuthContext } from "../../shared/auth/AuthContext";
import dayjs from "dayjs";
import esLocale from "dayjs/locale/es";
import { SystemRoles } from "../../shared/common/constants";
import { DashboardMonitor } from "./components/DashboardMonitor";
import { DesignSystem, colorsBase } from "../../themes/theme";
// import { DashboardRegional } from "./components/DashboardRegional";
import { useHistory } from "react-router-dom";
import { printLog } from "../../shared/utils";

const HomePage = () => {
  const theme = useTheme();
  const authContext = useAuthContext();
  const { user } = authContext;
  // const classes = useStyles();
  const navigate = useHistory();

  const goToPage = (event: any, ruta: string) => {
    navigate.push(ruta, {
      replace: true,
    });
  };
  printLog("user");
  printLog(user);

  const classes: any = {
    mainTitle: {
      ...theme.designSystem?.titulo1,
    },
    mainDescription: {
      ...DesignSystem?.cuerpo,
    },
    cardUsuarios: {
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, #FFF 40%) content-box, linear-gradient(to top, ${colorsBase.Rosado}, transparent 95%) border-box`,
      borderRadius: "10px",
      borderWidth: "3px",
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
      borderBottomStyle: "solid",
      borderTopStyle: "none",
      borderColor: "transparent",
      transition: "all .2s ease-in-out",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.05)",
      },
    },
    cardCursos: {
      background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, #fff 40%) content-box, linear-gradient(to top, ${colorsBase.Naranja1}, transparent 95%) border-box`,
      borderRadius: "10px",
      borderWidth: "3px",
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
      borderBottomStyle: "solid",
      borderTopStyle: "none",
      borderColor: "transparent",
      textAlign: "center",

      transition: "all .2s ease-in-out",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.05)",
      },
    },
    cardRegionales: {
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, #FFF 40%) content-box, linear-gradient(to top, ${colorsBase.Rojo}, transparent 95%) border-box`,
      borderRadius: "10px",
      borderWidth: "3px",
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
      borderBottomStyle: "solid",
      borderTopStyle: "none",
      borderColor: "transparent",
      textAlign: "center",
      transition: "all .2s ease-in-out",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.05)",
      },
    },
    cardNoticias: {
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, #FFF 40%) content-box, linear-gradient(to top, ${colorsBase.Azul1}, transparent 95%) border-box`,
      borderRadius: "10px",
      borderWidth: "3px",
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
      borderBottomStyle: "solid",
      borderTopStyle: "none",
      borderColor: "transparent",
      textAlign: "center",
      transition: "all .2s ease-in-out",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.05)",
      },
    },
    cardReportes: {
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, #FFF 40%) content-box, linear-gradient(to top, ${colorsBase.VerdeClaro}, transparent 95%) border-box`,
      borderRadius: "10px",
      borderWidth: "3px",
      borderLeftStyle: "solid",
      borderRightStyle: "solid",
      borderBottomStyle: "solid",
      borderTopStyle: "none",
      borderColor: "transparent",
      textAlign: "center",
      transition: "all .2s ease-in-out",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "&:hover": {
        cursor: "pointer",
        transform: "scale(1.05)",
      },
    },
    btnIngresar: {
      borderRadius: "40px",
      paddingLeft: 50,
      paddingRight: 50,
      elevation: "1",
      textTransform: "capitalize",
      ...DesignSystem?.titulo3,
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.common.white,
      },
    },
    cardTitle: {
      ...DesignSystem?.titulo2,
    },
    cardText: {
      ...DesignSystem?.cuerpo3,
      textAlign: "center",
    },
  };

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        {/* <Typography className={classes.mainTitle}> */}
        <Typography variant="h4" fontWeight={"medium"}>
          {user
            ? `Bienvenido ${(user.nombreCompleto || user.displayName) ?? ""}`
            : ""}
        </Typography>
        {/* <Typography className={classes.mainDescription}> */}
        <Typography>
          Hoy es {dayjs(new Date()).locale(esLocale).format("dddd")},{" "}
          {dayjs(new Date()).locale(esLocale).format("DD MMMM YYYY")}
        </Typography>
        {/* {theme.breakpoints.down('md')} */}
      </Box>
      <Box
        sx={{
          mx: {
            xs: 1,
            sm: 3,
            md: 5,
          },
          mt: {
            xs: 2,
            sm: 5,
            md: 5,
          },
        }}
      >
        {(user?.rol === SystemRoles.MONITOR || !user!.rol) && (
          <DashboardMonitor classes={classes} goToPage={goToPage} />
        )}
      </Box>
    </>
  );
};

// const useStyles = makeStyles((theme: Theme) => ({
//   mainTitle: {
//     ...theme.designSystem.titulo1
//   },
//   mainDescription: {
//     ...DesignSystem.cuerpo
//   },
//   cardUsuarios: {
//     background: `linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, #FFF 40%) content-box, linear-gradient(to top, ${colorsBase.Rosado}, transparent 95%) border-box`,
//     borderRadius: "10px",
//     borderWidth: "3px",
//     borderLeftStyle: "solid",
//     borderRightStyle: "solid",
//     borderBottomStyle: "solid",
//     borderTopStyle: "none",
//     borderColor: "transparent",
//     transition: "all .2s ease-in-out",
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     textAlign: "center",
//     '&:hover': {
//       cursor: 'pointer',
//       transform: "scale(1.05)",
//     }
//   },
//   cardCursos: {
//     background: `linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, #fff 40%) content-box, linear-gradient(to top, ${colorsBase.Naranja1}, transparent 95%) border-box`,
//     borderRadius: "10px",
//     borderWidth: "3px",
//     borderLeftStyle: "solid",
//     borderRightStyle: "solid",
//     borderBottomStyle: "solid",
//     borderTopStyle: "none",
//     borderColor: "transparent",
//     textAlign: "center",

//     transition: "all .2s ease-in-out",
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     '&:hover': {
//       cursor: 'pointer',
//       transform: "scale(1.05)",
//     }
//   },
//   cardRegionales: {
//     background: `linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, #FFF 40%) content-box, linear-gradient(to top, ${colorsBase.Rojo}, transparent 95%) border-box`,
//     borderRadius: "10px",
//     borderWidth: "3px",
//     borderLeftStyle: "solid",
//     borderRightStyle: "solid",
//     borderBottomStyle: "solid",
//     borderTopStyle: "none",
//     borderColor: "transparent",
//     textAlign: "center",
//     transition: "all .2s ease-in-out",
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     '&:hover': {
//       cursor: 'pointer',
//       transform: "scale(1.05)",
//     }
//   },
//   cardNoticias: {
//     background: `linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, #FFF 40%) content-box, linear-gradient(to top, ${colorsBase.Azul1}, transparent 95%) border-box`,
//     borderRadius: "10px",
//     borderWidth: "3px",
//     borderLeftStyle: "solid",
//     borderRightStyle: "solid",
//     borderBottomStyle: "solid",
//     borderTopStyle: "none",
//     borderColor: "transparent",
//     textAlign: "center",
//     transition: "all .2s ease-in-out",
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     '&:hover': {
//       cursor: 'pointer',
//       transform: "scale(1.05)",
//     }
//   },
//   cardReportes: {
//     background: `linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, #FFF 40%) content-box, linear-gradient(to top, ${colorsBase.VerdeClaro}, transparent 95%) border-box`,
//     borderRadius: "10px",
//     borderWidth: "3px",
//     borderLeftStyle: "solid",
//     borderRightStyle: "solid",
//     borderBottomStyle: "solid",
//     borderTopStyle: "none",
//     borderColor: "transparent",
//     textAlign: "center",
//     transition: "all .2s ease-in-out",
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     '&:hover': {
//       cursor: 'pointer',
//       transform: "scale(1.05)",
//     }
//   },
//   btnIngresar: {
//     borderRadius: '40px',
//     paddingLeft: 50,
//     paddingRight: 50,
//     elevation: '1',
//     textTransform: 'capitalize',
//     ...DesignSystem.titulo3,
//     backgroundColor: theme.palette.common.white,
//     '&:hover': {
//       backgroundColor: theme.palette.common.white,
//     },
//   },
//   cardTitle: {
//     ...DesignSystem.titulo2
//   },
//   cardText: {
//     ...DesignSystem.cuerpo3, textAlign: 'center'
//   }
// }));

export default HomePage;
