import { Box, Button, Grid, Typography } from "@mui/material";
import produce from "immer";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { lazy, Suspense, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  InstanciaUnidadCursoContenido,
  Pregunta,
  PreguntaEntregable,
} from "../interface";
// import { showAlertError } from "../../../shared/hooks/useAlert";
import CInputForm from "../../../shared/components/form/CInputForm";
import { PreguntaEntregableModel, PreguntaQuizModel } from "../types";

/// Editores
import { VideoContentEditor } from "./editors/VideoContentEditor";
import QuizContentEditor from "./editors/QuizContentEditor";
import DeliverableContentEditor from "./editors/DeliverableContentEditor";
import { CourseContentDialog } from "../shared/Elements";

/// Iconos
import bgLecturaIcon from "./../../../assets/course/tipo-contenido/lectura_n_normal.png";
import bgLecturaSelectedIcon from "./../../../assets/course/tipo-contenido/lectura_n_hover.png";
import bgVideoIcon from "./../../../assets/course/tipo-contenido/video_n_normal.png";
import bgVideoSelectedIcon from "./../../../assets/course/tipo-contenido/video_n_hover.png";
import bgQuizIcon from "./../../../assets/course/tipo-contenido/quiz_n_normal.png";
import bgQuizSelectedIcon from "./../../../assets/course/tipo-contenido/quiz_n_hover.png";
import bgEntregableIcon from "./../../../assets/course/tipo-contenido/entregable_n_normal.png";
import bgToolsIcon from "./../../../assets/course/tipo-contenido/tools.png";
import bgToolsIconSelected from "./../../../assets/course/tipo-contenido/tools-selected.png";
import bgEntregableSelectedIcon from "./../../../assets/course/tipo-contenido/entregable_n_hover.png";
import { Alerts } from "../../../shared/hooks/useAlert";
import { isJsonString, printLog } from "../../../shared/utils";
import useHttp from "../../../shared/hooks/useHttp";
import draftToHtml from "draftjs-to-html";
// import { useAlertModalContext } from "../../../shared/context/modalAlertContext";

type DialogContenidoFormProps = {
  open: boolean;
  onClose: () => void;
  contenido?: InstanciaUnidadCursoContenido;
  adjuntosContenido: AdjuntosContenido;
  onSaveContenido: (
    contenido: InstanciaUnidadCursoContenido,
    adjuntos: AdjuntosContenido
  ) => void;
};

export const DialogContenidoForm = ({
  open,
  onClose,
  onSaveContenido,
  contenido: contenidoInicial,
  adjuntosContenido,
}: DialogContenidoFormProps) => {
  const http = useHttp();

  // Editor de texto CKEDITOR
  const ComponentCkEditor = useMemo(
    () => lazy(() => import("../../../components/ckeditor/EditorTexto")),
    []
  );

  const [imagesDeleteEditor, setImagesDeleteEditor] = useState<string[]>([]);

  const [contenidoInterno, setContenidoInterno] = useState<ContenidoInterno>(
    () =>
      contenidoInicial !== undefined
        ? getContenidoInterno(contenidoInicial, adjuntosContenido)
        : {
            tipo: "",
            titulo: "",
          }
  );

  /// Context de alertas
  // const alertContext = useAlertModalContext();

  const { titulo, tipo } = contenidoInterno;
  const setTitulo = (titulo: string) =>
    setContenidoInterno({
      ...contenidoInterno,
      titulo,
    });

  const [adjuntos, setAdjuntos] =
    useState<Record<string, File>>(adjuntosContenido);

  const setContenidoTexto = (editorState: string) => {
    setContenidoInterno({ tipo: "TEXTO", editorHtml: editorState, titulo });
  };

  const setContenidoTools = (editorState: string) => {
    setContenidoInterno({
      tipo: "HERRAMIENTAS",
      editorHtml: editorState,
      titulo,
    });
  };

  const setContenidoVideo = (link: string) => {
    setContenidoInterno({ tipo: "VIDEO", link, titulo });
  };

  const setContenidoQuiz = (
    isEvaluated: boolean,
    preguntas: PreguntaQuizModel[]
  ) => {
    setContenidoInterno({ tipo: "QUIZ", isEvaluated, preguntas, titulo });
  };

  const setContenidoEntregable = (preguntas: PreguntaEntregableModel[]) => {
    setContenidoInterno({ tipo: "ENTREGABLE", preguntas, titulo });
  };

  // const handleChangeTipo = (event: SelectChangeEvent) => {
  //   const tipo = event.target.value;
  //   if (!isTipoContenido(tipo)) throw new Error("Tipo de Contenido Invalido");

  //   setContenidoInterno({ tipo, titulo });
  // };
  const handleChangeTipo = (_tipo: any) => {
    printLog("seleccionar a tipo >>> ", _tipo);
    // const tipo = event.target.value;
    // if (!isTipoContenido(_tipo)) throw new Error("Tipo de Contenido Invalido");

    setContenidoInterno({ tipo: _tipo, titulo });
  };

  const setAdjunto = (tempId: string, file: File) => {
    setAdjuntos(
      produce(adjuntos, (draft) => {
        draft[tempId] = file;
      })
    );
  };

  const deleteAdjunto = (tempId: string) => {
    setAdjuntos(
      produce(adjuntos, (draft) => {
        delete draft[tempId];
      })
    );
  };

  const handleSave = async () => {
    printLog("handleCreateContenido");
    printLog("Contenido interno ::", contenidoInterno);
    const contenidoTransformado = getInstanciaCursoContenido(contenidoInterno);
    printLog("contenido transformado :: ", contenidoTransformado);
    if ("error" in contenidoTransformado) {
      Alerts.showError({
        title: "No se puede generar el contenido",
        description: contenidoTransformado.error,
      });
      return;
    }

    const { contenido } = contenidoTransformado;
    printLog("adjuntos del contenido ::: ", adjuntos);
    onSaveContenido(contenido, adjuntos);

    if (imagesDeleteEditor.length > 0) {
      imagesDeleteEditor.forEach((url) => {
        if (url) deleteImageFromServer(url);
      }); // Eliminar del servidor
    }

    // Actualizar las imágenes actuales
    setImagesDeleteEditor([]);

    onClose();
  };

  const deleteImageFromServer = async (url: string) => {
    // verificamos que no sea una imagen de base64
    if (url.startsWith("data:image")) return;
    const idImage = url.split("/").pop();
    try {
      await http.delete(`/file/${idImage}`, {}).then((response) => {
        printLog("Imagen eliminada:", response);
      });
    } catch (error) {
      printLog("Error al eliminar la imagen del editor:", error);
    }
  };

  return (
    <>
      <CourseContentDialog
        open={open}
        scroll="paper"
        onClose={onClose}
        maxWidth="lg"
        ctitle="Selecciona un tipo de contenido"
        actiontext={contenidoInicial !== undefined ? "Guardar" : "Crear"}
        handleConfirm={handleSave}
        disableEnforceFocus
      >
        <Grid container spacing={1} my={5}>
          {[
            {
              label: "LECTURA",
              tipo: "TEXTO",
              bgIcon: bgLecturaIcon,
              bgSelectedIcon: bgLecturaSelectedIcon,
            },
            {
              label: "VIDEO",
              tipo: "VIDEO",
              bgIcon: bgVideoIcon,
              bgSelectedIcon: bgVideoSelectedIcon,
            },
            {
              label: "QUIZ",
              tipo: "QUIZ",
              bgIcon: bgQuizIcon,
              bgSelectedIcon: bgQuizSelectedIcon,
            },
            {
              label: "ENTREGABLE",
              tipo: "ENTREGABLE",
              bgIcon: bgEntregableIcon,
              bgSelectedIcon: bgEntregableSelectedIcon,
            },
          ].map(({ label, tipo: buttonTipo, bgIcon, bgSelectedIcon }) => (
            <Grid
              item
              xs={6}
              sm={6}
              md={2.5}
              key={buttonTipo}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component={Button}
                sx={{
                  height: "8rem",
                  width: "130px",
                  backgroundImage: `url(${
                    tipo === buttonTipo ? bgSelectedIcon : bgIcon
                  })`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  borderRadius: "10px",
                  color: tipo === buttonTipo ? "white" : "black",
                }}
                display="flex"
                alignItems="end"
                justifyContent="center"
                onClick={() => handleChangeTipo(buttonTipo)}
              >
                {label}
              </Box>
            </Grid>
          ))}
          {/* ITEM DE HERRAMIENTAS */}
          <Grid
            item
            xs={6}
            sm={6}
            md={2}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                ...(tipo === "HERRAMIENTAS"
                  ? {
                      backgroundImage: `linear-gradient(to bottom, #00BAE6, #008DC9);`,
                    }
                  : { background: "#ECECEC" }),
                padding: "10px",
                height: "8rem",
                width: "130px",
                borderRadius: "10px",
                color: tipo === "HERRAMIENTAS" ? "white" : "black",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
              onClick={() => handleChangeTipo("HERRAMIENTAS")}
            >
              <Box
                component={Button}
                sx={{
                  backgroundImage: `url(${
                    tipo === "HERRAMIENTAS" ? bgToolsIconSelected : bgToolsIcon
                  })`,
                  height: "8rem",
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                }}
              />
              <Typography variant="body2" textAlign="center" fontWeight={500}>
                {"HERRAMIENTAS"}
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          spacing={3}
          alignItems="center"
          mb={3}
        >
          <Grid item container xs={12}>
            <CInputForm
              type="text"
              placeholder="Escribe el título"
              name="titulo"
              value={titulo}
              onChange={(event) => setTitulo(event.target.value)}
            />
          </Grid>
        </Grid>
        {contenidoInterno.tipo === "TEXTO" && (
          <Suspense
            fallback={
              <div
                style={{
                  minHeight: "120px",
                  backgroundColor: "#f3f3f3",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    color: "#999",
                  }}
                >
                  Cargando...
                </p>
              </div>
            }
          >
            <ComponentCkEditor
              id="editor-cursos-form"
              onChange={setContenidoTexto}
              value={contenidoInterno.editorHtml}
              ocultarBordes={false}
              setImagesDeleteEditor={setImagesDeleteEditor}
            />
          </Suspense>
        )}

        {contenidoInterno.tipo === "VIDEO" && (
          <VideoContentEditor
            link={contenidoInterno.link}
            onLinkChange={setContenidoVideo}
          />
        )}
        {contenidoInterno.tipo === "QUIZ" && (
          <QuizContentEditor
            preguntas={contenidoInterno.preguntas}
            onPreguntasChange={(preguntas) =>
              setContenidoQuiz(contenidoInterno.isEvaluated, preguntas)
            }
            onAdjuntoChange={setAdjunto}
            onAdjuntoDelete={deleteAdjunto}
            onEvaluatedChange={(isEvaluated) => {
              setContenidoQuiz(isEvaluated, contenidoInterno.preguntas ?? []);
            }}
            isEvaluated={contenidoInterno.isEvaluated ?? false}
          />
        )}

        {contenidoInterno.tipo === "ENTREGABLE" && (
          <DeliverableContentEditor
            preguntas={contenidoInterno.preguntas}
            onPreguntasChange={setContenidoEntregable}
            onAdjuntoChange={setAdjunto}
            onAdjuntoDelete={deleteAdjunto}
          ></DeliverableContentEditor>
        )}

        {contenidoInterno.tipo === "HERRAMIENTAS" && (
          <Suspense
            fallback={
              <div
                style={{
                  minHeight: "120px",
                  backgroundColor: "#f3f3f3",
                  borderRadius: "4px",
                }}
              >
                <p
                  style={{
                    textAlign: "center",
                    padding: "20px",
                    color: "#999",
                  }}
                >
                  Cargando...
                </p>
              </div>
            }
          >
            <ComponentCkEditor
              id="editor-cursos-form-herramientas"
              onChange={setContenidoTools}
              value={contenidoInterno.editorHtml}
              ocultarBordes={false}
              setImagesDeleteEditor={setImagesDeleteEditor}
            />
          </Suspense>
        )}
      </CourseContentDialog>
    </>
  );
};

type TransformarContenidoResult =
  | {
      error: string;
    }
  | {
      contenido: InstanciaUnidadCursoContenido;
    };

const validaciones = {
  CONTENIDO_REQUERIDO:
    "El tipo de contenido es requerido, selecciona un tipo de contenido",
  TITULO_REQUERIDO: "El Titulo es requerido",
  TEXTO_REQUERIDO: "El Texto del contenido es requerido",
  LINK_REQUERIDO: "El link es requerido",
  PREGUNTAS_REQUERIDAS: "Debe incluir al menos una pregunta",
  RESPUESTAS_CONTENIDO_REQUERIDA:
    "El contenido de las respuestas a las preguntas del quiz es requerido",
} as const;

function getContenidoInterno(
  contenido: InstanciaUnidadCursoContenido,
  adjuntosContenido: AdjuntosContenido
): ContenidoInterno {
  printLog("DialogContenidoForm::getContenidoInterno - Contenido: ", contenido);
  switch (contenido.tipo) {
    case "TEXTO": {
      let editorHtml = contenido.texto ?? "";

      if (contenido.texto && isJsonString(contenido.texto)) {
        try {
          const parseado = JSON.parse(contenido.texto);
          const editorContent = EditorState.createWithContent(
            convertFromRaw(parseado)
          );
          const rawContentState = convertToRaw(
            editorContent.getCurrentContent()
          );
          let markup = draftToHtml(rawContentState);
          markup = markup.replace(/(width\s*:\s*auto)/gi, "width: 100%");
          editorHtml = markup;
        } catch (error) {
          console.error("Error al procesar el texto JSON:", error);
        }
      }

      return {
        tipo: "TEXTO",
        titulo: contenido.titulo,
        editorHtml,
      };
    }
    case "VIDEO":
      return contenido;
    case "QUIZ":
      return {
        tipo: "QUIZ",
        titulo: contenido.titulo,
        isEvaluated: contenido.isEvaluated,
        preguntas: contenido.preguntas.map<PreguntaQuizModel>(
          ({ adjuntos, urlAdjuntos, ...pregunta }) => {
            const id = adjuntos.length === 0 ? uuidv4() : adjuntos[0].tempId;

            return {
              id,
              categoria: "QUIZ",
              adjunto:
                adjuntos.length > 0
                  ? { tipo: "NUEVO", archivo: adjuntosContenido[id] }
                  : urlAdjuntos.length > 0
                  ? { tipo: "Existente", url: urlAdjuntos[0] }
                  : undefined,
              ...pregunta,
            };
          }
        ),
      };
    case "ENTREGABLE":
      return {
        tipo: "ENTREGABLE",
        titulo: contenido.titulo,
        preguntas: contenido.preguntas.map(
          ({ adjuntos, urlAdjuntos, ...pregunta }) => {
            const id = adjuntos.length === 0 ? uuidv4() : adjuntos[0].tempId;

            return {
              id,
              categoria: "ENTREGABLE",
              adjunto:
                adjuntos.length > 0
                  ? { tipo: "NUEVO", archivo: adjuntosContenido[id] }
                  : urlAdjuntos.length > 0
                  ? { tipo: "Existente", url: urlAdjuntos[0] }
                  : undefined,
              ...pregunta,
            };
          }
        ),
      };
    case "HERRAMIENTAS": {
      let editorHtml = contenido.texto ?? "";

      if (contenido.texto && isJsonString(contenido.texto)) {
        try {
          const parseado = JSON.parse(contenido.texto);
          const editorContent = EditorState.createWithContent(
            convertFromRaw(parseado)
          );
          const rawContentState = convertToRaw(
            editorContent.getCurrentContent()
          );
          let markup = draftToHtml(rawContentState);
          markup = markup.replace(/(width\s*:\s*auto)/gi, "width: 100%");
          editorHtml = markup;
        } catch (error) {
          console.error("Error al procesar el texto JSON:", error);
        }
      }

      return {
        tipo: "HERRAMIENTAS",
        titulo: contenido.titulo,
        editorHtml,
      };
    }
    case "":
      return contenido;
  }
}

function getInstanciaCursoContenido(
  interno: ContenidoInterno
): TransformarContenidoResult {
  const ok = (contenido: InstanciaUnidadCursoContenido) => ({
    contenido,
  });
  const fail = (error: string) => ({ error });

  const { tipo, titulo } = interno;

  if (!tipo || tipo.trim() === "")
    return fail(validaciones.CONTENIDO_REQUERIDO);

  if (!titulo || titulo.trim() === "")
    return fail(validaciones.TITULO_REQUERIDO);

  // Admitir contenido vacío
  // if (tipo === "") return ok({ tipo: "", titulo: interno.titulo });

  if (tipo === "TEXTO") {
    // Todo: Validar que el texto no sea vacío
    // if (interno.editorHtml === undefined || interno.editorHtml === '')
    //   return fail(validaciones.TEXTO_REQUERIDO);
    if (interno.editorHtml === undefined)
      return fail(validaciones.TEXTO_REQUERIDO);

    return ok({
      tipo,
      titulo,
      texto: interno.editorHtml,
      // texto: JSON.stringify(
      //   convertToRaw(interno.editorState.getCurrentContent())
      // ),
    });
  }

  if (tipo === "VIDEO") {
    // Todo: Validar que sea un link valido
    if (interno.link === undefined) return fail(validaciones.LINK_REQUERIDO);

    return ok({
      tipo,
      titulo,
      link: interno.link,
    });
  }

  if (tipo === "QUIZ") {
    printLog("Validando contenido de tipo QUIZ");
    const { preguntas, isEvaluated } = interno;
    if (!preguntas) return fail(validaciones.PREGUNTAS_REQUERIDAS);

    try {
      const contenido = {
        tipo,
        titulo,
        isEvaluated,
        preguntas: preguntas.map(
          ({
            tipo,
            titulo,
            descripcion,
            adjunto,
            categoria,
            id,
            adjuntoIds,
            ...rest
          }: any) => {
            // TODO: quitar any de opciones de preguntas
            if (tipo === "") throw new Error("Tipo de pregunta invalido");
            if (titulo.trim() === "")
              throw new Error("El título de la pregunta es requerido");
            // const { opciones } = rest
            const opciones =
              "opciones" in rest && rest.opciones ? rest.opciones : [];
            printLog("opciones de la pregunta > ", opciones);
            // if (opciones.some((o: any) => o.texto.trim() === '' && o.esCorrecta)) {
            if (
              opciones.length &&
              opciones.some((o: any) => o.texto.trim() === "")
            ) {
              throw new Error(validaciones.RESPUESTAS_CONTENIDO_REQUERIDA);
            }

            if (adjunto === undefined || adjunto.tipo === "NUEVO") {
              // Se eliminó el adjunto. Se resetea el adjuntoIds para que el backend sepa que debe eliminarlo de la bd
              adjuntoIds = [];
            }

            if (tipo === "SELECCION_MULTIPLE") {
              if (!opciones.some((opcion: any) => opcion.esCorrecta)) {
                throw new Error(
                  "Se debe marcar al menos una opcion como correcta"
                );
              }
            }

            if (tipo === "SELECCION_UNICA") {
              if (!opciones.some((opcion: any) => opcion.esCorrecta)) {
                throw new Error(
                  "Se debe marcar al menos una opción como correcta"
                );
              }
            }

            const adjuntos = {
              adjuntos: [],
              urlAdjuntos: [],
              ...(adjunto === undefined
                ? {}
                : adjunto.tipo === "NUEVO"
                ? {
                    adjuntos: [
                      { tempId: id, nombreArchivo: adjunto.archivo.name },
                    ],
                  }
                : { urlAdjuntos: [adjunto.url] }),
            };

            return {
              tipo,
              titulo,
              descripcion,
              adjuntoIds,
              ...adjuntos,
              ...rest,
            } as Pregunta;
          }
        ),
      };

      return ok(contenido);
    } catch (error: any) {
      // TODO: pendiente tipo de error
      printLog("Agarrando le error <> ", typeof error, error);
      return fail(
        (error.message as unknown as string) ?? "Contenido de quiz invalido"
      );
    }
  }

  if (tipo === "ENTREGABLE") {
    const { preguntas } = interno;
    if (!preguntas) return fail(validaciones.PREGUNTAS_REQUERIDAS);

    try {
      const contenido = {
        tipo,
        titulo,
        preguntas: preguntas.map(
          ({
            tipo,
            titulo,
            descripcion,
            adjunto,
            categoria,
            id,
            adjuntoIds,
            ...rest
          }: any) => {
            if (tipo === "") throw new Error("Tipo de pregunta invalido");

            if (adjunto === undefined || adjunto.tipo === "NUEVO") {
              // Se eliminó el adjunto. Se resetea el adjuntoIds para que el backend sepa que debe eliminarlo de la bd
              adjuntoIds = [];
            }

            if (titulo.trim() === "") {
              throw new Error("El título de la pregunta es requerido");
            }

            const adjuntos = {
              adjuntos: [],
              urlAdjuntos: [],
              ...(adjunto === undefined
                ? {}
                : adjunto.tipo === "NUEVO"
                ? {
                    adjuntos: [
                      { tempId: id, nombreArchivo: adjunto.archivo.name },
                    ],
                  }
                : { urlAdjuntos: [adjunto.url] }),
            };

            return {
              tipo,
              titulo,
              descripcion,
              adjuntoIds,
              ...adjuntos,
              ...rest,
            } as PreguntaEntregable;
          }
        ),
      };

      return ok(contenido);
    } catch (error: any) {
      // TODO: pendiente tipo de error
      printLog("Agarrando le error <> ", typeof error, error);
      return fail(
        (error.message as unknown as string) ??
          "Contenido de entregable invalido"
      );
    }
  }

  if (tipo === "HERRAMIENTAS") {
    if (interno.editorHtml === undefined || interno.editorHtml === '')
      return fail(validaciones.TEXTO_REQUERIDO);
    if (interno.editorHtml === undefined)
      return fail(validaciones.TEXTO_REQUERIDO);

    return ok({
      tipo,
      titulo,
      texto: interno.editorHtml,
    });
  }

  return fail("Tipo de contenido invalido");
}

// Tipos internos
type ContenidoSinTipo = {
  tipo: "";
  titulo: string;
};

type ContenidoInternoTexto = {
  tipo: "TEXTO";
  titulo: string;
  editorHtml?: string;
  // editorState?: EditorState;
};

type ContenidoInternoVideo = {
  tipo: "VIDEO";
  titulo: string;
  link?: string;
};

type ContenidoInternoQuiz = {
  tipo: "QUIZ";
  titulo: string;
  isEvaluated: boolean;
  preguntas?: PreguntaQuizModel[];
};

type ContenidoInternoEntregable = {
  tipo: "ENTREGABLE";
  titulo: string;
  preguntas?: PreguntaEntregableModel[];
};

type ContenidoInternoTools = {
  tipo: "HERRAMIENTAS";
  titulo: string;
  editorHtml?: string;
};

export type ContenidoInterno =
  | ContenidoInternoTexto
  | ContenidoInternoVideo
  | ContenidoInternoQuiz
  | ContenidoInternoEntregable
  | ContenidoSinTipo
  | ContenidoInternoTools;

export type AdjuntosContenido = Record<string, File>;
