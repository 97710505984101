import { Box, Typography, styled } from "@mui/material";
import { MainCampaignCard } from "./MainCampaignCard";

const StyledBox = styled(Box)(() => ({
  margin: "0 0 0 0",
  padding: 0,
  width: "100%",
  backgroundColor: "#fcf8de",
}));

export const CampaignSection = () => {
  return (
    <StyledBox display="flex" justifyContent="center" id="campania">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          margin: 0,
          padding: 0,
          pt: 5,
          pb: 10,
        }}
      >
        <Typography
          variant="h4"
          textAlign={"center"}
          fontWeight={"bold"}
          color={"rgb(74,12,85,0.8)"}
        >
          CAMPAÑAS POR UNA VIDA SIN VIOLENCIA
        </Typography>
        <Box my={2} />
        <Box>
          <Typography variant="body1" textAlign={"center"}>
            ¡Una vida digna es una vida sin violencia!
          </Typography>
          <Box my={2} />
          <Typography variant="body1" textAlign={"center"}>
            Voces de mujeres, hombres y jóvenes que están impulsando cambios en
            sus municipios, son las que encontrarás en los materiales que
            ponemos a tu disposición en este segmento.
          </Typography>
        </Box>
        <Box my={2} />
        <MainCampaignCard />
        {/* <Box my={10} /> */}
      </Box>
    </StyledBox>
  );
};
