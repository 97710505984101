import produce from "immer";
import React, { useContext, useReducer } from "react";
// import { AdjuntosContenido } from "../components/DialogContenidoForm";
import {
  CursoModel,
  InstanciaUnidadCursoContenido,
  Unidad,
} from "../interface";

type CourseReducer = React.Reducer<InternCourse, CursoAction>;
export type AdjuntosContenido = Record<string, File>;
export type InternUnit = {
  titulo: string;
  objetivo: string;
  contenido: Array<{
    instancia: InstanciaUnidadCursoContenido;
    adjuntos: AdjuntosContenido;
  }>;
};

const courseReducer: CourseReducer = (prevState, action) => {
  switch (action.type) {
    case "LOAD_CURSO":
      // Convertir Los instanciaContenidos en contenidosInternos
      // Cada contenidosInterno tiene un array vacio de adjuntos
      // Este array se va a ir llenando conforme se agreguen nuevos adjuntos.
      // Los adjuntos ya existentes se manejan dentro de cada pregunta
      return {
        ...action.curso,
        unidades: action.curso.unidades.map((unidad) => ({
          ...unidad,
          contenido: unidad.contenido.map((contenido) => ({
            instancia: contenido,
            adjuntos: {},
          })),
        })),
      };
    case "NUEVO_CURSO":
      return emptyCourse();
    case "EDIT_VALORES_CURSO":
      return {
        ...prevState,
        ...action.payload,
      };
    case "SET_IMAGEN_CURSO": {
      return produce(prevState, (draft) => {
        draft.urlImagen = null;
        draft.imagenCurso = action.file;
      });
    }
    case "DELETE_IMAGEN_CURSO":
      return produce(prevState, (draft) => {
        draft.urlImagen = null;
        draft.imagenCurso = undefined;
      });
    case "ADD_UNIDAD":
      return produce(prevState, (draft) => {
        draft.unidades.push({
          titulo: action.payload.titulo ?? "",
          objetivo: action.payload.objetivo ?? "",
          contenido: [],
        });
      });
    case "EDIT_VALORES_UNIDAD":
      return produce(prevState, (draft) => {
        Object.assign(draft.unidades[action.idxUnidad], action.payload);
      });
    case "DELETE_UNIDAD":
      return produce(prevState, (draft) => {
        draft.unidades.splice(action.idxUnidad, 1);
      });
    case "MOVE_UNIDAD":
      return produce(prevState, (draft) => {
        const unidad = draft.unidades[action.idxUnidad];
        draft.unidades.splice(action.idxUnidad, 1);
        draft.unidades.splice(action.idxUnidad + action.delta, 0, unidad);
      });
    case "SET_CONTENIDO":
      return produce(prevState, (draft) => {
        const contenidos = draft.unidades[action.idxUnidad].contenido;
        const idxContenido = action.idxContenido ?? contenidos.length;
        contenidos[idxContenido] = {
          instancia: action.contenido,
          adjuntos: action.adjuntos,
        };
      });
    case "DELETE_CONTENIDO":
      return produce(prevState, (draft) => {
        const contenidos = draft.unidades[action.idxUnidad].contenido;
        contenidos.splice(action.idxContenido, 1);
      });
    case "MOVE_CONTENIDO":
      return produce(prevState, (draft) => {
        const contenidos = draft.unidades[action.idxUnidad].contenido;
        const contenido = contenidos[action.idxContenido];
        contenidos.splice(action.idxContenido, 1);
        contenidos.splice(action.idxContenido + action.delta, 0, contenido);
      });
  }
};

export type InternCourse = Omit<CursoModel, "unidades"> & {
  unidades: InternUnit[];
};

type CursoAction =
  | {
      type: "LOAD_CURSO";
      curso: CursoModel;
    }
  | { type: "NUEVO_CURSO" }
  | { type: "DELETE_IMAGEN_CURSO" }
  | { type: "SET_IMAGEN_CURSO"; file: File }
  | {
      type: "EDIT_VALORES_CURSO";

      payload: Partial<Pick<CursoModel, "nombre" | "descripcion">>;
    }
  | {
      type: "ADD_UNIDAD";
      payload: Partial<Pick<Unidad, "titulo" | "objetivo">>;
    }
  | {
      type: "EDIT_VALORES_UNIDAD";
      idxUnidad: number;
      payload: Partial<Pick<Unidad, "titulo" | "objetivo">>;
    }
  | {
      type: "DELETE_UNIDAD";
      idxUnidad: number;
    }
  | {
      type: "MOVE_UNIDAD";
      idxUnidad: number;
      delta: number;
    }
  | {
      type: "SET_CONTENIDO";
      idxUnidad: number;
      idxContenido?: number;
      contenido: InstanciaUnidadCursoContenido;
      adjuntos: AdjuntosContenido;
    }
  | {
      type: "DELETE_CONTENIDO";
      idxUnidad: number;
      idxContenido: number;
    }
  | {
      type: "MOVE_CONTENIDO";
      idxUnidad: number;
      idxContenido: number;
      delta: number;
    };

type CourseContextValue = [InternCourse, (action: CursoAction) => void];
const CourseContext = React.createContext<CourseContextValue | null>(null);

const CourseProvider = ({ children }: { children: React.ReactNode }) => {
  const [course, dispatch] = useReducer(courseReducer, emptyCourse());
  return (
    <CourseContext.Provider value={[course, dispatch]}>
      {children}
    </CourseContext.Provider>
  );
};

export function withCourseContextProvider(
  WrappedComponent: React.ComponentType
) {
  return () => (
    <CourseProvider>
      <WrappedComponent></WrappedComponent>
    </CourseProvider>
  );
}

export { CourseProvider };

export function useCourseContext() {
  const context = useContext(CourseContext);
  if (context === null) throw new Error("No provider for CourseContext");
  return context;
}

function emptyCourse(): InternCourse {
  return {
    nombre: "",
    descripcion: "",
    unidades: [],
    urlImagen: null,
  };
}

export function extractAttachments(unidades: InternUnit[]): AdjuntosContenido {
  return unidades.reduce((adjuntosUnidad, unidad) => {
    const adjuntos: AdjuntosContenido = unidad.contenido.reduce(
      (adjuntosContenido, contenido) => ({
        ...adjuntosContenido,
        ...contenido.adjuntos,
      }),
      {}
    );
    return {
      ...adjuntosUnidad,
      ...adjuntos,
    };
  }, {});
}

export function extractUnits(unidades: InternUnit[]): Unidad[] {
  return unidades.map((unidad) => {
    const contenido = unidad.contenido.map((contenido) => contenido.instancia);
    return {
      ...unidad,
      contenido,
    };
  });
}
