import {
  alpha,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  SelectChangeEvent,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CSelectForm from "../../../shared/components/form/CSelectForm";
import useHttp from "../../../shared/hooks/useHttp";
import { useEffect, useRef, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { CardDirectory } from "./CardDirectory";
// import {_ordenDirectorios, directoriosF} from "../data/directorios";
import { useQuery } from "@tanstack/react-query";

const StyledBox = styled(Box)(() => ({
  margin: "0 0 6em 0",
  padding: 0,
  width: "100%",
}));

export const Directory = () => {
  const theme = useTheme();
  const http = useHttp();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);

  /// Valores de formulario
  const [departamento, setDepartamento] = useState("");
  const [municipio, setMunicipio] = useState("");

  const gridRefDirectorios = useRef<HTMLDivElement>(null);

  //const [directoriosAgrupado, setDirectoriosAgrupado] = useState(null);

  const [municipioSeleccionado, setMunicipioSeleccionado] = useState("");

  const { data: directoriosAgrupado } = useQuery({
    queryKey: ["/ext/municipalDirectory", municipioSeleccionado],
    queryFn: () =>
      http.get(
        `/ext/municipalDirectory?municipio_cod=${municipioSeleccionado}`,
        {}
      ) as any,
  });

  const obtenerDepartamentosMunicipios = async () => {
    try {
      const response: any = await http.get(
        "/municipio/porDepartamento?hasDirectory=true",
        {}
      );
      if (response) {
        setDepartamentos(response.departamentos);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    obtenerDepartamentosMunicipios().finally();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledBox display="flex" justifyContent="center">
      <Box
        sx={{
          width: { xs: "100%", md: "75%" },
          height: "100%",
          margin: { xs: "0 1.5em 0 1.5em", md: 0 },
          padding: "3em 0em",
          // border: "1px solid red",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card
              elevation={0}
              sx={{
                borderRadius: "10px",
                background: alpha("#cacaca", 0.7),
                position: "sticky",
                top: "80px",
              }}
            >
              <CardContent>
                <Box display={"flex"} flexDirection={"column"} gap={2}>
                  <Typography>Realiza una búsqueda por municipio</Typography>
                  <Box>
                    <CSelectForm
                      placeholder="Selecciona un departamento"
                      fullWidth
                      value={departamento}
                      items={[
                        ...departamentos.map((item: any) => ({
                          value: item.departamento_cod,
                          label: item.nombre,
                        })),
                      ]}
                      onChange={(event: SelectChangeEvent) => {
                        setDepartamento(event.target.value);
                        if (event.target.value === "") {
                          setMunicipios([]);
                        } else {
                          const _depto: any = departamentos.find(
                            (item: any) =>
                              item.departamento_cod === event.target.value
                          );
                          setMunicipios(_depto.municipios);
                        }
                      }}
                    />
                  </Box>
                  <Box>
                    <CSelectForm
                      placeholder="Selecciona un municipio"
                      fullWidth
                      value={municipio}
                      items={municipios.map((item: any) => ({
                        value: item.municipio_cod,
                        label: item.municipio_desc,
                      }))}
                      onChange={(event: SelectChangeEvent) => {
                        setMunicipio(event.target.value);
                      }}
                    />
                  </Box>
                  <Box
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                    gap={2}
                    mt={3}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      size={isMobile ? "small" : "medium"}
                      sx={{
                        flex: 1,
                        color: theme.palette.common.white,
                        borderRadius: "10px",
                      }}
                      disabled={!municipio}
                      onClick={() => {
                        setMunicipioSeleccionado(municipio);
                        gridRefDirectorios.current?.scrollIntoView({
                          behavior: "smooth",
                          block: "start",
                          inline: "start",
                        });
                      }}
                    >
                      <SearchIcon color="inherit" />
                      Buscar
                    </Button>
                    <Button
                      variant="outlined"
                      size={isMobile ? "small" : "medium"}
                      sx={{ borderRadius: "10px" }}
                      disabled={!municipio && !departamento}
                      onClick={() => {
                        console.log("limpiar formulario >>>");
                        setDepartamento("");
                        setMunicipio("");
                        setMunicipioSeleccionado("");
                      }}
                    >
                      Limpiar
                    </Button>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Box ref={gridRefDirectorios} />
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              minHeight: "500px",
            }}
          >
            {/* {directoriosAgrupado && Object.entries(directoriosAgrupado).map()} */}
            {directoriosAgrupado &&
              Object.entries(directoriosAgrupado).map(
                ([municipio, objetos]) => (
                  <Card
                    elevation={0}
                    key={municipio}
                    sx={{
                      borderRadius: "10px",
                      background: theme.palette.grey[100],
                      my: 2,
                    }}
                  >
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      sx={{ color: theme.palette.grey[600] }}
                      px={3}
                      pt={3}
                      pb={0.5}
                    >
                      <Typography variant="body2">Municipio</Typography>
                      <Typography variant="h6" fontWeight={"normal"}>
                        {municipio}
                      </Typography>
                    </Box>
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={12} xl={6}>
                          {(objetos as unknown as Array<any>)
                            .slice(
                              0,
                              Math.floor(
                                (objetos as unknown as Array<any>).length / 2
                              )
                            )
                            .map((objeto: any, idxObjeto: number) => (
                              <CardDirectory
                                key={`direccionFake-${idxObjeto}`}
                                titulo={objeto.titulo}
                                tipo={objeto.tipo}
                                direcciones={objeto.direccion}
                                contacto={objeto.telefono}
                                emergencia={objeto.celular}
                                atencion={objeto.atencion}
                                diasAtencion={undefined}
                              />
                            ))}
                        </Grid>
                        <Grid item xs={12} md={12} xl={6}>
                          {(objetos as unknown as Array<any>)
                            .slice(
                              Math.floor(
                                (objetos as unknown as Array<any>).length / 2
                              )
                            )
                            .map((objeto: any, idxObjeto: number) => (
                              <CardDirectory
                                key={`direccionFake-${idxObjeto}`}
                                titulo={objeto.titulo}
                                tipo={objeto.tipo}
                                direcciones={objeto.direccion}
                                contacto={objeto.telefono}
                                emergencia={objeto.celular}
                                atencion={objeto.atencion}
                                diasAtencion={undefined}
                              />
                            ))}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                )
              )}
            {/* {departamentosF.map((depto, idxDepto) => (
              <Card
                elevation={0}
                key={idxDepto}
                sx={{
                  borderRadius: "10px",
                  background: theme.palette.grey[100],
                  my: 2,
                }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  sx={{ color: theme.palette.grey[600] }}
                  px={3}
                  pt={3}
                  pb={0.5}
                >
                  <Typography variant="body2">Departamento</Typography>
                  <Typography variant="h6" fontWeight={"normal"}>
                    {depto}
                  </Typography>
                </Box>
                <Divider />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} xl={6}>
                      {(directorios as any)[depto].direcciones
                        .slice(
                          0,
                          Math.floor(
                            (directorios as any)[depto].direcciones.length / 2
                          )
                        )
                        .map((direccion: any, idxDireccion: number) => (
                          <CardDirectory
                            key={`direccionFake-${idxDireccion}`}
                            titulo={"SLIM"}
                            direcciones={[direccion.direccionSlim]}
                            contacto={direccion.celularSlim}
                            emergencia={direccion.emergencia}
                            atencion={direccion.atencion}
                            diasAtencion={undefined}
                          />
                        ))}
                    </Grid>
                    <Grid item xs={12} md={12} xl={6}>
                      {(directorios as any)[depto].direcciones
                        .slice(
                          Math.floor(
                            (directorios as any)[depto].direcciones.length / 2
                          )
                        )
                        .map((direccion: any, idxDireccion: number) => (
                          <CardDirectory
                            key={`direccionFake-${idxDireccion}`}
                            titulo={"SLIM"}
                            direcciones={[direccion.direccionSlim]}
                            contacto={direccion.celularSlim}
                            emergencia={direccion.emergencia}
                            atencion={direccion.atencion}
                            diasAtencion={undefined}
                          />
                        ))}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            ))} */}
          </Grid>
        </Grid>
      </Box>
    </StyledBox>
  );
};
