import useHttp from "../../../shared/hooks/useHttp";
import { printLog } from "../../../shared/utils";

export function useGetCategories(): () => Promise<any> {
  const http = useHttp();

  return async () => {
    try {
      const response = await http.get("/municipio/cifras/categorias", {});
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (error) {
      printLog("Error al obtener las categorías >>> ", error);
    }
  };
}

export function useGetAllIndicadores(): () => Promise<any> {
  const http = useHttp();
  return async () => {
    try {
      const response = await http.get("/municipio/cifras/indicadores", {});
      if (response) {
        return response;
      } else {
        return [];
      }
    } catch (error) {
      printLog("Error al obtener los indicadores >>> ", error);
    }
  };
}

export type FiltersDatosType = {
  nivel: string;
  departamento?: string;
  municipio?: string;
  gestion: string;
  categoria: string;
  indicador: string;
};
export function useGetDatos(): (filters: FiltersDatosType) => Promise<any> {
  const http = useHttp();

  return async (filters: FiltersDatosType) => {
    try {
      const params = new URLSearchParams();

      if (filters.nivel) params.append("nivel", filters.nivel);
      if (filters.departamento)
        params.append("departamento", filters.departamento);
      if (filters.municipio) params.append("municipio", filters.municipio);
      if (filters.gestion) params.append("year", filters.gestion);
      if (filters.categoria) params.append("categoria", filters.categoria);
      if (filters.indicador) params.append("indicador", filters.indicador);

      const response = await http.get(
        `/municipio/cifras/datos?${params.toString()}`,
        {}
      );

      if (response) {
        return response;
      }

      return null;
    } catch (e) {
      printLog("Error al obtener los datos municipio gráficos >>> ", e);
      return [];
    }
  };
}


export const _cifras = [
  /// Datos municipios
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "8",
    nivel: "MUNICIPAL",
    codMunicipio: "1807",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "1",
    nivel: "MUNICIPAL",
    codMunicipio: "1113",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "1",
    nivel: "MUNICIPAL",
    codMunicipio: "1124",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "1",
    nivel: "MUNICIPAL",
    codMunicipio: "1114",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "1",
    nivel: "MUNICIPAL",
    codMunicipio: "1128",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "1",
    nivel: "MUNICIPAL",
    codMunicipio: "1110",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "1",
    nivel: "MUNICIPAL",
    codMunicipio: "1116",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "1",
    nivel: "MUNICIPAL",
    codMunicipio: "1111",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "1",
    nivel: "MUNICIPAL",
    codMunicipio: "1129",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "1",
    nivel: "MUNICIPAL",
    codMunicipio: "1126",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "1",
    nivel: "MUNICIPAL",
    codMunicipio: "1122",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "3",
    nivel: "MUNICIPAL",
    codMunicipio: "1334",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "3",
    nivel: "MUNICIPAL",
    codMunicipio: "1309",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "3",
    nivel: "MUNICIPAL",
    codMunicipio: "1312",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "3",
    nivel: "MUNICIPAL",
    codMunicipio: "1317",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "3",
    nivel: "MUNICIPAL",
    codMunicipio: "1347",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "3",
    nivel: "MUNICIPAL",
    codMunicipio: "1304",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "4",
    nivel: "MUNICIPAL",
    codMunicipio: "1420",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "4",
    nivel: "MUNICIPAL",
    codMunicipio: "1407",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "4",
    nivel: "MUNICIPAL",
    codMunicipio: "1409",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "4",
    nivel: "MUNICIPAL",
    codMunicipio: "1404",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "9",
    nivel: "MUNICIPAL",
    codMunicipio: "1901",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "6",
    nivel: "MUNICIPAL",
    codMunicipio: "1602",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "6",
    nivel: "MUNICIPAL",
    codMunicipio: "1609",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "6",
    nivel: "MUNICIPAL",
    codMunicipio: "1606",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "5",
    nivel: "MUNICIPAL",
    codMunicipio: "1520",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "5",
    nivel: "MUNICIPAL",
    codMunicipio: "1513",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    codDepartamento: "5",
    nivel: "MUNICIPAL",
    codMunicipio: "1532",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1501",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1710",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1723",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1755",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1705",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1714",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1703",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1735",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1713",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1750",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1708",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1210",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1287",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1256",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1265",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1227",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1286",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1260",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1215",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1245",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1226",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1258",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-01",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1205",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 0 },
    ],
  },

  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "8",
    codMunicipio: "1807",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1113",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1124",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1114",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1128",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1110",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1116",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1111",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1129",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1126",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1122",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1334",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1309",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1312",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1317",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1347",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1304",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "4",
    codMunicipio: "1420",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "4",
    codMunicipio: "1407",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "4",
    codMunicipio: "1409",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "4",
    codMunicipio: "1404",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "9",
    codMunicipio: "1901",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1520",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1513",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1532",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1501",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "6",
    codMunicipio: "1602",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "6",
    codMunicipio: "1609",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "6",
    codMunicipio: "1606",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1710",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1723",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1755",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1705",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1714",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1703",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1735",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1713",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1750",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1708",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1210",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1287",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1256",
    metadata: [
      {
        label: "Sí",
        value: 1,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1265",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1227",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1286",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1260",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1215",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1245",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1226",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1258",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-04",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1205",
    metadata: [
      {
        label: "Sí",
        value: 0,
      },
      {
        label: "No",
        value: 0,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "6",
    codMunicipio: "1602",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "6",
    codMunicipio: "1609",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "6",
    codMunicipio: "1606",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "8",
    codMunicipio: "1807",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1113",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1124",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1114",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1128",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 1 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1110",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1116",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1111",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1129",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "Villa Vaca",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1122",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1334",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1309",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 1 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1312",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 1 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1317",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1347",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1304",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "4",
    codMunicipio: "1420",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "4",
    codMunicipio: "1407",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "4",
    codMunicipio: "1409",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "4",
    codMunicipio: "1404",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "9",
    codMunicipio: "1901",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1520",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1513",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1532",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1501",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1710",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1723",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1755",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1705",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1714",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 1 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1703",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1735",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1713",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1750",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1708",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 1 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1210",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1287",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1256",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1265",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1227",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1286",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1260",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1215",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1245",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 1 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1226",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1258",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 0 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-02",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1205",
    metadata: [
      { label: "Equipo multidisciplinario completo", value: 1 },
      { label: "2 profesionales", value: 0 },
      { label: "1 profesional", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "Santa Cruz",
    codMunicipio: "1710",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "Santa Cruz",
    codMunicipio: "1723",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "Santa Cruz",
    codMunicipio: "1755",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamenot: "Santa Cruz",
    codMunicipio: "1705",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "Santa Cruz",
    codMunicipio: "1714",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamenot: "Santa Cruz",
    codMunicipio: "1703",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "Santa Cruz",
    codMunicipio: "1735",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "Santa Cruz",
    codMunicipio: "1713",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "Santa Cruz",
    codMunicipio: "1750",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "Santa Cruz",
    codMunicipio: "1708",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "La Paz",
    codMunicipio: "1210",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "La Paz",
    codMunicipio: "1287",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "La Paz",
    codMunicipio: "1256",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "La Paz",
    codMunicipio: "1265",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "La Paz",
    codMunicipio: "1227",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "La Paz",
    codMunicipio: "1286",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "La Paz",
    codMunicipio: "1260",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "La Paz",
    codMunicipio: "1215",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "La Paz",
    codMunicipio: "1245",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "La Paz",
    codMunicipio: "1226",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "La Paz",
    codMunicipio: "1258",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamenot: "La Paz",
    codMunicipio: "1205",
    codCategoria: "CAT-01",
    codIndicador: "IND-03",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 10 },
      { label: "Casos de información/orientación Hombres", value: 7 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 0 },
      { label: "Casos de información/orientación Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 2660 },
      { label: "Casos de información/orientación Hombres", value: 1329 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 103 },
      { label: "Casos de información/orientación Hombres", value: 63 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 60 },
      { label: "Casos de información/orientación Hombres", value: 27 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 50 },
      { label: "Casos de información/orientación Hombres", value: 20 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 43 },
      { label: "Casos de información/orientación Hombres", value: 24 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 3 },
      { label: "Casos de información/orientación Hombres", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 86 },
      { label: "Casos de información/orientación Hombres", value: 35 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 80 },
      { label: "Casos de información/orientación Hombres", value: 20 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 11 },
      { label: "Casos de información/orientación Hombres", value: 8 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 2 },
      { label: "Casos de información/orientación Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 12 },
      { label: "Casos de información/orientación Hombres", value: 6 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 28 },
      { label: "Casos de información/orientación Hombres", value: 21 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 16 },
      { label: "Casos de información/orientación Hombres", value: 2 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 400 },
      { label: "Casos de información/orientación Hombres", value: 100 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 490 },
      { label: "Casos de información/orientación Hombres", value: 312 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 122 },
      { label: "Casos de información/orientación Hombres", value: 6 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 57 },
      { label: "Casos de información/orientación Hombres", value: 25 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 83 },
      { label: "Casos de información/orientación Hombres", value: 70 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 15 },
      { label: "Casos de información/orientación Hombres", value: 8 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 50 },
      { label: "Casos de información/orientación Hombres", value: 15 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 36 },
      { label: "Casos de información/orientación Hombres", value: 12 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 181 },
      { label: "Casos de información/orientación Hombres", value: 18 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 577 },
      { label: "Casos de información/orientación Hombres", value: 340 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 20 },
      { label: "Casos de información/orientación Hombres", value: 15 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 128 },
      { label: "Casos de información/orientación Hombres", value: 142 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 33 },
      { label: "Casos de información/orientación Hombres", value: 12 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 0 },
      { label: "Casos de información/orientación Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 42 },
      { label: "Casos de información/orientación Hombres", value: 19 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 10 },
      { label: "Casos de información/orientación Hombres", value: 10 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 0 },
      { label: "Casos de información/orientación Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 0 },
      { label: "Casos de información/orientación Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 258 },
      { label: "Casos de información/orientación Hombres", value: 78 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 50 },
      { label: "Casos de información/orientación Hombres", value: 20 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 28 },
      { label: "Casos de información/orientación Hombres", value: 32 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 300 },
      { label: "Casos de información/orientación Hombres", value: 250 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 200 },
      { label: "Casos de información/orientación Hombres", value: 50 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 58 },
      { label: "Casos de información/orientación Hombres", value: 30 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 32 },
      { label: "Casos de información/orientación Hombres", value: 17 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 25 },
      { label: "Casos de información/orientación Hombres", value: 6 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 2500 },
      { label: "Casos de información/orientación Hombres", value: 1000 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 90 },
      { label: "Casos de información/orientación Hombres", value: 10 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 18 },
      { label: "Casos de información/orientación Hombres", value: 7 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 20 },
      { label: "Casos de información/orientación Hombres", value: 4 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 38 },
      { label: "Casos de información/orientación Hombres", value: 7 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 10 },
      { label: "Casos de información/orientación Hombres", value: 3 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 5 },
      { label: "Casos de información/orientación Hombres", value: 2 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 536 },
      { label: "Casos de información/orientación Hombres", value: 400 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 8 },
      { label: "Casos de información/orientación Hombres", value: 10 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-01",
    codIndicador: "IND-05",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 928 },
      { label: "Casos de información/orientación Hombres", value: 777 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 61 },
      { label: "Casos de denuncias formales Hombres", value: 23 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 14 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 7 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 51 },
      { label: "Casos de denuncias formales Hombres", value: 26 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 3 },
      { label: "Casos de denuncias formales Hombres", value: 4 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 35 },
      { label: "Casos de denuncias formales Hombres", value: 6 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 6 },
      { label: "Casos de denuncias formales Hombres", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 7 },
      { label: "Casos de denuncias formales Hombres", value: 2 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 1 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 23 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 9 },
      { label: "Casos de denuncias formales Hombres", value: 14 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 4 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 8 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 177 },
      { label: "Casos de denuncias formales Hombres", value: 22 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 17 },
      { label: "Casos de denuncias formales Hombres", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 12 },
      { label: "Casos de denuncias formales Hombres", value: 2 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 8 },
      { label: "Casos de denuncias formales Hombres", value: 22 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 1 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 0 },
      { label: "Casos de denuncias formales Hombres", value: 6 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 4 },
      { label: "Casos de denuncias formales Hombres", value: 4 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 62 },
      { label: "Casos de denuncias formales Hombres", value: 123 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 17 },
      { label: "Casos de denuncias formales Hombres", value: 2 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 8 },
      { label: "Casos de denuncias formales Hombres", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 0 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 16 },
      { label: "Casos de denuncias formales Hombres", value: 55 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 98 },
      { label: "Casos de denuncias formales Hombres", value: 65 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 2 },
      { label: "Casos de denuncias formales Hombres", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 15 },
      { label: "Casos de denuncias formales Hombres", value: 3 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 0 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 0 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 15 },
      { label: "Casos de denuncias formales Hombres", value: 10 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 0 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 0 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 121 },
      { label: "Casos de denuncias formales Hombres", value: 58 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 23 },
      { label: "Casos de denuncias formales Hombres", value: 13 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 14 },
      { label: "Casos de denuncias formales Hombres", value: 2 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 35 },
      { label: "Casos de denuncias formales Hombres", value: 3 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 0 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 2 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 24 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 0 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 117 },
      { label: "Casos de denuncias formales Hombres", value: 8 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 35 },
      { label: "Casos de denuncias formales Hombres", value: 10 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 8 },
      { label: "Casos de denuncias formales Hombres", value: 2 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 0 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 33 },
      { label: "Casos de denuncias formales Hombres", value: 6 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 0 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 2 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 8 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 3 },
      { label: "Casos de denuncias formales Hombres", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-01",
    codIndicador: "IND-06",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de denuncias formales Mujeres", value: 2572 },
      { label: "Casos de denuncias formales Hombres", value: 1929 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 180 },
      { label: "Casos de denuncias formales Mujeres", value: 120 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 158 },
      { label: "Casos de denuncias formales Mujeres", value: 20 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 500 },
      { label: "Casos de denuncias formales Mujeres", value: 134 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 16 },
      { label: "Casos de denuncias formales Mujeres", value: 68 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 0 },
      { label: "Casos de denuncias formales Mujeres", value: 2 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 95 },
      { label: "Casos de denuncias formales Mujeres", value: 163 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 40 },
      { label: "Casos de denuncias formales Mujeres", value: 16 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 480 },
      { label: "Casos de denuncias formales Mujeres", value: 52 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 8 },
      { label: "Casos de denuncias formales Mujeres", value: 8 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 15 },
      { label: "Casos de denuncias formales Mujeres", value: 23 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 41 },
      { label: "Casos de denuncias formales Mujeres", value: 26 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 1584 },
      { label: "Casos de denuncias formales Mujeres", value: 290 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 28 },
      { label: "Casos de denuncias formales Mujeres", value: 12 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 1472 },
      { label: "Casos de denuncias formales Mujeres", value: 808 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 122 },
      { label: "Casos de denuncias formales Mujeres", value: 122 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 258 },
      { label: "Casos de denuncias formales Mujeres", value: 64 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 66 },
      { label: "Casos de denuncias formales Mujeres", value: 87 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 14 },
      { label: "Casos de denuncias formales Mujeres", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 50 },
      { label: "Casos de denuncias formales Mujeres", value: 15 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 63 },
      { label: "Casos de denuncias formales Mujeres", value: 63 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 100 },
      { label: "Casos de denuncias formales Mujeres", value: 54 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 56 },
      { label: "Casos de denuncias formales Mujeres", value: 30 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 93 },
      { label: "Casos de denuncias formales Mujeres", value: 29 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 459 },
      { label: "Casos de denuncias formales Mujeres", value: 100 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 4186 },
      { label: "Casos de denuncias formales Mujeres", value: 110 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 730 },
      { label: "Casos de denuncias formales Mujeres", value: 57 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 44 },
      { label: "Casos de denuncias formales Mujeres", value: 32 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 77 },
      { label: "Casos de denuncias formales Mujeres", value: 11 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 1845 },
      { label: "Casos de denuncias formales Mujeres", value: 1138 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 44 },
      { label: "Casos de denuncias formales Mujeres", value: 52 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 10 },
      { label: "Casos de denuncias formales Mujeres", value: 125 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 79 },
      { label: "Casos de denuncias formales Mujeres", value: 90 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 450 },
      { label: "Casos de denuncias formales Mujeres", value: 73 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 315 },
      { label: "Casos de denuncias formales Mujeres", value: 386 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 110 },
      { label: "Casos de denuncias formales Mujeres", value: 56 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 125 },
      { label: "Casos de denuncias formales Mujeres", value: 30 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 1441 },
      { label: "Casos de denuncias formales Mujeres", value: 113 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 190 },
      { label: "Casos de denuncias formales Mujeres", value: 13 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 200 },
      { label: "Casos de denuncias formales Mujeres", value: 13 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 122 },
      { label: "Casos de denuncias formales Mujeres", value: 24 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 348 },
      { label: "Casos de denuncias formales Mujeres", value: 40 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 113 },
      { label: "Casos de denuncias formales Mujeres", value: 53 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 24 },
      { label: "Casos de denuncias formales Mujeres", value: 24 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 56 },
      { label: "Casos de denuncias formales Mujeres", value: 17 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 155 },
      { label: "Casos de denuncias formales Mujeres", value: 29 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 5 },
      { label: "Casos de denuncias formales Mujeres", value: 66 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 5 },
      { label: "Casos de denuncias formales Mujeres", value: 5 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 10 },
      { label: "Casos de denuncias formales Mujeres", value: 15 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 306 },
      { label: "Casos de denuncias formales Mujeres", value: 23 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 5 },
      { label: "Casos de denuncias formales Mujeres", value: 5 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-01",
    codIndicador: "IND-34",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Casos de información/orientación Mujeres", value: 4136 },
      { label: "Casos de denuncias formales Mujeres", value: 4157 },
    ],
  },

  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 1 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 1 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 1 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 2 },
      { label: "2022", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 1 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  // {
  //   codDepartamento: "5",
  //   codMunicipio: "1532",
  //   codCategoria: "CAT-01",
  //   codIndicador: "IND-07",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "2021", value: null },
  //     { label: "2022", value: null },
  //   ],
  // },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 1 },
      { label: "2022", value: 2 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 1 },
      { label: "2022", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  // {
  //   codDepartamento: "7",
  //   codMunicipio: "1713",
  //   codCategoria: "CAT-01",
  //   codIndicador: "IND-07",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "2021", value: null },
  //     { label: "2022", value: null },
  //   ],
  // },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 1 },
      { label: "2022", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 1 },
      { label: "2022", value: 0 },
    ],
  },
  // {
  //   codDepartamento: "2",
  //   codMunicipio: "1265",
  //   codCategoria: "CAT-01",
  //   codIndicador: "IND-07",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "2021", value: null },
  //     { label: "2022", value: null },
  //   ],
  // },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 2 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 0 },
      { label: "2022", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-01",
    codIndicador: "IND-07",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "2021", value: 20 },
      { label: "2022", value: 2 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-01",
    codIndicador: "IND-09",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-01",
    codIndicador: "IND-08",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },

  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 8,
    nivel: "MUNICIPAL",
    codMunicipio: 1807,
    metadata: [
      {
        label: "",
        value: 2,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 1,
    nivel: "MUNICIPAL",
    codMunicipio: 1113,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 1,
    nivel: "MUNICIPAL",
    codMunicipio: 1124,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 1,
    nivel: "MUNICIPAL",
    codMunicipio: 1114,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 1,
    nivel: "MUNICIPAL",
    codMunicipio: 1128,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 1,
    nivel: "MUNICIPAL",
    codMunicipio: 1110,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 1,
    nivel: "MUNICIPAL",
    codMunicipio: 1116,
    metadata: [
      {
        label: "",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 1,
    nivel: "MUNICIPAL",
    codMunicipio: 1111,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 1,
    nivel: "MUNICIPAL",
    codMunicipio: 1129,
    metadata: [
      {
        label: "",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 1,
    nivel: "MUNICIPAL",
    codMunicipio: 1122,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 1,
    nivel: "MUNICIPAL",
    codMunicipio: 1126,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 3,
    nivel: "MUNICIPAL",
    codMunicipio: 1334,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 3,
    nivel: "MUNICIPAL",
    codMunicipio: 1309,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 3,
    nivel: "MUNICIPAL",
    codMunicipio: 1312,
    metadata: [
      {
        label: "",
        value: 4,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 3,
    nivel: "MUNICIPAL",
    codMunicipio: 1317,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 3,
    nivel: "MUNICIPAL",
    codMunicipio: 1347,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 3,
    nivel: "MUNICIPAL",
    codMunicipio: 1304,
    metadata: [
      {
        label: "",
        value: 3,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 2,
    nivel: "MUNICIPAL",
    codMunicipio: 1210,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 2,
    nivel: "MUNICIPAL",
    codMunicipio: 1287,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 2,
    nivel: "MUNICIPAL",
    codMunicipio: 1256,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 2,
    nivel: "MUNICIPAL",
    codMunicipio: 1265,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 2,
    nivel: "MUNICIPAL",
    codMunicipio: 1205,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 2,
    nivel: "MUNICIPAL",
    codMunicipio: 1227,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 2,
    nivel: "MUNICIPAL",
    codMunicipio: 1286,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 2,
    nivel: "MUNICIPAL",
    codMunicipio: 1260,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 2,
    nivel: "MUNICIPAL",
    codMunicipio: 1215,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 2,
    nivel: "MUNICIPAL",
    codMunicipio: 1245,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 2,
    nivel: "MUNICIPAL",
    codMunicipio: 1226,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 2,
    nivel: "MUNICIPAL",
    codMunicipio: 1258,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 4,
    nivel: "MUNICIPAL",
    codMunicipio: 1404,
    metadata: [
      {
        label: "",
        value: 2,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 4,
    nivel: "MUNICIPAL",
    codMunicipio: 1420,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 4,
    nivel: "MUNICIPAL",
    codMunicipio: 1407,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 4,
    nivel: "MUNICIPAL",
    codMunicipio: 1409,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 9,
    nivel: "MUNICIPAL",
    codMunicipio: 1901,
    metadata: [
      {
        label: "",
        value: 3,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 5,
    nivel: "MUNICIPAL",
    codMunicipio: 1532,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 5,
    nivel: "MUNICIPAL",
    codMunicipio: 1520,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 5,
    nivel: "MUNICIPAL",
    codMunicipio: 1513,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 5,
    nivel: "MUNICIPAL",
    codMunicipio: 1501,
    metadata: [
      {
        label: "",
        value: 5,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 7,
    nivel: "MUNICIPAL",
    codMunicipio: 1710,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 7,
    nivel: "MUNICIPAL",
    codMunicipio: 1723,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 7,
    nivel: "MUNICIPAL",
    codMunicipio: 1755,
    metadata: [
      {
        label: "",
        value: 2,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 7,
    nivel: "MUNICIPAL",
    codMunicipio: 1705,
    metadata: [
      {
        label: "",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 7,
    nivel: "MUNICIPAL",
    codMunicipio: 1735,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 7,
    nivel: "MUNICIPAL",
    codMunicipio: 1714,
    metadata: [
      {
        label: "",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 7,
    nivel: "MUNICIPAL",
    codMunicipio: 1703,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 7,
    nivel: "MUNICIPAL",
    codMunicipio: 1713,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 7,
    nivel: "MUNICIPAL",
    codMunicipio: 1708,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 7,
    nivel: "MUNICIPAL",
    codMunicipio: 1750,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 6,
    nivel: "MUNICIPAL",
    codMunicipio: 1602,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 6,
    nivel: "MUNICIPAL",
    codMunicipio: 1609,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-35",
    codDepartamento: 6,
    nivel: "MUNICIPAL",
    codMunicipio: 1606,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },

  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 8,
    codMunicipio: 1807,
    metadata: [
      {
        label: "",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1113,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1124,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1114,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1128,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1110,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1116,
    metadata: [
      {
        label: "",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1111,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1129,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1122,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1126,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 3,
    codMunicipio: 1334,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 3,
    codMunicipio: 1309,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 3,
    codMunicipio: 1312,
    metadata: [
      {
        label: "",
        value: 8,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 3,
    codMunicipio: 1317,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 3,
    codMunicipio: 1347,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 3,
    codMunicipio: 1304,
    metadata: [
      {
        label: "",
        value: 3,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1210,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1287,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1256,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1265,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1205,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1227,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1286,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1260,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1215,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1245,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1226,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1258,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 4,
    codMunicipio: 1404,
    metadata: [
      {
        label: "",
        value: 3,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 4,
    codMunicipio: 1420,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 4,
    codMunicipio: 1407,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 4,
    codMunicipio: 1409,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 9,
    codMunicipio: 1901,
    metadata: [
      {
        label: "",
        value: 4,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 5,
    codMunicipio: 1532,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 5,
    codMunicipio: 1520,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 5,
    codMunicipio: 1513,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 5,
    codMunicipio: 1501,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1710,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1723,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1755,
    metadata: [
      {
        label: "",
        value: 3,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1705,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1735,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1714,
    metadata: [
      {
        label: "",
        value: 1,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1703,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1713,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1708,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1750,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 6,
    codMunicipio: 1602,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 6,
    codMunicipio: 1609,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },
  {
    codCategoria: "CAT-01",
    codIndicador: "IND-36",
    nivel: "MUNICIPAL",
    codDepartamento: 6,
    codMunicipio: 1606,
    metadata: [
      {
        label: "",
        value: null,
      },
    ],
  },

  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 8,
    codMunicipio: 1807,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1113,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1124,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1114,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1128,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1110,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1116,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1111,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1129,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1122,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 1,
    codMunicipio: 1126,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 3,
    codMunicipio: 1334,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 3,
    codMunicipio: 1309,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 3,
    codMunicipio: 1312,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 3,
    codMunicipio: 1317,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 3,
    codMunicipio: 1347,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 3,
    codMunicipio: 1304,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1210,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1287,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1256,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1265,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1205,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1227,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1286,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1260,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1215,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1245,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1226,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 2,
    codMunicipio: 1258,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 4,
    codMunicipio: 1404,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 4,
    codMunicipio: 1420,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 4,
    codMunicipio: 1407,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 4,
    codMunicipio: 1409,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 9,
    codMunicipio: 1901,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 5,
    codMunicipio: 1532,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 5,
    codMunicipio: 1520,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 5,
    codMunicipio: 1513,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 5,
    codMunicipio: 1501,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1710,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1723,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1755,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1705,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1735,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1714,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1703,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1713,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1708,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 7,
    codMunicipio: 1750,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 6,
    codMunicipio: 1602,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 6,
    codMunicipio: 1609,
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-03",
    codIndicador: "IND-23",
    nivel: "MUNICIPAL",
    codDepartamento: 6,
    codMunicipio: 1606,
    metadata: [{ label: "", value: null }],
  },

  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "8",
    codMunicipio: "1807",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1113",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1124",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1114",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1128",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1110",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1116",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1111",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1129",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1122",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "1",
    codMunicipio: "1126",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1334",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1309",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1312",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1317",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1347",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "3",
    codMunicipio: "1304",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1210",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1287",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1256",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1265",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1205",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1227",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1286",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1260",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1215",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1245",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1226",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "2",
    codMunicipio: "1258",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "4",
    codMunicipio: "1404",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "4",
    codMunicipio: "1420",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "4",
    codMunicipio: "1407",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "4",
    codMunicipio: "1409",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "9",
    codMunicipio: "1901",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1532",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1520",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1513",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "5",
    codMunicipio: "1501",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1710",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1723",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1755",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1705",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1735",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1714",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1703",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1713",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1708",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "7",
    codMunicipio: "1750",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "6",
    codMunicipio: "1602",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "6",
    codMunicipio: "1609",
    metadata: [{ label: "", value: null }],
  },
  {
    codCategoria: "CAT-04",
    codIndicador: "IND-27",
    nivel: "MUNICIPAL",
    codDepartamento: "6",
    codMunicipio: "1606",
    metadata: [{ label: "", value: null }],
  },

  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      // { label: "Centros culturales y artísticos", value: null },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 0 },
      { label: "Centros académicos (Universidades)", value: 1 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-04",
    codIndicador: "IND-24",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Municipal", value: 1 },
      { label: "Centros académicos (Universidades)", value: 0 },
      { label: "Hombres", value: 0 },
      { label: "Centros culturales y artísticos", value: 0 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "Macharetí",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "Cobija",
    codMunicipio: "Pando",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "Villamontes",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "Cercado",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "Sucre",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Miguel Velasco",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Ramon",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "Andres Ibañez",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "Andres Ibañez",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "Andres Ibañez",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "Chúa Cocani",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-04",
    codIndicador: "IND-25",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Hombres de 14 a 18 años", value: 0 },
      { label: "Hombres de 19 a 59 años", value: 0 },
      { label: "Mujeres de 14 a 18 años", value: 0 },
      { label: "Mujeres de 19 a 59 años", value: 0 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "Macharetí",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "Villamontes",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Miguel Velasco",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "Chúa Cocani",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-05",
    codIndicador: "IND-28",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "Macharetí",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "Villamontes",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Miguel Velasco",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "Chúa Cocani",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-05",
    codIndicador: "IND-29",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 10 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 2 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 6 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 5 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 21 },
      // { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
      // { label: "Madres jóvenes (De 14 a 28 años)", value: null },
    ],
  },
  // {
  //   codDepartamento: "1",
  //   codMunicipio: "1110",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "1",
    codMunicipio: "Macharetí",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 15 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 3 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 2 },
    ],
  },
  // {
  //   codDepartamento: "1",
  //   codMunicipio: "1116",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 12 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 0 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 0 },
    ],
  },
  // {
  //   codDepartamento: "1",
  //   codMunicipio: "1122",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 0 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 3 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 3 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 6 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 2 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 8 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 5 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 5 },
    ],
  },
  // {
  //   codDepartamento: "9",
  //   codMunicipio: "1901",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 2 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 5 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 2 },
    ],
  },
  // {
  //   codDepartamento: "3",
  //   codMunicipio: "1309",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  // {
  //   codDepartamento: "3",
  //   codMunicipio: "1334",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  // {
  //   codDepartamento: "3",
  //   codMunicipio: "1347",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 27 },
      // { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
      // { label: "Madres jóvenes (De 14 a 28 años)", value: null },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 7 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 8 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 1 },
    ],
  },
  // {
  //   codDepartamento: "4",
  //   codMunicipio: "1420",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  // {
  //   codDepartamento: "4",
  //   codMunicipio: "1407",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  // {
  //   codDepartamento: "4",
  //   codMunicipio: "1409",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 12 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 0 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 0 },
    ],
  },
  // {
  //   codDepartamento: "5",
  //   codMunicipio: "1532",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  // {
  //   codDepartamento: "5",
  //   codMunicipio: "1520",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 3 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 0 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 0 },
    ],
  },
  // {
  //   codDepartamento: "5",
  //   codMunicipio: "1501",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 4 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 5 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 3 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 123 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 3 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 120 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "Villamontes",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 11 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 1 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 1 },
    ],
  },
  // {
  //   codDepartamento: "7",
  //   codMunicipio: "1710",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 15 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 2 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 2 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Miguel Velasco",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 9 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 2 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 2 },
    ],
  },
  // {
  //   codDepartamento: "7",
  //   codMunicipio: "1750",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  // {
  //   codDepartamento: "7",
  //   codMunicipio: "1755",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  // {
  //   codDepartamento: "7",
  //   codMunicipio: "1714",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 4 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 0 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 12 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 22 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 17 },
    ],
  },
  // {
  //   codDepartamento: "7",
  //   codMunicipio: "1723",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 8 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 2 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 2 },
    ],
  },
  // {
  //   codDepartamento: "2",
  //   codMunicipio: "1286",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "2",
    codMunicipio: "Chúa Cocani",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 1 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 3 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 0 },
    ],
  },
  // {
  //   codDepartamento: "2",
  //   codMunicipio: "1227",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  // {
  //   codDepartamento: "2",
  //   codMunicipio: "1245",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  // {
  //   codDepartamento: "2",
  //   codMunicipio: "1258",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  // {
  //   codDepartamento: "2",
  //   codMunicipio: "1256",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  // {
  //   codDepartamento: "2",
  //   codMunicipio: "1265",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  // {
  //   codDepartamento: "2",
  //   codMunicipio: "1260",
  //   codCategoria: "CAT-05",
  //   codIndicador: "IND-30",
  //   nivel: "MUNICIPAL",
  //   metadata: [
  //     { label: "Mujeres adultas (De 28 a 59 años)", value: null },
  //     { label: "Mujeres jóvenes (De 14 a 28 años)", value: null },
  //     { label: "Madres jóvenes (De 14 a 28 años)", value: null },
  //   ],
  // },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 2 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 2 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 17 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 3 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 3 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 15 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 0 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-05",
    codIndicador: "IND-30",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Mujeres adultas (De 28 a 59 años)", value: 6 },
      { label: "Mujeres jóvenes (De 14 a 28 años)", value: 2 },
      { label: "Madres jóvenes (De 14 a 28 años)", value: 0 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "Macharetí",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "Villamontes",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Miguel Velasco",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "Chúa Cocani",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-05",
    codIndicador: "IND-31",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "Macharetí",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "Villamontes",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Miguel Velasco",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "Chúa Cocani",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-05",
    codIndicador: "IND-32",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "Potosi",
    codMunicipio: "1532",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-02",
    codIndicador: "IND-10",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 1 },
      { label: "Unidad Económica/Productiva - No", value: 0 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 1 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 1 },
      { label: "Unidad de desarrollo humano/social - No", value: 0 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 1 },
      { label: "Juntas de Vecinos - No", value: 0 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 1 },
      { label: "Ministerio Público - No", value: 0 },
      { label: "Juzgado - Sí", value: 1 },
      { label: "Juzgado - No", value: 0 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 0 },
      { label: "Sector Salud - No", value: 1 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 1 },
      { label: "Unidad de desarrollo humano/social - No", value: 0 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 1 },
      { label: "Otro - No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 0 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 0 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 0,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 0 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 0 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 0 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 0 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 0 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 0 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 0 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 0 },
      { label: "Sector Educación - No", value: 1 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 1 },
      { label: "Juzgado - No", value: 0 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 1 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 1 },
      { label: "Control Social - No", value: 0 },
      { label: "Sindicatos - Sí", value: 1 },
      { label: "Sindicatos - No", value: 0 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 1 },
      { label: "Unidad Económica/Productiva - No", value: 0 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 1 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 1 },
      { label: "Juntas de Vecinos - No", value: 0 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 1 },
      { label: "Ministerio Público - No", value: 0 },
      { label: "Juzgado - Sí", value: 1 },
      { label: "Juzgado - No", value: 0 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 1 },
      { label: "Sindicatos - No", value: 0 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 1 },
      { label: "Asociación - No", value: 0 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 1 },
      { label: "Unidad de desarrollo humano/social - No", value: 0 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 1 },
      { label: "Ministerio Público - No", value: 0 },
      { label: "Juzgado - Sí", value: 1 },
      { label: "Juzgado - No", value: 0 },
      { label: "Control Social - Sí", value: 1 },
      { label: "Control Social - No", value: 0 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 1 },
      { label: "Cooperativas - No", value: 0 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 0 },
      { label: "Sector Educación - No", value: 1 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 1 },
      { label: "Juntas de Vecinos - No", value: 0 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 1 },
      { label: "Sindicatos - No", value: 0 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 0 },
      { label: "Sector Salud - No", value: 1 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 1 },
      { label: "Unidad de desarrollo humano/social - No", value: 0 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 1 },
      { label: "Otro - No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 1 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 1,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 0,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 1 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 1 },
      { label: "Juntas de Vecinos - No", value: 0 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 1 },
      { label: "Ministerio Público - No", value: 0 },
      { label: "Juzgado - Sí", value: 1 },
      { label: "Juzgado - No", value: 0 },
      { label: "Control Social - Sí", value: 1 },
      { label: "Control Social - No", value: 0 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 1 },
      { label: "Asociación - No", value: 0 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 1,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 0,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 0 },
      { label: "Sector Salud - No", value: 1 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 1 },
      { label: "Juntas de Vecinos - No", value: 0 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 1 },
      { label: "Asociación - No", value: 0 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 1 },
      { label: "Cooperativas - No", value: 0 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 1 },
      { label: "Unidad de desarrollo humano/social - No", value: 0 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 1 },
      { label: "Juzgado - No", value: 0 },
      { label: "Control Social - Sí", value: 1 },
      { label: "Control Social - No", value: 0 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 1 },
      { label: "Asociación - No", value: 0 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 1 },
      { label: "Unidad de desarrollo humano/social - No", value: 0 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 1 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 1 },
      { label: "Juntas de Vecinos - No", value: 0 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 1 },
      { label: "Ministerio Público - No", value: 0 },
      { label: "Juzgado - Sí", value: 1 },
      { label: "Juzgado - No", value: 0 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 1 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 1 },
      { label: "Cooperativas - No", value: 0 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "Potosi",
    codMunicipio: "1532",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 0 },
      { label: "Sector Educación - No", value: 1 },
      { label: "Sector Salud - Sí", value: 0 },
      { label: "Sector Salud - No", value: 1 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 0 },
      { label: "Sector Educación - No", value: 1 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 1 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 1 },
      { label: "Ministerio Público - No", value: 0 },
      { label: "Juzgado - Sí", value: 1 },
      { label: "Juzgado - No", value: 0 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 1 },
      { label: "Otro - No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 0 },
      { label: "Sector Educación - No", value: 1 },
      { label: "Sector Salud - Sí", value: 0 },
      { label: "Sector Salud - No", value: 1 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 0 },
      { label: "Sector Salud - No", value: 1 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 1 },
      { label: "Juntas de Vecinos - No", value: 0 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 1 },
      { label: "Control Social - No", value: 0 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 0 },
      { label: "Sector Educación - No", value: 1 },
      { label: "Sector Salud - Sí", value: 0 },
      { label: "Sector Salud - No", value: 1 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 0 },
      { label: "Sector Educación - No", value: 1 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 0 },
      { label: "Sector Educación - No", value: 1 },
      { label: "Sector Salud - Sí", value: 0 },
      { label: "Sector Salud - No", value: 1 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 1 },
      { label: "Control Social - No", value: 0 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 1 },
      { label: "Unidad de desarrollo humano/social - No", value: 0 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 1 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 0 },
      { label: "Sector Educación - No", value: 1 },
      { label: "Sector Salud - Sí", value: 0 },
      { label: "Sector Salud - No", value: 1 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 1 },
      { label: "Unidad Económica/Productiva - No", value: 0 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 1 },
      { label: "Unidad de desarrollo humano/social - No", value: 0 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 1 },
      { label: "Juntas de Vecinos - No", value: 0 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 1,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 0,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 1 },
      { label: "Juntas de Vecinos - No", value: 0 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 1 },
      { label: "Control Social - No", value: 0 },
      { label: "Sindicatos - Sí", value: 1 },
      { label: "Sindicatos - No", value: 0 },
      { label: "Cooperativas - Sí", value: 1 },
      { label: "Cooperativas - No", value: 0 },
      { label: "Asociación - Sí", value: 1 },
      { label: "Asociación - No", value: 0 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 1 },
      { label: "Juntas de Vecinos - No", value: 0 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 1 },
      { label: "Ministerio Público - No", value: 0 },
      { label: "Juzgado - Sí", value: 1 },
      { label: "Juzgado - No", value: 0 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 1 },
      { label: "Asociación - No", value: 0 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 1 },
      { label: "Control Social - No", value: 0 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 1 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 1 },
      { label: "Ministerio Público - No", value: 0 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 1 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 1 },
      { label: "Asociación - No", value: 0 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 1 },
      { label: "Unidad Económica/Productiva - No", value: 0 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 1 },
      { label: "Unidad Económica/Productiva - No", value: 0 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 1 },
      { label: "Juntas de Vecinos - No", value: 0 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 1 },
      { label: "Ministerio Público - No", value: 0 },
      { label: "Juzgado - Sí", value: 1 },
      { label: "Juzgado - No", value: 0 },
      { label: "Control Social - Sí", value: 1 },
      { label: "Control Social - No", value: 0 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 1 },
      { label: "DNA - No", value: 0 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 0 },
      { label: "Sector Salud - No", value: 1 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 1 },
      { label: "Policía - No", value: 0 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 1 },
      { label: "Control Social - No", value: 0 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 1 },
      { label: "Organizaciones de Jóvenes - No", value: 0 },
      { label: "ONGs - Sí", value: 1 },
      { label: "ONGs - No", value: 0 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 0 },
      { label: "Sector Salud - No", value: 1 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 1 },
      { label: "FELCV - No", value: 0 },
      { label: "Autoridades IOC - Sí", value: 1 },
      { label: "Autoridades IOC - No", value: 0 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 1 },
      { label: "Medios de Comunicación - No", value: 0 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 1 },
      { label: "Juzgado - No", value: 0 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 1 },
      { label: "SLIM - No", value: 0 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 1 },
      { label: "Concejo Municipal - No", value: 0 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 0 },
      { label: "Promotoras/es comunitarias/os - No", value: 1 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-02",
    codIndicador: "IND-11",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Sector Educación - Sí", value: 1 },
      { label: "Sector Educación - No", value: 0 },
      { label: "Sector Salud - Sí", value: 1 },
      { label: "Sector Salud - No", value: 0 },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - Sí",
        value: 0,
      },
      {
        label: "Sector Económico (Banco, empresa privada, ONG) - No",
        value: 1,
      },
      { label: "Unidad Económica/Productiva - Sí", value: 0 },
      { label: "Unidad Económica/Productiva - No", value: 1 },
      { label: "Unidad de desarrollo humano/social - Sí", value: 0 },
      { label: "Unidad de desarrollo humano/social - No", value: 1 },
      { label: "Concejo Municipal - Sí", value: 0 },
      { label: "Concejo Municipal - No", value: 1 },
      { label: "Policía - Sí", value: 0 },
      { label: "Policía - No", value: 1 },
      { label: "FELCV - Sí", value: 0 },
      { label: "FELCV - No", value: 1 },
      { label: "Autoridades IOC - Sí", value: 0 },
      { label: "Autoridades IOC - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 1 },
      { label: "Organizaciones de Mujeres - No", value: 0 },
      { label: "Medios de Comunicación - Sí", value: 0 },
      { label: "Medios de Comunicación - No", value: 1 },
      { label: "Juntas de Vecinos - Sí", value: 0 },
      { label: "Juntas de Vecinos - No", value: 1 },
      { label: "Promotoras/es comunitarias/os - Sí", value: 1 },
      { label: "Promotoras/es comunitarias/os - No", value: 0 },
      { label: "Ministerio Público - Sí", value: 0 },
      { label: "Ministerio Público - No", value: 1 },
      { label: "Juzgado - Sí", value: 0 },
      { label: "Juzgado - No", value: 1 },
      { label: "Control Social - Sí", value: 0 },
      { label: "Control Social - No", value: 1 },
      { label: "Sindicatos - Sí", value: 0 },
      { label: "Sindicatos - No", value: 1 },
      { label: "Cooperativas - Sí", value: 0 },
      { label: "Cooperativas - No", value: 1 },
      { label: "Asociación - Sí", value: 0 },
      { label: "Asociación - No", value: 1 },
      { label: "Organizaciones de Jóvenes - Sí", value: 0 },
      { label: "Organizaciones de Jóvenes - No", value: 1 },
      { label: "ONGs - Sí", value: 0 },
      { label: "ONGs - No", value: 1 },
      { label: "Otro - Sí", value: 0 },
      { label: "Otro - No", value: 1 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "Potosi",
    codMunicipio: "1532",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-02",
    codIndicador: "IND-12",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "Potosi",
    codMunicipio: "1532",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },

  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-02",
    codIndicador: "IND-13",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "Potosi",
    codMunicipio: "1532",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-02",
    codIndicador: "IND-14",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "Potosi",
    codMunicipio: "1532",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },

  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-02",
    codIndicador: "IND-15",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "Potosi",
    codMunicipio: "1532",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-02",
    codIndicador: "IND-16",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 1 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1128",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 0 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "9",
    codMunicipio: "1901",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "Potosi",
    codMunicipio: "1532",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1606",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1750",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1708",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1287",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-02",
    codIndicador: "IND-17",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "Macharetí",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "Cobija",
    codMunicipio: "Pando",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "Villamontes",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Miguel Velasco",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Ramon",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "Chúa Cocani",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-03",
    codIndicador: "IND-18",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "Macharetí",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "Cobija",
    codMunicipio: "Pando",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "Villamontes",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Miguel Velasco",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Ramon",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "Chúa Cocani",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-03",
    codIndicador: "IND-19",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "Macharetí",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "Cobija",
    codMunicipio: "Pando",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "Villamontes",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Miguel Velasco",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Ramon",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "Chúa Cocani",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-03",
    codIndicador: "IND-20",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "Macharetí",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "Cobija",
    codMunicipio: "Pando",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "Villamontes",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Miguel Velasco",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Ramon",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "Chúa Cocani",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-03",
    codIndicador: "IND-21",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "SLIM - Sí", value: 0 },
      { label: "SLIM - No", value: 1 },
      { label: "DNA - Sí", value: 0 },
      { label: "DNA - No", value: 1 },
      { label: "Desarrollo humano/social - Sí", value: 0 },
      { label: "Desarrollo humano/social - No", value: 1 },
      { label: "Desarrollo económico/productivo - Sí", value: 0 },
      { label: "Desarrollo económico/productivo - No", value: 1 },
      { label: "Sector salud - Sí", value: 0 },
      { label: "Sector salud - No", value: 1 },
      { label: "Sector educación - Sí", value: 0 },
      { label: "Sector educación - No", value: 1 },
      { label: "Organizaciones de Mujeres - Sí", value: 0 },
      { label: "Organizaciones de Mujeres - No", value: 1 },
    ],
  },
  {
    codDepartamento: "8",
    codMunicipio: "1807",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1113",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1114",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1110",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "Macharetí",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1116",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1111",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1122",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1126",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1124",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "1",
    codMunicipio: "1129",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "Cobija",
    codMunicipio: "Pando",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1317",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1309",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1334",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1347",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1312",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "3",
    codMunicipio: "1304",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1609",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "1602",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "6",
    codMunicipio: "Villamontes",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1420",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1407",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1409",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "4",
    codMunicipio: "1404",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1532",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1520",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1513",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "5",
    codMunicipio: "1501",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1710",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1735",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Miguel Velasco",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "San Ramon",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1755",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1714",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1703",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1713",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1723",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "7",
    codMunicipio: "1705",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1286",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "Chúa Cocani",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1227",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1245",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1258",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1256",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1265",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1260",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1215",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1226",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1210",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
  {
    codDepartamento: "2",
    codMunicipio: "1205",
    codCategoria: "CAT-03",
    codIndicador: "IND-22",
    nivel: "MUNICIPAL",
    metadata: [
      { label: "Sí", value: 0 },
      { label: "No", value: 1 },
    ],
  },
];
