import {
  Box,
  Button,
  CircularProgress,
  MobileStepper,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material";
import quizSuccess from "./../../../assets/course/cm_aprobado.png";
import iconUnidadCompletado from "./../../../assets/course/cm_aprobado.png";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  useCompletarContenidoUnidad,
  useGetUnitContent,
  useGetUnitStudentCourse,
} from "./data/StudentCourseClient";
import { isJsonString, printLog } from "../../../shared/utils";
import { ContentViewer } from "../../../shared/components/curso/content/ContentViewer";
import { YoutubePlayer } from "../../../shared/components/curso/YoutubePlayer";
import { QuizViewer } from "../../../shared/components/curso/QuizViewer";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import Layout from "../../../components/Layout";
import { Alerts } from "../../../shared/hooks/useAlert";
import { QuizFeedbackViewer } from "./QuizFeedbackViewer";
import {
  CompletarContenidoRequest,
  CompletarContenidoUnidadResponse,
  ContenidoCurso,
  QuizFeedback,
  UnidadCursoParticipante,
} from "./data/StudentCourseApiModels";
import {
  AdjuntosMap,
  QuizFinishedEvent,
} from "../../../shared/components/curso/CursoViewerTypes";
import contenidoTextoIcon from "./../../../assets/course/cm_doc_normal.svg";
import contenidoVideoIcon from "./../../../assets/course/cm_vid_normal.svg";
import contenidoQuizIcon from "./../../../assets/course/cm_tarea_normal.svg";
import contenidoEntregableIcon from "./../../../assets/course/cm_adj_up_normal.svg";
import contenidoToolsIcon from "./../../../assets/course/cm_tools.svg";

export const UnidadContenidoCursoPage = () => {
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const urlParams = useParams();
  // const cursoParticipanteId = urlParams.cursoParticipanteId as string;
  // const unidadId = urlParams.unidadId as string;
  const {
    state: { numeroContenido, cursoParticipanteId, unidadId },
  }: any = useLocation();
  const [activeStep, setActiveStep] = useState(1);

  const [loadingUnidad, setLoadingUnidad] = useState(true);

  type ActiveView = "CONTENIDO" | "FIN_UNIDAD" | "FIN_QUIZ";
  const [activeView, setActiveView] = useState<ActiveView>("CONTENIDO");
  const [quizFeedback, setQuizFeedback] = useState<QuizFeedback | null>(null);

  const [unidadCurso, setUnidadCurso] =
    useState<UnidadCursoParticipante | null>(null);
  const [contenidoUnidad, setContenidoUnidad] = useState<ContenidoCurso | null>(
    null
  );
  // printLog(
  //   "idCursoParticipante de locatio ::: ",
  //   cursoParticipanteId,
  //   unidadId,
  //   numeroContenido
  // );

  /// client methods
  const getUnidadCursoParticipante = useGetUnitStudentCourse();
  const getContenidoUnidad = useGetUnitContent();
  const completarContenidoUnidad = useCompletarContenidoUnidad();

  // const alertContext = useAlertModalContext();

  const maxSteps =
    unidadCurso !== null
      ? unidadCurso.contenidos[unidadCurso.contenidos.length - 1].numero
      : 0;
  printLog("maxSteps :: ", maxSteps);

  async function fetchUnidadCursoParticipante() {
    const fetchedUnidadCursoParticipante = await getUnidadCursoParticipante({
      cursoParticipanteId,
      unidadId,
    });
    printLog(
      "Unidad curso participante fetcheado :: ",
      fetchedUnidadCursoParticipante
    );
    setUnidadCurso(fetchedUnidadCursoParticipante);
  }

  async function fetchContenidoUnidadCurso(_numero: number) {
    setLoadingUnidad(true);
    printLog("fetcheando contenido unidad..., numero contenido: ", _numero);
    const fetchedContenidoUnidad = await getContenidoUnidad({
      cursoParticipanteId,
      unidadId,
      numero: _numero,
    });
    printLog(
      "Contenido - Unidad fetcheado :: ",
      _numero,
      fetchedContenidoUnidad
    );
    setContenidoUnidad(fetchedContenidoUnidad);
    setTimeout(() => {
      setLoadingUnidad(false);
    }, 200);
  }

  useEffect(() => {
    if (cursoParticipanteId) {
      fetchUnidadCursoParticipante();
    }
    setActiveStep(numeroContenido);
    fetchContenidoUnidadCurso(numeroContenido);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = async (
    _contendidoUnidad: any,
    unidadCursoContenidos: any
  ) => {
    printLog("contenido unidad :: ", _contendidoUnidad);
    printLog("unidadCursoContenidos :: ", unidadCursoContenidos);
    printLog(
      "unidadCursoContenidos[activeStep - 1] :: ",
      unidadCursoContenidos[activeStep - 1]
    );
    printLog(
      "activeStep < unidadCurso.contenidos.length :: ",
      activeStep,
      unidadCurso?.contenidos.length
    );
    printLog(
      "activeStep < unidadCurso.contenidos.length :: ",
      activeStep < (unidadCurso?.contenidos?.length ?? 0)
    );
    await completarContenido(unidadCursoContenidos[activeStep - 1]);
    await avanzar();
  };

  const completarContenido = async (
    _unidadContenido: UnidadCursoParticipante["contenidos"][number],
    _respuestas: CompletarContenidoRequest = {},
    adjuntosRespuesta: AdjuntosMap = {}
  ): Promise<CompletarContenidoUnidadResponse> => {
    printLog("completar el contenido :: ", _unidadContenido);
    printLog("respuestas :: ", _respuestas);
    printLog("Adjuntos respuestas ::", adjuntosRespuesta);
    if (_unidadContenido.estado !== "RESPONDIDO") {
      const params = {
        cursoParticipanteId,
        unidadId,
        numero: _unidadContenido.numero,
      };
      return await completarContenidoUnidad(
        params,
        _respuestas as CompletarContenidoRequest,
        adjuntosRespuesta
      );
    } else {
      return {
        quizFeedback: undefined,
      };
    }
  };

  const handleBack = async () => {
    // setUnidadCurso(null);
    setContenidoUnidad(null);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    await fetchUnidadCursoParticipante();
    await fetchContenidoUnidadCurso(activeStep - 1);
  };

  const convertedContent = (texto: string) => {
    if (isJsonString(texto) && JSON.parse(texto)) {
      const parseado = JSON.parse(texto);
      const editorContent = EditorState.createWithContent(
        convertFromRaw(parseado)
      );
      const rawContentState = convertToRaw(editorContent.getCurrentContent());
      let markup = draftToHtml(rawContentState);

      // Validando estilos con ancho y alto con valor auto
      markup = markup.replace(/(width\s*:\s*auto)/gi, "width: 100%");
      // markup = markup.replace(/(height\s*:\s*auto)/ig, "height: auto");

      // printLog('markup resultado :: ', typeof markup, markup);

      // setConvertedContent(convertidoHTML)
      return markup;
      // return convertidoHTML;
    } else {
      if (texto.trim() !== "") {
        return texto;
      }
      return "<p>Sin contenido</p>";
    }
  };

  const handleFinalizarQuiz = async (datosQuiz: QuizFinishedEvent) => {
    printLog(
      ":: unidadCursoContenido actual al finalizar qquiz :: ",
      unidadCurso?.contenidos[activeStep - 1]
    );
    printLog("contenidoUnidad al finalizar qquiz :: ", contenidoUnidad);
    printLog(
      "[UnidadContenidoCursoPage.tsx] Finalizar quiz :: datosQuiz :: ",
      typeof datosQuiz,
      datosQuiz
    );
    /// validar las respuestas del quiz
    let hasError = false;
    let indiceError;
    for (const idx in datosQuiz.respuestas) {
      printLog(
        `datosQuiz.respuestas[${idx}] > `,
        typeof datosQuiz.respuestas[idx],
        datosQuiz.respuestas[idx]
      );
      if (
        !datosQuiz.respuestas[idx].length ||
        (Array.isArray(datosQuiz.respuestas[idx]) &&
          (datosQuiz.respuestas[idx] as []).some(
            (x: any) => typeof x === "string" && x === ""
          ))
      ) {
        hasError = true;
        indiceError = Number(idx) + 1;
        break;
      }
    }
    if (hasError) {
      Alerts.showError({
        description: `Todas las preguntas del cuestionario deben contener una respuesta válida y no estar en blanco. Debe ingresar al menos una respuesta válida para la pregunta ${indiceError}`,
      });
      // alertContext.showErrorAlert({
      //   message: `Debe ingresar al menos una respuesta válida para la pregunta ${indiceError}`,
      //   btnText: 'Continuar'
      // });
      // showAlertError({
      //   btnText: "Continuar",
      //   title: "¡Error!",
      //   message: `Debe ingresar al menos una respuesta válida para la pregunta ${indiceError}`,
      // });
      return;
    }

    // --------------
    let adjuntosRespuestas = undefined;
    let respuestas: CompletarContenidoRequest = [];

    for (const item of Object.values(datosQuiz.respuestas)) {
      respuestas.push(item);
    }

    if (contenidoUnidad!.tipo === "ENTREGABLE") {
      adjuntosRespuestas = datosQuiz.adjuntosRespuestas;
    }

    const result = await completarContenido(
      unidadCurso!.contenidos[activeStep - 1],
      respuestas,
      adjuntosRespuestas
    );

    if (result?.quizFeedback) {
      setActiveView("FIN_QUIZ");
      setQuizFeedback(result.quizFeedback);
    } else {
      await avanzar();
    }
  };

  const resetQuiz = () => {
    setActiveView("CONTENIDO");
  };

  const avanzar = async () => {
    printLog("maxSteps :: ", maxSteps);
    printLog("activeStep :: ", activeStep);
    if (maxSteps === activeStep) {
      printLog("mostrar pantalla unidad finalizado");
      setActiveView("FIN_UNIDAD");
    } else {
      // setUnidadCurso(null);
      setContenidoUnidad(null);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setActiveView("CONTENIDO");
      await fetchUnidadCursoParticipante();
      await fetchContenidoUnidadCurso(activeStep + 1);
    }
  };

  const contentState = unidadCurso?.contenidos[activeStep - 1].estado;

  const showArrows =
    contenidoUnidad?.tipo === "TEXTO" ||
    contenidoUnidad?.tipo === "VIDEO" ||
    contenidoUnidad?.tipo === "HERRAMIENTAS" ||
    contentState === "RESPONDIDO" ||
    activeView === "FIN_QUIZ";

  const getIconSrc = (
    tipo: "TEXTO" | "ENTREGABLE" | "VIDEO" | "QUIZ" | "HERRAMIENTAS"
  ) => {
    switch (tipo) {
      case "TEXTO":
        return contenidoTextoIcon;
      case "VIDEO":
        return contenidoVideoIcon;
      case "QUIZ":
        return contenidoQuizIcon;
      case "ENTREGABLE":
        return contenidoEntregableIcon;
      case "HERRAMIENTAS":
        return contenidoToolsIcon;
      default:
        return "";
    }
  };

  return (
    <Layout bgColor="custom">
      <Box mt={4}>
        {(activeView === "CONTENIDO" || activeView === "FIN_QUIZ") && (
          <>
            <Box
              mt={1}
              display="flex"
              gap={2}
              justifyContent="space-between"
              alignItems="center"
              alignContent="center"
              mb={1}
              mx={2}
            >
              <Typography
                sx={{
                  color: theme.palette.courseSecondary.main,
                  fontSize: 16,
                  fontWeight: 800,
                  pl: 2,
                  [theme.breakpoints.down("md")]: {
                    pl: 1,
                  },
                }}
              >
                {unidadCurso?.numero === 1 ? (
                  <>{unidadCurso.titulo}</>
                ) : (
                  <>
                    Unidad {unidadCurso?.numero}: {unidadCurso?.titulo}
                  </>
                )}
              </Typography>
              <Button
                size={"small"}
                onClick={history.goBack}
                sx={{
                  border: `1px solid ${theme.palette.primary.main}`,
                  color: theme.palette.primary.main,
                  borderRadius: "8px",
                  minWidth: "initial",
                  gap: 1,
                }}
              >
                <ArrowBackRounded />{" "}
                {!isMobile && (
                  <Typography
                    variant="body1"
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  >
                    Regresar
                  </Typography>
                )}
              </Button>
            </Box>
            {unidadCurso !== null && (
              <>
                {isMobile ? (
                  <MobileStepper
                    variant="progress"
                    steps={unidadCurso.contenidos.length}
                    position="static"
                    activeStep={activeStep - 1}
                    sx={{
                      flexGrow: 1,
                      justifyContent: "center",
                      background: "transparent",
                    }}
                    nextButton={null}
                    backButton={null}
                  />
                ) : (
                  <Stepper
                    activeStep={activeStep - 1}
                    alternativeLabel
                    style={{
                      transform: "scale(0.9)",
                    }}
                  >
                    {unidadCurso.contenidos.map((label, index) => (
                      <Step key={`${label.titulo}-${index}`}>
                        <StepLabel
                          sx={{
                            "& .MuiStepIcon-root": {
                              color:
                                index <= activeStep - 1
                                  ? theme.palette.primary.main
                                  : theme.palette.grey[500],
                            },
                            "& .MuiStepIcon-text": {
                              fill:
                                index === activeStep - 1
                                  ? theme.palette.getContrastText(
                                      theme.palette.primary.main
                                    )
                                  : theme.palette.getContrastText(
                                      theme.palette.grey[500]
                                    ),
                            },
                          }}
                        >
                          {""}
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}

                <Box
                  sx={{
                    mt: 1,
                    // mb: 10,
                    // pb: 10,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "calc(100vh - 210px)",
                      ml: {
                        xs: 1,
                        sm: 2,
                      },
                      mr: {
                        xs: 1,
                        sm: 2,
                      },
                      mb: {
                        xs: 1,
                        sm: 1,
                        md: 0,
                        lg: 0,
                      },
                    }}
                  >
                    <Box
                      flex="1"
                      display="flex"
                      flexDirection="column"
                      alignItems="stretch"
                      sx={{
                        px: 1,
                        py: 2,
                        overflowY: "scroll",
                        border: `1px solid ${theme.palette.neutral.main}`,
                        borderRadius: "7px",
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "calc(100% - 350px)",
                          lg: "calc(100% - 450px)",
                          xl: "calc(100% - 500px)",
                        },
                        [theme.breakpoints.down("md")]: {
                          px: 1,
                        },
                        "&::-webkit-scrollbar": {
                          width: "3px",
                          borderRadius: "10px",
                        },
                        "&::-webkit-scrollbar-track": {
                          background: theme.palette.neutral.light,
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: theme.palette.neutral.main,
                        },
                      }}
                    >
                      {loadingUnidad ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <CircularProgress />
                        </Box>
                      ) : (
                        <>
                          {contenidoUnidad !== null && contenidoUnidad ? (
                            <Typography
                              component="div"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 1,
                                fontWeight: 600,
                              }}
                            >
                              <Typography
                                component="span"
                                sx={{
                                  color: theme.palette.grey[500],
                                  fontWeight: 500,
                                  display: "flex",
                                  alignItems: "center",
                                  marginRight: 1,
                                }}
                              >
                                <img
                                  src={getIconSrc(contenidoUnidad.tipo)}
                                  style={{ height: 23 }}
                                  alt={`icono-${contenidoUnidad.tipo.toLowerCase()}`}
                                />
                              </Typography>
                              {contenidoUnidad.titulo}
                            </Typography>
                          ) : (
                            ""
                          )}

                          <Box sx={{ height: "100%" }}>
                            {contenidoUnidad !== null &&
                              contenidoUnidad.tipo === "TEXTO" &&
                              contenidoUnidad.texto !== null && (
                                <ContentViewer
                                  textoHTML={convertedContent(
                                    contenidoUnidad.texto
                                  )}
                                />
                              )}
                            {contenidoUnidad !== null &&
                              contenidoUnidad.tipo === "VIDEO" && (
                                <>
                                  {contenidoUnidad.link !== null && (
                                    <YoutubePlayer
                                      urlVideo={contenidoUnidad.link!}
                                    />
                                  )}
                                </>
                              )}
                            {contenidoUnidad != null &&
                              contenidoUnidad.tipo === "QUIZ" && (
                                <>
                                  {contentState !== "RESPONDIDO" &&
                                    activeView === "CONTENIDO" && (
                                      <QuizViewer
                                        idxContenido={activeStep}
                                        tipo={contenidoUnidad.tipo}
                                        preguntas={contenidoUnidad.preguntas}
                                        onFinalizarQuiz={handleFinalizarQuiz}
                                        isEvaluated={
                                          contenidoUnidad.isEvaluated
                                        }
                                        onCancel={() => history.goBack()}
                                      />
                                    )}
                                  {activeView === "FIN_QUIZ" &&
                                    quizFeedback && (
                                      <QuizFeedbackViewer
                                        feedbackData={quizFeedback}
                                        onRetry={resetQuiz}
                                      />
                                    )}
                                  {contentState === "RESPONDIDO" && (
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                      mt={4}
                                    >
                                      <img
                                        src={quizSuccess}
                                        height={250}
                                        alt="quiz-success"
                                      />
                                      <Typography
                                        mt={2}
                                        sx={{
                                          fontWeight: 700,
                                          color: theme.palette.info.hover,
                                        }}
                                      >
                                        ¡Quiz completado exitosamente!
                                      </Typography>
                                    </Box>
                                  )}
                                </>
                              )}
                            {contenidoUnidad !== null &&
                              contenidoUnidad.tipo === "ENTREGABLE" && (
                                <>
                                  {contentState !== "RESPONDIDO" && (
                                    <QuizViewer
                                      idxContenido={activeStep}
                                      tipo={contenidoUnidad.tipo}
                                      preguntas={contenidoUnidad.preguntas}
                                      onFinalizarQuiz={handleFinalizarQuiz}
                                    />
                                  )}
                                  {contentState === "RESPONDIDO" && (
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                      mt={4}
                                    >
                                      <img
                                        src={quizSuccess}
                                        height={250}
                                        alt="quiz-success"
                                      />
                                      <Typography
                                        mt={2}
                                        sx={{
                                          fontWeight: 700,
                                          color: theme.palette.info.hover,
                                        }}
                                      >
                                        ¡Entregable completado exitosamente!
                                      </Typography>
                                    </Box>
                                  )}
                                </>
                              )}

                            {contenidoUnidad !== null &&
                              contenidoUnidad.tipo === "HERRAMIENTAS" &&
                              contenidoUnidad.texto !== null && (
                                <ContentViewer
                                  textoHTML={convertedContent(
                                    contenidoUnidad.texto
                                  )}
                                />
                              )}
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    {showArrows && (
                      <Box
                        component={Button}
                        disabled={activeStep === 1}
                        onClick={handleBack}
                        sx={{
                          background:
                            "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                          color: "white",
                          borderRadius: "8px",
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                          "&:disabled": {
                            background: "transparent",
                            border: `1px solid ${theme.palette.neutral.main}`,
                          },
                          [theme.breakpoints.down("md")]: {
                            py: 1.5,
                            pl: 0.5,
                            pr: 0,
                          },
                        }}
                      >
                        <ArrowBackRounded />
                      </Box>
                    )}
                    {showArrows && (
                      <Box
                        component={Button}
                        onClick={() =>
                          handleNext(contenidoUnidad, unidadCurso.contenidos)
                        }
                        sx={{
                          background:
                            "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                          color: "white",
                          borderRadius: "8px",
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                          "&:disabled": {
                            background: "transparent",
                            border: `1px solid ${theme.palette.neutral.main}`,
                          },
                          [theme.breakpoints.down("md")]: {
                            py: 1.5,
                            pl: 0.5,
                            pr: 0,
                          },
                        }}
                      >
                        <ArrowForwardRounded />
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
        {activeView === "FIN_UNIDAD" && (
          <>
            <Paper
              elevation={0}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // height: "calc(100vh - 250px)",
                mt: 1,
                borderRadius: "10px",
                mb: 10,
                p: 3,
              }}
            >
              <Typography
                sx={{
                  mt: 5,
                  textAlign: "center",
                  fontSize: "18pt",
                  fontWeight: 700,
                  color: theme.palette.primary.main,
                }}
              >
                ¡Unidad completada exitosamente!
              </Typography>
              {/* <UnidadSuccess style={{ width: "auto", height: 220 }} /> */}
              <img
                src={iconUnidadCompletado}
                height={300}
                alt="icon-unidad-completado"
              />
              {/* {unidadCurso !== null && (
                <Typography sx={{ mt: 2 }}>
                  Unidad {unidadCurso.numero}: {unidadCurso.titulo}
                </Typography>
              )} */}
              <Button
                sx={{
                  mt: 10,
                  background:
                    "linear-gradient(180deg, rgba(0,191,232,1) 0%, rgba(0,154,219,1) 53%, rgba(0,119,170,1) 100%)",
                  px: 5,
                  py: 1.5,
                  color: "white",
                }}
                onClick={() => history.goBack()}
              >
                Confirmar
              </Button>
            </Paper>
          </>
        )}
      </Box>
    </Layout>
  );
};
