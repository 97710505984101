import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/Layout";
import Footer from "../../components/Footer";
import MainBanner from "./components/MainBanner";
import { CampaignSection } from "./components/Campaign";
import { FeaturedBanner } from "./components/FeaturedBanner";
import { ResourcesSection } from "./components/Resources";
import News from "../Hemeroteca/components/News";
import { SocialsSection } from "./components/Socials";
import ReactGA from "react-ga4";

const CommunicationPage = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // https://stackoverflow.com/questions/40280369/use-anchors-with-react-router    
    const handleScrollToAnchor = () => {
      if (hash) {
        const anchorId = hash.replace("#", "");
        const element = document.getElementById(anchorId);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }
      } else {
        window.scrollTo(0, 0);
      }
    };

    // Pequeño retraso para garantizar que el DOM esté cargado
    if (document.readyState === "complete") {
      const timeout = setTimeout(handleScrollToAnchor, 500);
      return () => clearTimeout(timeout);
    } else {
      window.addEventListener("load", handleScrollToAnchor);
      return () => {
        window.removeEventListener("load", handleScrollToAnchor);
      };
    }
  }, [pathname, hash]);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: "/comunicacion",
      title: "Comunicación",
    });
  }, []);

  return (
    <Layout>
      <MainBanner />
      <FeaturedBanner />
      <CampaignSection />
      <ResourcesSection />
      <News />
      <SocialsSection />
      <Footer />
    </Layout>
  );
};

export default CommunicationPage;
